


//============= Heading Start Here ==============//

#heading-clients{
  background-color: $bg-primary;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;



  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-lg){
          font-size: 40px;
          line-height: 50px;
        }
        @include breakpoint($secreen-max-sm){
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//



//============= Clients Start Here ==============//

#clients-home3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        margin-bottom: 64px;
        .clients-heading-outer{
          .clients-heading{
            font-family: $font-family-poppins-semibold;
            
            font-size: $lg-font-size;
            line-height: $lg-line-height;
            color: $neutral;
            margin: 16px 0;
            text-transform: capitalize;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .clients-desc-outer{
          .clients-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            
          }
        }
      }
      .clients{
        .img{
          width: 100%;
          .image-logo{
            width: 140px;
            @include site-transition;
            opacity: .5;
            @include breakpoint($secreen-max-lg){
              margin: 0 auto !important;
            }
            &:hover{
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

//============= Clients End Here ==============//
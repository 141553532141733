//==========  Footer Start Here =================//

#footer{
  padding: 128px 0;
  background-color: $bg-neutral;
  .container{
    .row{
      .quick-links-outer{
        padding-right: 0;
        @include breakpoint($secreen-max-sm){
          padding-right: 15px !important;
          padding-left: 15px !important;
        }
        .quick-links{
          @include breakpoint($secreen-max-sm){
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .quick-links-heading{
            display: flex;
            .quick{
              font-family: $font-family-poppins-semibold;
              font-weight: 600;
              font-size: $sm-font-size;
              line-height: $md-line-height;
              color: $text-white;
              text-transform: capitalize;
              margin-bottom: 16px;
            }
          }
          .links{
            display: flex;
            align-items: center;
            @include breakpoint($secreen-xs){
              justify-content: center;
            }
            .left-links,
            .right-links{
              line-height: 38px;
              @include breakpoint($secreen-max-sm){
                padding-right: 0;
                &:not(:last-child){
                  padding-right: 30px;
                }
              }
              li{
                a{
                  font-family: $font-family-poppins-regular;
                  font-weight: 400;
                  color: $text-white;
                  transition: .5s all ease-in-out;
                  &:hover{
                    color: $text-primary;
                    transition: .5s all ease-in-out;
                  }
                }
              }
            }
            .left-links{
              padding-right: 32px;
            }
            .left-links{
              @include breakpoint($secreen-xs){
                margin-right: 32px;
              }
            }
          }
        }
      }
      .contact-Details-outer{
        .contact-Details{
          @include breakpoint($secreen-max-sm){
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          padding: 0 45px;
          @include breakpoint($secreen-max-lg){
            padding: 0;
          }
          @include breakpoint($secreen-max-sm){
            margin-top: 64px;
          }
          .contact-Details-heading{
            display: flex;
            .contact{
              font-family: $font-family-poppins-semibold;
              font-weight: 600;
              font-size: $sm-font-size;
              line-height: $md-line-height;
              color: $text-white;
              text-transform: capitalize;
              margin-bottom: 16px;
            }
          }
          .contact-Details-info{
            @include breakpoint($secreen-max-sm){
              display: flex;
              flex-direction: column;
            }
            .info{
              display: inline-flex;
              align-items: center;
              position: relative;
              .main{
                height: 32px;
                width: 32px;
                background-color: $bg-primary;
                border-radius: 50%;
                margin-right: 10px;
                text-align: center;
                line-height: 31px;
                position: relative;
                @include site-transition;
                .contact{
                  color: $text-white;
                }
              }
              p{
                font-family: $font-family-poppins-regular;
                font-weight: 400;
                color: $text-white;
              }
            }
            .center{
              margin: 20px 0;
            }
          }
        }
      }
      .get-latest-outer{
        .get-latest{
          @include breakpoint($secreen-max-sm){
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          @include breakpoint($secreen-max-md){
            margin-top: 64px;
          }
          .get-latest-heading{
            display: flex;
            .latest{
              font-family: $font-family-poppins-semibold;
              font-weight: 600;
              font-size: $sm-font-size;
              line-height: $md-line-height;
              color: $text-white;
              text-transform: capitalize;
              margin-bottom: 16px;
            }
          }
          .input{
            display: flex;
            @include breakpoint($secreen-xs){
              flex-direction: column;
              align-items: center;
            }
            .input-inner{
              width: 303px;
              padding: 14px 24px;
              font-family: $font-family-poppins-regular;
              font-weight: 400;
              background-color: $bg-white;
              border: none;
              color: $text-grey-shade-1;
              text-transform: capitalize;
              &:focus{
                outline: none;
              }
              @include breakpoint($secreen-xs){
                width: 100%;
              }
              @include breakpoint($secreen-xs){
                margin: 4px 0 16px 0;
              }
            }
            .faq-input{
              color: $text-grey-shade-1;
            }
            .subscribe-btn{
              @include site-btn;
            }
          }
          .icon{
            display: flex;
            align-items: center;
            margin-top: 24px;
            li{
              &:not(:first-child){
                margin-left: 16px;
              }
              a{
                .social-icon{
                  color: $text-white;
                  font-size: 24px;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#copy{
  background-color: $bg-neutral;
  padding: 22px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  .container{
    .wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoint($secreen-max-md){
        flex-direction: column;
        justify-content: center;
      }
      .logo{
        .digitized{
          width: 170px;
          height: 46px;
          margin-bottom: 8px;
        }
      }
      .copyright{
        text-align: center;
        .copy{
          font-family: $font-family-poppins-regular;
          font-weight: 400;
          color: $text-white;
          margin-top: 8px;
          margin-bottom: 8px;
          text-transform: capitalize;
          text-align: center;
          margin: 8px 0 4px 0;
          a{
            color: $text-primary;
            display: inline-block;
          }
        }
      }
      .condition{
        display: flex;
        align-items: center;
        text-align: center;
        @include breakpoint($secreen-xs){
          flex-direction: column;
          margin: 4px 0 8px 0;
        }
        a{
          font-family: $font-family-poppins-regular;
          font-weight: 400;
          color: $text-white;
          text-transform: capitalize;
          @include site-transition;
          @include breakpoint($secreen-xs){
            margin-bottom: 4px;
          }
          &:hover{
            color: $text-primary;
          }
        }
        span{
          font-family: $font-family-poppins-regular;
          font-weight: 400;
          color: $text-white;
          margin: 0 5px;
          @include breakpoint($secreen-xs){
            display: none;
          }
        }
        a{
          font-family: $font-family-poppins-regular;
          font-weight: 400;
          text-transform: capitalize;
          @include site-transition;
          &:hover{
            color: $text-primary;
          }
        }
      }
    }
  }
}


//============= Footer End Here =================//
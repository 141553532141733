

//============= Heading Start Here ==============//

#heading-privacy{
  background-color: $bg-primary;
  margin-bottom: 128px;
  .container{
    .heading{
      padding: 60px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}


//============= Heading End Here ==============//



//============= Content Start Here ==============//

#pricavy{
  .container{
    margin: 104px auto;
    .content1{
      margin-bottom: 30px;
      h2{
        color: $text-neutral;
        margin-bottom: 16px;
        @include breakpoint($secreen-max-md){
          font-size: 32px;
          line-height: 44px;
        }
        @include breakpoint($secreen-xs){
          font-size: 28px;
          line-height: 36px;
        }
      }
      .first{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
      .second{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
        margin: 16px 0;
      }
      .third{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
    }
    .content2{
      margin-bottom: 30px;
      h2{
        color: $text-neutral;
        margin-bottom: 16px;
      }
      .first{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
    }
    .content3{
      margin-bottom: 30px;
      h2{
        color: $text-neutral;
        margin-bottom: 16px;
      }
      .first{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
      .second{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
        margin: 16px 0;
      }
      .third{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
    }
    .content2{
      margin-bottom: 30px;
      h2{
        color: $text-neutral;
        margin-bottom: 16px;
      }
      .first{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
    }
    .content4{
      margin-bottom: 30px;
      h2{
        color: $text-neutral;
        margin-bottom: 16px;
      }
      .first{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
    }
    .content5{
      margin-bottom: 30px;
      h2{
        color: $text-neutral;
        margin-bottom: 16px;
      }
      .first{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
    }
    .content6{
      margin-bottom: 30px;
      h2{
        color: $text-neutral;
        margin-bottom: 16px;
      }
      .first{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
      }
      .second{
        color: $text-grey-shade-1;
        line-height: $sm-line-height;
        font-family: $font-family-poppins-regular;
        
        margin-top: 16px;
      }
    }
  }
}

//============= Content End Here ==============//

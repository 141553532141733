
// Side Nav

#mySidenav{

}
.sidenav-about3{
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: scroll;
  transition: 0.5s;
  padding-top: 40px;
  @include breakpoint($secreen-xs){
    position: fixed;
    right: 0 !important;
    left: auto;
  }
  .closebtn{
    .close{
      position: absolute;
      top: 15px;
      right: 25px;
      color: $text-white;
      opacity: 1;
      width: 16px;
      @include site-transition;
      &:hover{
        opacity: .5;
      }
    }
  }
  .nav{
    display: flex;
    flex-direction: column;
    .dropdown{
      padding: 0 40px;
      .dropdown-toggle{
        margin-bottom: 16px;
        font-size: 20px;
        font-family: $font-family-poppins-semibold;
        color: $text-white;
        text-transform: capitalize;
        @include site-transition;
        &:after{
          display: none;
        }
        &:hover{
          color: $text-primary;
          svg
          {
            fill: $text-primary;
          }
        }
        svg{
          fill: $text-grey-shade-1;
          @include site-transition;
        }
      }
      .dropdown-toggle-inner{
        margin-left: 20px !important;
        margin-bottom: 0 !important;
      }
      .dropdown-menu{
        border: none;
        border-radius: 0;
        background-color: transparent;
        position: relative !important;
        @include site-transition;
        height: auto;
        .dropdown-item{
          font-size: 20px;
          font-family: $font-family-poppins-semibold;
          
          color: $text-white;
          text-transform: capitalize;
          margin-bottom: 8px;
          @include site-transition;
          padding: 0 !important;
          margin-left: 20px;
          &:hover{
            background: transparent;
            color: $text-primary;
          }
        }
        .dropdown{


        }
        .sub-drop{
          position: inherit !important;
        }
      }
      .dropdown-menu-inner{
        left: 20px !important;
      }
      .show{
        display: block;
        position: relative !important;
        top: -5px !important;
        animation: fade .7s ease-in-out;
        transform: translate3d(0px,0px,0px) !important;
      }
    }
    .action{
      display: flex;
      flex-direction: column-reverse;
      padding: 0 40px;
      @include breakpoint ($secreen-max-lg){
        display: none;
      }
      @include breakpoint ($secreen-max-md){
        display: flex;
      }
      .contact-btn{
        @include site-btn;
        background-color: transparent;
        border: 1px solid $border-primary;
        color: $text-white;
        padding: 12px 24px;
        border-radius: 0;
        text-align: center;
      }
    }
  }
}




header {
  position: fixed;
  top: 0;
  transition: top 0.3s ease-in-out;
  width: 100%;
}

.nav-up {
  top: -100px;
}



#header-home2 {
    box-shadow: $box-shadow;
    position: fixed;
    top: 0;
    left: 0;
    height: 92px;
    width: 100%;
    background-color: $bg-white;
    z-index: 10;
    transition: top 0.3s;
    @include breakpoint($secreen-max-sm){
      height: 75px;
    }
      .container {
          .nav {
              display: flex;
              align-items: center;
              padding: 20px 0;
              @include breakpoint($secreen-max-md){
                justify-content: space-between;
              }
              @include breakpoint($secreen-max-sm){
                padding: 15px 0;
              }
              .logo {
                  display: flex;
                  align-items: center;
                  @include breakpoint($secreen-xs){
                    justify-content: space-between;
                    flex-direction: row-reverse;
                    width: 100%;
                  }
                  .toggle{
                    transform: scaleX(-1 ,1);
                    margin-right: 12px;
                    @include breakpoint($secreen-max-lg){
                      display: block;
                    }
                    @include breakpoint($secreen-xl){
                      display: none;
                    }
                    @include breakpoint($secreen-xs){
                      transform: scaleX(-1);
                      margin-right: 0;
                    }
                    &:hover{
                      .first{
                        width: 25px;
                        @include site-transition;
                      }
                      .third{
                        width: 25px;
                        @include site-transition;
                      }
                    }
                    .toggle-line {
                        display: flex;
                        flex-direction: row;
                    }
                    .first {
                        width: 21px;
                        height: 3px;
                        transition: 0.3s;
                        background-color: $bg-grey-shade-1;
                        @include site-transition;
                    }
                    .second {
                        width: 25px;
                        height: 3px;
                        margin: 5px 0;
                        transition: 0.3s;
                        background-color: $bg-grey-shade-1;
                    }
                    .third {
                        width: 16px;
                        height: 3px;
                        transition: 0.3s;
                        background-color: $bg-grey-shade-1;
                        @include site-transition;
                    }
                    &:hover{
                      cursor: pointer;
                    }
                  }
                  .digieized{
                    .digieized-logo{
                      width: 152px;
                      height: auto;
                    }
                  }
              }
              .navigation {
                  margin-left: auto;
                  display: flex;
                  @include breakpoint($secreen-max-lg){
                    display: none;
                  }
                  .navigation-ul {
                      display: flex;
                      align-items: center;
                      .navigation-li {
                          position: relative;
                          &:hover{
                            .drop-ul{
                               opacity: 1;
                               visibility: visible;
                               transform: perspective(300px) rotateX(0deg);
                               top: 100%;
                             }
                          }
                          &:not(:first-child) {
                              margin-left: 32px;
                          }
                          .links {
                              display: flex;
                              align-items: center;
                              line-height: 52px;
                              cursor: pointer;
                              text-transform: capitalize;
                              color: $text-grey-shade-1;
                              font-family: $font-family-poppins-medium;
                              
                              @include site-transition;
                              &:hover{
                                color: $text-primary;
                              }
                          }
                          .drop-ul{
                            background-color: $bg-white;
                            box-shadow: $box-shadow;
                            position: absolute;
                            top: 100px;
                            width: 270px;
                            z-index: 1;
                            opacity: 0;
                            visibility: hidden;
                            border-radius: 0;
                            left: -20px;
                            transform-origin: 50% -50px;
                            transform: perspective(300px) rotateX(-8deg);
                            transition: all cubic-bezier(.4,0,.2,1) .4s;
                            padding: 16px 0;
                            .drop-li{
                              position: relative;
                              &:hover{
                                background-color: $bg-primary;
                                .other-ul{
                                  opacity: 1;
                                  visibility: visible;
                                  transform: perspective(300px) rotateX(0deg);
                                }
                                .drop-links{
                                  color: $text-white;
                                  transition: .3s all ease-in-out;
                                  background-color: $bg-primary;
                                  cursor: pointer;
                                  &:hover{
                                    .drop-arrow{
                                      transform: rotate(-270deg);
                                      transition: .3s all ease-in-out;
                                    }
                                  }
                                  .drop-arrow{
                                    color: $text-white;
                                  }
                                }
                              }
                              .drop-links{
                                padding: 12px 32px;
                                color: $text-grey-shade-1;
                                font-family: $font-family-poppins-medium;
                                position: relative;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                text-transform: capitalize;
                                justify-content: space-between;
                                .drop-arrow{
                                  transform: rotate(-90deg);
                                  color: $text-grey-shade-1;
                                  transition: .3s all ease-in-out;
                                }
                              }
                              .other-ul{
                                position: absolute;
                                right: 254px;
                                top: -16px;
                                background-color: $bg-white;
                                box-shadow: $box-shadow;
                                width: 270px;
                                z-index: 1;
                                opacity: 0;
                                visibility: hidden;
                                border-radius: 0;
                                transform-origin: 50% -50px;
                                transform: perspective(300px) rotateX(-8deg);
                                transition: all cubic-bezier(.4,0,.2,1) .4s;
                                padding: 16px 0;
                                .other-li{
                                  position: relative;
                                  .other-links{
                                    padding: 12px 32px;
                                    color: $text-grey-shade-1;
                                    font-family: $font-family-poppins-medium;
                                    
                                    position: relative;
                                    text-transform: capitalize;
                                    &:hover{
                                      color: $text-white;
                                      transition: .3s all ease-in-out;
                                      background-color: $bg-primary;
                                    }
                                  }
                                }
                              }
                            }
                          }
                      }
                  }
              }
              .action{
                display: flex;
                margin-left: 32px;
                @include breakpoint ($secreen-max-lg){
                  margin-left: auto;
                }
                .contact-btn{
                  @include site-btn;
                  background-color: transparent;
                  border: 1px solid $border-primary;
                  color: $primary;
                  padding: 12px 24px;
                  @include breakpoint ($secreen-xs){
                    display: none;
                  }
                  &:hover{
                    background-color: $bg-primary;
                    color: $text-white;
                  }
                }
              }
          }
          .nav2{

          }
          #nav{
          }
      }
  }



//============= Heading Start Here ==============//

#heading-testimonials{
  background-color: $bg-primary;
  .container{
    .heading{
      padding: 60px;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//



#testimonials-home3{
}
.home3{
  margin: 128px 0;
}

.testimonials-home2{
  margin-bottom: 128px;
}

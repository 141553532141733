@mixin site-transition {
    transition: all 0.2s ease-in-out;
    transition: 0.2s linear; /* vendorless fallback */
    -o-transition: 0.2 linear; /* opera */
    -ms-transition: 0.2s linear; /* IE 10 */
    -moz-transition: 0.2s linear; /* Firefox */
    -webkit-transition: 0.2s linear; /*safari and chrome */
}

@mixin site-btn {
    background-color: $bg-primary;
    color: $text-white;
    padding: 14px 24px;
    font-family: $font-family-poppins-regular;
    font-weight: 500;
    position: relative;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    @include site-transition;
    &:hover{
      background-color: $bg-neutral;
      cursor: pointer;
    }
}

@mixin site-tag {
  padding: 8px 16px;
  background-color: #F0EDF6;
  color: $text-grey-shade-1;
  font-family: $font-family-poppins-regular;
  font-size: 15px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}




@mixin news-blog {
    @keyframes news {
        0% {
            position: absolute;
            right: 8px;
        }
        40% {
            position: absolute;
            opacity: 0;
            right: -25px;
        }
        70% {
            position: absolute;
            opacity: 0;
            right: 25px;
        }
       100% {
             position: absolute;
             opacity: 1;
             right: 12px;
        }
    }
}

@mixin rotate-transition {
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(2000deg);
        }
    }
}


@mixin fadeIN {
    @keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

@mixin slider-circle {
    @keyframes circle {
        0% {
            opacity: 0;
            width: 0;
            height: 0;
        }
        100% {
            opacity: 1;
            width: 720px;
            height: 720px;
        }
    }
}
@mixin slider-illustertaion {
    @keyframes illustertaion {
        0% {
            opacity: 1;
            transform: translate3d(150%,0,0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
    }
}
@mixin button-transition {
    @keyframes pulse {
        0% {
            transform: scale(0.5);
            opacity: 0;
        }
        50% {
            transform: scale(1);
            opacity: 0.7;
        }
        100% {
            transform: scale(1.3);
            opacity: 0;
        }
    }
}


@mixin button-transition-home3 {
    @keyframes home3pulse {
        0% {
            transform: scale(1);
            opacity: 0;
        }
        50% {
            transform: scale(1.4);
            opacity: 1;
        }
        100% {
            transform: scale(1.8);
            opacity: 0;
        }
    }
}

@mixin button-transition-home31 {
    @keyframes home3pulse1 {
        0% {
            transform: scale(1);
            opacity: 0;
        }
        50%{
          transform: scale(1.2);
          opacity: 1;
        }
        100% {
            transform: scale(1.6);
            opacity: 0;
        }
    }
}

@mixin morphing-transition {
    @keyframes morphing {
        0% {
            clip-path: path('M0.021,0.751 c0.032,0.078,0.089,0.076,0.106,0.075 c0.049,-0.002,0.059,-0.043,0.084,-0.022 c0.034,0.029,0.022,0.116,0.061,0.166 c0.035,0.045,0.079,0.021,0.088,0.016 c0.032,-0.017,0.058,-0.059,0.078,-0.092 s0.027,-0.052,0.045,-0.067 c0.008,-0.006,0.031,-0.024,0.054,-0.01 c0.034,0.02,0.03,0.086,0.067,0.138 c0.026,0.037,0.064,0.054,0.096,0.037 c0.043,-0.024,0.04,-0.085,0.081,-0.112 c0.051,-0.034,0.079,0.048,0.131,0.012 c0.032,-0.022,0.051,-0.074,0.059,-0.098 c0.044,-0.124,0.026,-0.283,0.012,-0.411 c-0.011,-0.098,-0.018,-0.163,-0.046,-0.228 c-0.006,-0.013,-0.046,-0.104,-0.111,-0.121 c-0.039,-0.01,-0.07,0.01,-0.127,0.047 c-0.063,0.041,-0.069,0.067,-0.097,0.06 c-0.041,-0.01,-0.044,-0.068,-0.096,-0.111 S0.405,0.001,0.391,0.006 C0.319,0.03,0.313,0.117,0.259,0.115 C0.207,0.114,0.198,0.031,0.141,0.033 c-0.004,0,-0.065,0.004,-0.089,0.084 c-0.025,0.081,0.017,0.141,0.001,0.272 C0.039,0.504,-0.001,0.523,0,0.628 C0,0.65,0.003,0.705,0.021,0.751');
            background-color: red;
        }
        100% {
            clip-path: path('M0.004,0.683 c0.012,0.08,0.048,0.145,0.09,0.154 c0.036,0.008,0.074,-0.022,0.108,0.006 C0.232,0.869,0.249,0.936,0.277,0.972 c0.026,0.033,0.06,0.035,0.089,0.017 s0.055,-0.055,0.079,-0.094 c0.015,-0.024,0.029,-0.051,0.046,-0.068 s0.038,-0.025,0.055,-0.011 c0.03,0.025,0.041,0.102,0.068,0.141 c0.03,0.044,0.072,0.029,0.104,-0.007 c0.025,-0.028,0.048,-0.069,0.076,-0.07 c0.027,-0.001,0.05,0.036,0.076,0.05 c0.051,0.028,0.099,-0.044,0.118,-0.138 c0.023,-0.118,0.005,-0.251,-0.002,-0.371 c-0.006,-0.097,-0.006,-0.2,-0.033,-0.281 c-0.025,-0.075,-0.069,-0.116,-0.113,-0.123 S0.753,0.032,0.712,0.065 C0.673,0.095,0.632,0.136,0.591,0.119 c-0.035,-0.014,-0.062,-0.065,-0.095,-0.094 a0.154,0.313,0,0,0,-0.102,-0.014 c-0.045,0.026,-0.085,0.093,-0.131,0.087 c-0.042,-0.006,-0.078,-0.073,-0.12,-0.084 s-0.086,0.042,-0.101,0.122 c-0.013,0.069,-0.006,0.146,-0.011,0.219 C0.024,0.455,-0.005,0.549,0.001,0.649 A0.094,0.191,0,0,0,0.004,0.683');
            background-color: red;
        }
    }
}



@mixin circle-svg {
    @keyframes circle {
        0% {
            position: absolute;
            top: 170px;
        }
        50% {
            position: absolute;
            top: 32px;
        }
        100% {
            position: absolute;
            top: 170px;
        }
    }
}

@mixin circle-svg1 {
    @keyframes circle1 {
        0% {
            position: absolute;
            top: 115px;
        }
        50% {
            position: absolute;
            top: 410px;
        }
        100% {
            position: absolute;
            top: 115px;
        }
    }
}

@mixin circle-svg-features-1 {
    @keyframes features1 {
        0% {
            position: absolute;
            bottom: 180px;
        }
        50% {
            position: absolute;
            bottom: 32px;
        }
        100% {
            position: absolute;
            bottom: 180px;
        }
    }
}

@mixin features-circle-svg {
    @keyframes features {
        0% {
            position: absolute;
            bottom: 106px;
        }
        30% {
            position: absolute;
            bottom: 180px;
            left: 360px;
        }
        70% {
            position: absolute;
            bottom: 180px;
            left: -66px;
        }
        100% {
            position: absolute;
            bottom: 106px;
        }
    }
}

@mixin dots-svg {
    @keyframes dots {
        0% {
            position: absolute;
            left: -20px;
        }
        50% {
            position: absolute;
            left: 0px;
        }
        100% {
            position: absolute;
            left: -20px;
        }
    }
}

@mixin dots-svg-grid {
    @keyframes dots {
        0% {
            position: absolute;
            left: -88px;
        }
        50% {
            position: absolute;
            left: -20px;
        }
        100% {
            position: absolute;
            left: -88px;
        }
    }
}


@mixin dots-svg1 {
    @keyframes dots1 {
        0% {
            position: absolute;
            left: 20px;
        }
        50% {
            position: absolute;
            left: -10px;
        }
        100% {
            position: absolute;
            left: 20px;
        }
    }
}

@mixin features-triangle {
    @keyframes triangle1 {
        0% {
            transform: translateX(-360px);
        }
        20% {
            transform: translate(-500px, -60px) rotate(120deg);
        }
        40% {
            transform: translate(-661px, 35px) rotate(190deg);
        }
        60% {
            transform: translate(-500px, 92px) rotate(90deg);
        }
        80% {
            transform: translate(-247px, -10px) rotate(180deg);
        }
        100% {
            transform: translateX(-360px);
        }
    }
}

@mixin triangle-svg {
    @keyframes triangle {
        0% {
            position: absolute;
            left: 140px;
            transform: rotate(0deg);
        }
        100% {
            position: absolute;
            transform: scale(1.5);
            transform: rotate(4060deg);
        }
    }
}

@mixin circle-svg-2 {
    @keyframes circle2 {
      0% {
          transform: translate(-100px, -40px) rotate(0deg);
      }
      33% {
          transform: translate(-650px, 250px) rotate(-120deg);
      }
      67% {
          transform: translate(-640px, -100px) rotate(-80deg);
      }
      100% {
          transform: translate(-100px, -40px) rotate(0deg);
      }
    }
}

@mixin dropdown-animation {
    @keyframes dropdown {
        0% {
            top: 92px;
            opacity: 0;
        }
        50% {
            top: 72px;
            opacity: .5;
        }
        100% {
            top: 52px;
            opacity: 1;
        }
    }
}

@mixin circle-3-animation {
    @keyframes circle-3 {
        0% {
            left: 40px;
            transform: translate(0) rotate(0deg);
        }
        33% {
            transform: translate(700px,-150px) rotate(120deg);
        }
        67% {
            transform: translate(690px, 150px) rotate(-80deg);
        }
        100% {
            transform: translate(0) rotate(0deg);
            left: 40px;
        }

    }
}



//===============  Heading Start Here ================//

#heading-login{
  background-color: $bg-primary;
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//===============  Heading End Here ================//


//===============  Login Form Start Here ================//


#login-page{
  margin: 128px 0;
  @include breakpoint($secreen-max-md){
    margin: 64px 0;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        h2{
          color: $text-neutral;
          margin-bottom: 18px;
          @include breakpoint($secreen-max-md){
            font-size: 32px;
            line-height: 44px;
          }
          @include breakpoint($secreen-xs){
            font-size: 28px;
            line-height: 36px;
          }
        }
        p{
          color: $text-grey-shade-1;
          font-family: $font-family-poppins-regular;
          font-size: 18px;
        }
      }
      .row{
        .login-form{
          background-color: $bg-white;
          padding: 40px;
          margin: 64px auto 0 auto;
          display: flex;
          flex-direction: column;
          box-shadow: $box-shadow;
          @include breakpoint($secreen-max-md){
            padding: 40px 15px;
          }
          .heading-inner{
            p{
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-regular;
              text-align: center;
              text-transform: capitalize;
            }
          }
          .socila-media{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 28px 0;
            @include breakpoint($secreen-xs){
              flex-direction: column;
            }
            .google{
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              padding: 14px 0;
              width: 100%;
              margin-right: 40px;
              background-color: $bg-cinnabar;
              @include breakpoint($secreen-xs){
                margin-right: 0;
                margin-bottom: 16px;
              }
              .social-icon{
                color: $text-white;
              }
              p{
                text-transform: capitalize;
                color: $text-white;
                font-size: 16px;
                margin-left: 8px;
                font-family: $font-family-poppins-regular;
              }
            }
            .facebbok{
              padding: 14px 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              background-color: $bg-chambray;
              .social-icon{
                color: $text-white;
              }
              p{
                text-transform: capitalize;
                color: $text-white;
                margin-left: 8px;
                font-family: $font-family-poppins-regular;
                
              }
            }
          }
          .line-or{
            text-align: center;
            position: relative;
            &:after{
              content: "";
              position: absolute;
              left: 0;
              width:46%;
              height: 1px;
              background-color: $text-grey-shade-3;
              top: 50%;
              transform: translate(0%, -50%);
            }
            &:before{
              content: "";
              position: absolute;
              right: 0;
              width: 46%;
              height: 1px;
              background-color: $text-grey-shade-3;
              top: 50%;
              transform: translate(0%, -50%);
            }
            p{
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-regular;
              
              text-transform: capitalize;
            }
          }
          .form{
            margin-top: 28px;
            .input-email{
              .input{
                width: 100%;
                border: none;
                padding: 16px 24px;
                background-color: $bg-sonw;
                color: $text-grey-shade-1;
                font-family: $font-family-poppins-regular;
                
                text-transform: capitalize;
                @include site-transition;
                &:focus{
                  outline: none;
                  background-color: $bg-grey-shade-3;
                }
              }
              .input-user{
                margin-bottom: 24px;
              }
            }
            .input-password{
              display: flex;
              align-items: center;
              justify-content: space-between;
              background-color: $bg-sonw;
              margin: 24px 0 28px 0;
              position: relative;
              .input{
                width: 100%;
                background-color: none;
                border: none;
                color: $text-grey-shade-1;
                background-color: transparent;
                font-family: $font-family-poppins-regular;
                
                padding: 16px 24px;
                @include site-transition;
                &:focus{
                  outline: none;
                  background-color: $bg-grey-shade-3;
                }
              }
              .icon-eye{
                position: absolute;
                right: 24px;
                color: $text-grey-shade-1;
                &:hover{
                  cursor: pointer;
                }
              }
            }
            .check{
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include breakpoint($secreen-xs){
               flex-direction: column;
              }
              label{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0;
                @include breakpoint($secreen-xs){
                 margin-bottom: 20px;
                }
                .check-inner{
                  background-color: $bg-grey-shade-3;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  flex-shrink: 0;
                  justify-content: center;
                  align-items: center;
                  margin-right: 7px;
                  position: relative;
                  &:hover{
                    cursor: pointer;
                  }
                  &:focus{
                    border: 2px solid red;
                  }
                  .input-check{
                    opacity: 0;
                    position: absolute;
                    &:hover{
                      cursor: pointer;
                    }
                  }
                  .input-check:checked + svg {
                  	display: block;
                    fill: #00ACEE;
                    background-color: #4200ff;
                    padding-left: 6px;
                    padding-top: 7px;
                  }
                  .fill-current{
                    display: none;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                  }
                }
                .select-none{
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-regular;
                  
                  a{
                    display: inline-flex;
                    color: $text-primary;
                  }
                }
              }
              .forget{
                a{
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-regular;
                  
                  text-transform: capitalize;
                }
              }
            }
          .action{
            display: flex;
            margin: 28px 0 28px 0;
            a{
              @include site-btn;
              width: 100%;
              text-align: center;
            }
          }
          .signup{
            display: flex;
            justify-content: center;
            p{
              
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
              text-transform: capitalize;
              a{
                display: contents;
                text-transform: capitalize;
                color: $text-primary;
                @include site-transition;
                &:hover{
                  color: $text-grey-shade-1;
                }
              }
            }
          }
        }
      }
    }
  }
}
}

//===============  Login Form End Here ================//

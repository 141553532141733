

//============= Heading Start Here ==============//

#heading-work{
  background-color: $bg-primary;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px
  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//




//============= Works Classic Layout Start Here ==============//

#work-process{
  margin-bottom: 128px;
  position: relative;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px
  }
  &:after{
    content: "";
    position: absolute;
    bottom: 267px;
    width: 100%;
    background-image: url('/../assets/img/dashed-line.png');
    height: 3px;
    z-index: -1;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        justify-content: center;
        .work-outer{
          .work-inner{
            background-color: $bg-white;
            padding: 40px 32px;
            box-shadow: $box-shadow;
            .icon{
              display: flex;
              .work-icon{
                font-size: 56px;
                margin: 0px auto 24px auto;
                color: $text-primary;
              }
            }
            .heading-inner{
              text-align: center;
              h3{
                color: $text-neutral;
              }
              p{
                color: $text-grey-shade-1;
                font-family: $font-family-poppins-regular;
                line-height: 30px;
                margin: 16px 0 16px 0;
              }
              .step-one{
                color: $text-icecold;
                line-height: 50px;
                text-transform: lowercase;
              }
              .step-three{
                color: $text-remy;
                line-height: 50px;
                text-transform: lowercase;
              }
              .step-two{
                color: $text-oasis;
                line-height: 50px;
                text-transform: lowercase;
              }
            }
          }
        }
        .box1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box2{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//============= Works Classic Layout End Here ==============//




//============= Work Modren Layout Start Here ==============//

#create-account{
  .container{
    .heading{
      text-align: center;
      h2{
        color: $text-neutral;
        @include breakpoint($secreen-max-md){
          font-size: 32px;
          line-height: 44px;
        }
        @include breakpoint($secreen-xs){
          font-size: 28px;
          line-height: 36px;
        }
      }
      p{
        color: $text-grey-shade-1;
        font-family: $font-family-poppins-regular;
        font-size: 18px;
        line-height: $sm-line-height;
        margin-top: 18px;
      }
    }
    .row{
      margin-top: 64px;
      align-items: center;
      .create-account{
        .content{
          display: flex;
          align-items: center;
          @include breakpoint($secreen-xs){
            flex-direction: column;
            justify-content: center;
          }
          .zero1{
            h2{
               font-size: $xl-font-size;
               margin-right: 30px;
               color: $text-icecold;
            }
          }
          .account{
            h3{
              color: $text-neutral;
              @include breakpoint($secreen-xs){
                  text-align: center;
              }
            }
            p{
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-regular;
              line-height: 30px;
              margin-top: 8px;
              @include breakpoint($secreen-xs){
                  text-align: center;
              }
            }
          }
        }
      }
      .image{
        .img{
          display: flex;
          justify-content: flex-end;
          @include breakpoint($secreen-max-md){
              margin-top: 24px;
              justify-content: center;
          }
          .workimage1{
            width: 100%;
            @include breakpoint($secreen-max-sm){
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}


#create-account2{
  .container{
    .row{
      align-items: center;
      margin: 32px 0;
      @include breakpoint($secreen-max-md){
        flex-direction: column-reverse;
      }
      .image{
        .img{
          display: flex;
          justify-content: flex-end;
          @include breakpoint($secreen-max-md){
              margin-top: 24px;
              justify-content: center;
          }
          .workimage1{
            width: 100%;
            @include breakpoint($secreen-max-sm){
              height: 100% !important;
            }
          }
        }
      }
      .create-account{
        .content{
          display: flex;
          align-items: center;
          @include breakpoint($secreen-xs){
            flex-direction: column;
            justify-content: center;
          }
          .zero1{
            h2{
               font-size: $xl-font-size;
               margin-right: 30px;
               color: $text-oasis;
            }
          }
          .account{
            h3{
              color: $text-neutral;
              @include breakpoint($secreen-xs){
                  text-align: center;
              }
            }
            p{
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-regular;
              line-height: 30px;
              margin-top: 8px;
              @include breakpoint($secreen-xs){
                  text-align: center;
              }
            }
          }
        }
      }
    }
  }
}


#create-account3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      text-align: center;
      h2{
        color: $text-neutral;
      }
      p{
        color: $text-grey-shade-1;
        font-family: $font-family-poppins-regular;
        line-height: 30px;
        margin-top: 8px;
        @include breakpoint($secreen-xs){
            text-align: center;
        }
      }
    }
    .row{
      margin-top: 64px;
      align-items: center;
      .create-account{
        .content{
          display: flex;
          align-items: center;
          @include breakpoint($secreen-xs){
            flex-direction: column;
            justify-content: center;
          }
          .zero1{
            h2{
               font-size: $xl-font-size;
               margin-right: 30px;
               color: $text-remy;
            }
          }
          .account{
            h3{
              color: $text-neutral;
              @include breakpoint($secreen-xs){
                  text-align: center;
              }
            }
            p{
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-regular;
              line-height: 30px;
              margin-top: 8px;
              @include breakpoint($secreen-xs){
                  text-align: center;
              }
            }
          }
        }
      }
      .image{
        .img{
          display: flex;
          justify-content: flex-end;
          @include breakpoint($secreen-max-md){
              margin-top: 24px;
              justify-content: center;
          }
          .workimage1{
            width: 100%;
            @include breakpoint($secreen-max-sm){
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}

//============= Work Modren Layout End Here ==============//


//============= Work regular Layout Start Here ==============//

#work-process-2{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      text-align: center;
      .services-tag-outer{
        display: flex;
        justify-content: center;
        .services-tag{
          @include site-tag;
        }
      }
      .services-heading-outer{
        .services-heading{
          font-family: $font-family-poppins-semibold;
          font-weight: 600;
          font-size: $lg-font-size;
          line-height: $lg-line-height;
          color: $text-neutral;
          margin: 18px 0;
          text-transform: capitalize;
          @include breakpoint($secreen-max-md){
            font-size: 32px;
            line-height: 44px;
          }
          @include breakpoint($secreen-xs){
            font-size: 28px;
            line-height: 36px;
          }
        }
      }
      .services-desc-outer{
        .services-desc{
          font-family: $font-family-poppins-regular;
          color: $text-grey-shade-1;
          font-size: 18px;
        }
      }
    }
    .row{
      margin-top: 64px;
      align-items: center;
      .work{
        .work-inner{
          .heading-inner{
            position: relative;
            h2{
              color: $text-primary;
              text-transform: lowercase;
              margin-left: 20px;
              @include breakpoint($secreen-max-sm){
                text-align: center;
                margin-left: 0;
              }
            }
            .shape{
              position: absolute;
              top: -5px;
              left: -0px;
              z-index: -1;
              @include breakpoint($secreen-max-sm){
                left: 50%;
                transform: translate(-50%, 0%);
              }
            }
          }
          .desc{
            @include breakpoint($secreen-max-sm){
              text-align: center;
            }
            h3{
              margin: 24px 0 12px 0;
              color: $text-neutral;
            }
            p{
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
              line-height: 30px;
            }
          }
        }
      }
      .box1{
        @include breakpoint($secreen-max-md){
          margin-bottom: 30px;
        }
      }
      .box2{
        @include breakpoint($secreen-max-sm){
          margin-bottom: 30px;
        }
      }
    }
  }
}

//============= Work regular Layout End Here ==============//

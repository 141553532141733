


//============= Heading Start Here ==============//

#heading-contact{
  background-color: $bg-primary;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//




//=================== Contact form Start Here ====================//

#contact{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      h2{
        color: $text-neutral;
        text-align: center;
        @include breakpoint($secreen-max-md){
          font-size: 32px;
          line-height: 44px;
        }
        @include breakpoint($secreen-xs){
          font-size: 28px;
          line-height: 40px;
        }
      }
      p{
        font-family: $font-family-poppins-regular;
        color: $text-grey-shade-1;
        text-transform: capitalize;
        text-align: center;
        margin-top: 18px;
        font-size: 18px;
      }
    }
    .row{
      margin-top: 64px;
      display: flex;
      align-items: center;
      .image{
        @include breakpoint($secreen-max-md){
          margin-bottom: 64px;
        }
        .img{
          img{
            width: 100%;
          }
        }
      }
      .form{
        .form-inner{
          background-color: $bg-white;
          box-shadow: $box-shadow;
          padding: 40px;
          @include breakpoint($secreen-xs){
            padding: 40px 15px;
          }
          .heading-form{
            h3{
              color: $text-neutral;
            }
            p{
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
              text-transform: capitalize;
              margin: 12px 0 36px 0;
            }
          }
          .input-outer{
            input{
              width: 100%;
              padding: 16px 24px;
              font-family: $font-family-poppins-regular;
              
              border: none;
              text-transform: capitalize;
              background-color: $bg-sonw;
              color: $text-grey-shade-1;
              margin-bottom: 20px;
              @include site-transition;
              &:focus{
                outline: none;
                background-color: $bg-grey-shade-3;
              }
            }
          }
          textarea{
            resize: none;
            width: 100%;
            background-color: $bg-sonw;
            border: none;
            font-family: $font-family-poppins-regular;
            
            padding: 12px 24px;
            color: $text-grey-shade-1;
            text-transform: capitalize;
            margin-bottom: 20px;
            @include site-transition;
            &:focus{
              outline: none;
              background-color: $bg-grey-shade-3;
            }
          }
          .action{
            display: flex;
            .contact-btn{
              width: 100%;
              text-align: center;
              @include site-btn;
            }
          }
        }
      }
    }

  }
}

//=================== Contact form End Here ====================//




//=================== Information Start Here ====================//


#information{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      text-align: center;
      h2{
        color: $text-neutral;
        @include breakpoint($secreen-max-md){
          font-size: 32px;
          line-height: 44px;
        }
        @include breakpoint($secreen-xs){
          font-size: 28px;
          line-height: 40px;
        }
      }
      p{
        font-family: $font-family-poppins-regular;
        font-size: 18px;
        color: $text-grey-shade-1;
        text-transform: capitalize;
        margin-top: 18px;
      }
    }
    .row{
      margin-top: 64px;
      .information-outer{
        .information-inner{
          background-color: $bg-white;
          box-shadow: $box-shadow;
          padding: 32px 36px 36px 36px;
          .heading-inner{
            h3{
              color: $text-neutral;
            }
            p{
              font-family: $font-family-poppins-regular;
              
              color: $text-grey-shade-1;
              text-transform: capitalize;
              margin: 8px 0 48px 0;
            }
          }
          .icons{
            display: flex;
            align-items: center;
            .icons-inner{
              .envelope1,
              .phone1,
              .location1{
                color: $text-primary;
              }
            }
            .icons-inner{
              .envelope2,
              .phone2,
              .location2{
                color: $text-primary;
                opacity: 10%;
              }
            }
            .icons-inner{
              .envelope3,
              .phone3,
              .location3{
                color: $text-primary;
                opacity: 5%;
              }
            }
            .center{
              margin: 0 12px;
            }
          }
        }
      }
      .box1{
        @include breakpoint($secreen-max-md){
          margin-bottom: 30px;
        }
      }
      .box2{
        @include breakpoint($secreen-max-sm){
          margin-bottom: 30px;
        }
      }
    }
  }
}

//=================== Information End Here ====================//


//=================== Social Button Start Here ===================//

#social-media{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      text-align: center;
      h2{
        color: $text-neutral;
        @include breakpoint($secreen-max-md){
          font-size: 32px;
          line-height: 44px;
        }
        @include breakpoint($secreen-xs){
          font-size: 28px;
          line-height: 40px;
        }
      }
      p{
        font-family: $font-family-poppins-regular;
        font-size: 18px;
        color: $text-grey-shade-1;
        text-transform: capitalize;
        margin-top: 18px;
      }
    }
    ul{
      margin-top: 64px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 0 50px;
      li{
        &:not(:last-child){
          margin-right: 30px;
        }
        @include breakpoint($secreen-max-lg){
          margin-bottom: 30px;
        }
        @include breakpoint($secreen-xs){
          &:not(:last-child){
            margin-right: 0px;
          }
        }
        .social-btn{
          padding: 18px 0;
          display: flex;
          width: 160px;
          align-items: center;
          justify-content: center;
          .social-icon{
            color: $text-white;
            margin-right: 8px;
            font-size: 24px;
          }
          .snap{
            color: $text-neutral;
          }
          p{
            font-family: $font-family-poppins-medium;
            
            color: $text-white;
            text-transform: capitalize;
          }
          .snap-text{
            color: $text-neutral;
          }
        }
        .fb{
          background-color: $bg-chambray;
        }
        .twi{
          background-color: $bg-twitter;
        }
        .insta{
          background-color: $bg-instagram;
        }
        .dribl{
          background-color: $bg-dribble;
        }
        .snap{
          background-color: $bg-snapchat;
        }
      }
      .last-child{
        margin-bottom: 0px;
      }
    }
  }
}

//=================== Social Button End Here ===================//

iframe{
  border-width: 0px;
  width: 100%;
}


//============= Pricing Start Here ==============//


#heading-pricing{
  background-color: $bg-primary;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-lg){
          font-size: 40px;
          line-height: 50px;
        }
        @include breakpoint($secreen-max-sm){
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
}


//============= Pricing End Here ==============//


//=============  Header Start Here ==============//


#header {
    .container {
        .nav {
            display: flex;
            align-items: center;
            padding-top: 28px;
            padding-bottom: 100px;
            @include breakpoint($secreen-max-md){
              padding-bottom: 64px;
            }
            @include breakpoint($secreen-xs){
              padding-top: 16px;
            }
            .logo {
                display: flex;
                align-items: center;
                @include breakpoint($secreen-xs){
                  width: 100%;
                  justify-content: space-between;
                  flex-direction: row-reverse;
                }
                a{
                  .digieized{
                    width: 152px;
                    height: auto;
                  }
                }
                .toggle {
                    margin-right: 12px;
                    @include breakpoint($secreen-xs){
                      margin-right: 0;
                      transform: scale(-1, 1);
                    }
                    &:hover {
                        cursor: pointer;
                        .first {
                            width: 25px !important;
                            transition: 0.3s;
                            background-color: $bg-primary !important;
                        }
                        .second {
                            transition: 0.3s;
                            background-color: $bg-primary !important;
                        }
                        .third {
                            width: 25px !important;
                            transition: 0.3s;
                            background-color: $bg-primary !important;
                        }
                    }
                    .toggle-line {
                        display: flex;
                        flex-direction: row;
                    }
                    .first {
                        width: 21px;
                        height: 3px;
                        transition: 0.3s;
                        background-color: $bg-grey-shade-1;
                    }
                    .second {
                        width: 25px;
                        height: 3px;
                        margin: 5px 0;
                        transition: 0.3s;
                        background-color: $bg-grey-shade-1;
                    }
                    .third {
                        width: 16px;
                        height: 3px;
                        transition: 0.3s;
                        background-color: $bg-grey-shade-1;
                    }
                }
            }
            .logo-digitized{
              .digitized{
                width: 152px;
                height: 46px;
              }
            }
            .navigation {
                margin-left: auto;
                display: flex;
                @include breakpoint ($secreen-max-lg){
                  display: none;
                }
                .navigation-ul {
                    display: flex;
                    align-items: center;
                    .navigation-li {
                        position: relative;
                        &:hover{
                          .drop-ul{
                             opacity: 1;
                             visibility: visible;
                             transform: perspective(300px) rotateX(0deg);
                             top: 100%;
                           }
                        }
                        &:not(:first-child) {
                            margin-left: 32px;
                        }
                        .links {
                            display: flex;
                            align-items: center;
                            line-height: 52px;
                            cursor: pointer;
                            @include site-transition;
                            &:hover{
                              p{
                                color: $text-primary;
                              }
                              .drop-arrow{
                                color: $text-primary;
                                
                              }
                            }
                            p {
                                text-transform: capitalize;
                                color: $text-neutral;
                                font-family: $font-family-poppins-medium;
                                font-weight: 500;
                                @include site-transition;
                            }
                            .drop-arrow {
                                margin-left: 3px;
                                position: relative;
                                top: 0px;
                                color: $text-grey-shade-1;
                                @include site-transition;
                            }
                        }
                        .drop-ul{
                          background-color: $bg-white;
                          box-shadow: $box-shadow;
                          position: absolute;
                          top: 100px;
                          width: 270px;
                          z-index: 10;
                          opacity: 0;
                          visibility: hidden;
                          border-radius: 0;
                          left: -20px;
                          transform-origin: 50% -50px;
                          transform: perspective(300px) rotateX(-8deg);
                          transition: all cubic-bezier(.4,0,.2,1) .4s;
                          padding: 16px 0;
                          .drop-li{
                            position: relative;
                            &:hover{
                              background-color: $bg-primary;
                              .other-ul{
                                opacity: 1;
                                visibility: visible;
                                transform: perspective(300px) rotateX(0deg);
                              }
                              .drop-links{
                                color: $text-white;
                                @include site-transition;
                                background-color: $bg-primary;
                                cursor: pointer;
                                &:hover{
                                  .drop-arrow{
                                    transform: rotate(-270deg);
                                    @include site-transition;
                                  }
                                }
                                .drop-arrow{
                                  color: $text-white;
                                }
                              }
                            }
                            .drop-links{
                              padding: 12px 32px;
                              color: $text-grey-shade-1;
                              font-family: $font-family-poppins-medium;
                              font-weight: 500;
                              position: relative;
                              display: flex;
                              align-items: center;
                              cursor: pointer;
                              text-transform: capitalize;
                              justify-content: space-between;
                              .drop-arrow{
                                transform: rotate(-90deg);
                                color: $text-grey-shade-1;
                                @include site-transition;
                              }
                            }
                            .other-ul{
                              position: absolute;
                              right: 254px;
                              top: -16px;
                              background-color: $bg-white;
                              box-shadow: $box-shadow;
                              width: 270px;
                              z-index: 10;
                              opacity: 0;
                              visibility: hidden;
                              border-radius: 0;
                              transform-origin: 50% -50px;
                              transform: perspective(300px) rotateX(-8deg);
                              transition: all cubic-bezier(.4,0,.2,1) .4s;
                              padding: 16px 0;
                              .other-li{
                                position: relative;
                                .other-links{
                                  padding: 12px 32px;
                                  color: $text-grey-shade-1;
                                  font-family: $font-family-poppins-medium;
                                  font-weight: 500;
                                  position: relative;
                                  text-transform: capitalize;
                                  &:hover{
                                    color: $text-white;
                                    @include site-transition;
                                    background-color: $bg-primary;
                                  }
                                }
                              }
                            }
                          }
                        }
                    }
                }
            }
            .action {
                display: flex;
                margin-left: 32px;
                overflow: hidden;
                position: relative;
                margin-left: auto;
                .contact-btn {
                    @include site-btn;
                    padding: 13px 23px;
                    border: 1px solid $border-primary;
                    background-color: transparent;
                    color: $text-primary;
                    &:hover{
                      background-color: $text-primary;
                      color: $text-white;
                    }
                }
                @include breakpoint($secreen-xs){
                  display: none;
                }
            }
        }
        .nav2{

        }
        #nav{
        }
    }
}

//=============  Header End Here ==============//





//=============  Toggle Menu Start Here ==============//

#mySidenav{

}
.sidenav1{
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: $bg-neutral;
  overflow-x: scroll;
  transition: 0.5s;
  padding-top: 40px;
  @include breakpoint($secreen-xs){
    position: fixed;
    right: 0 !important;
    left: auto;
  }
  .closebtn{
    .close-icon{
      position: absolute;
      top: 15px;
      right: 25px;
      color: $text-grey-shade-1;
      font-size: 24px;
      @include site-transition;
      &:hover{
        color: $text-white;
      }
    }
  }
  .main-menu{
    display: flex;
    justify-content: space-between;
    width: 1140px;
    margin: 100px auto 0 auto;
    @include breakpoint($secreen-max-lg){
      width: 100%;
      padding: 0 40px;
    }
    @include breakpoint($secreen-max-sm){
      margin: 100px auto 100px auto;
    }
    .nav{
      display: flex;
      flex-direction: column;
      margin-right: 100px;
      @include breakpoint($secreen-max-md){
        margin-right: 50px;
      }
      @include breakpoint($secreen-max-sm){
        width: 100%;
        margin: 0;
        align-content: center;
      }
      .dropdown{
        .dropdown-toggle{
          margin-bottom: 16px;
          font-size: 20px;
          font-family: $font-family-poppins-medium;
          font-weight: 500;
          color: $text-white;
          text-transform: capitalize;
          @include site-transition;
          &:after{
            display: none;
          }
          &:hover{
            color: $text-primary;
            cursor: pointer;
            .drop-arrow
            {
              color: $text-primary;
            }
          }
          @include breakpoint($secreen-max-sm){
            text-align: center;
          }
          .drop-arrow{
            color: $text-grey-shade-1;
            font-size: 16px;
            @include site-transition;
            position: relative;
            top: 1px;
          }
        }
        .other-toggle{

        }
        .dropdown-toggle-inner{
          margin-left: 20px !important;
          margin-bottom: 16px !important;
          @include breakpoint($secreen-max-sm){
            margin-left: 0 !important;
            text-align: center;
          }
        }
        .dropdown-menu{
          border: none;
          border-radius: 0;
          background-color: transparent;
          position: relative !important;
          @include site-transition;
          height: 0 !important;
          padding: 0;
          .dropdown-item{
            font-size: 20px;
            font-family: $font-family-poppins-medium;
            font-weight: 500;
            color: $text-white;
            text-transform: capitalize;
            margin-bottom: 8px;
            @include site-transition;
            padding: 0 !important;
            margin-left: 20px;
            @include breakpoint($secreen-max-sm){
              margin-left: 0;
              text-align: center;
            }
            &:hover{
              background: transparent;
              color: $text-primary;
            }
          }
          .dropdown-inner{

          }
          .dropdown{


          }
          .sub-drop{
            position: inherit !important;
          }
        }
        .dropdown-menu-inner{
          left: 20px !important;
          @include breakpoint($secreen-max-sm){
            margin-left: 0;
            text-align: center;
            left: 0 !important;
          }
        }
        .show{
          display: block;
          position: relative !important;
          top: -5px !important;
          height: 100% !important;
          width: 100%;
          @include site-transition;
          transform: translate3d(0px,0px,0px) !important;
        }
      }
      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        .contact-btn {
            @include site-btn;
            text-align: center;
        }
        @include breakpoint($secreen-min-sm){
          display: none;
        }
      }
    }
    .grid-inner1{
      display: flex;
      flex-direction: column;
      width: 100%;
      @include breakpoint($secreen-max-sm){
        display: none;
      }
      .input1{
        display: flex;
        align-items: center;
        padding: 13px 30px;
        background-color: white;
        margin-bottom: 32px;
        .input-serch1{
          border: none;
          font-family: $font-family-poppins-regular;
          font-weight: 400;
          background-color: transparent;
          text-transform: capitalize;
          color: $text-grey-shade-1;
          width: 100%;
          &:focus{
            outline: none;
          }
        }
        .search-icon{
          font-size: 16px;
          color: $text-grey-shade-1;
          position: relative;
          top: 1px;
        }
      }
      .contact-main{
        display: flex;
        @include breakpoint($secreen-max-md){
          flex-direction: column;
        }
        .contact-Details{
          margin-right: auto;
          @include breakpoint($secreen-max-md){
            margin-bottom: 32px;
          }
          .contact-Details-heading{
            .contact{
              font-family: $font-family-poppins-regular;
              font-weight: 400;
              font-size: $sm-font-size;
              line-height: $md-line-height;
              color: $text-white;
              text-transform: capitalize;
              margin-bottom: 12px;
              text-align: left;
            }
          }
          .contact-Details-info{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            .info{
              display: inline-flex;
              align-items: center;
              margin-bottom: 16px;
              .main{
                height: 32px;
                width: 32px;
                background-color: $bg-primary;
                border-radius: 50%;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include site-transition;
                .contact{
                  height: auto;
                  color: $text-white;
                  position: relative;
                  top: 1px;
                  @include breakpoint($secreen-thirteen-max){
                    top: 0;
                    right: -0.5px;
                  }
                }
              }
              p{
                font-family: $font-family-poppins-regular;
                font-weight: 400;
                color: $text-white;
              }
            }
          }
        }
        .get-latest{
          .get-latest-heading{
            .latest{
              font-size: $sm-font-size;
              line-height: $md-line-height;
              color: $text-white;
              text-transform: capitalize;
              margin-bottom: 12px;
            }
          }
          .input{
            display: flex;
            align-items: center;
            .input-inner{
              width: 303px;
              padding: 14px 24px;
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-regular;
              font-weight: 400;
              background-color: $bg-white;
              border: none;
              text-transform: capitalize;
              &:focus{
                outline: none;
              }
            }
            .subscribe-btn{
              @include site-btn;
            }
          }
          .icon{
            display: flex;
            align-items: center;
            margin-top: 20px;
            li{
              &:not(:first-child){
                margin-left: 16px;
              }
              a{
                .social-icon{
                  color: $text-grey-shade-1;
                  font-size: 24px;
                  @include site-transition;
                  &:hover{
                    color: $text-white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//=============  Toggle Menu End Here ==============//






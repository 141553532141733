


//================  Heading Start Here =================//

#heading-about3{
  background-color: $bg-grey-shade-3;
  margin-top: 92px;
  @include breakpoint($secreen-max-sm){
    margin-top: 76px;
  }
  .container{
    .heading{
      padding: 70px;
      h2{
        color: $text-neutral;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-lg){
          font-size: 40px;
          line-height: 50px;
        }
        @include breakpoint($secreen-max-sm){
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
}

//================  Heading End Here =================//




//================  About Start Here =================//

#about-3 {
    margin: 128px 0;
    @include breakpoint($secreen-max-md){
      margin: 64px 0;
    }
    .container {
        position: relative;
        .row{
          align-items: center;
          .content {
            position: relative;
            @include breakpoint($secreen-max-md){
              text-align: center;
            }
              .about-tag-outer {
                  display: inline-flex;
                  .about-tag {
                      @include site-tag;
                  }
              }
              .heading {
                  h2 {
                      margin: 18px 0;
                      color: $text-neutral;
                      @include breakpoint($secreen-max-md){
                        font-size: 32px;
                        line-height: 44px;
                      }
                      @include breakpoint($secreen-xs){
                        font-size: 28px;
                        line-height: 36px;
                      }
                  }
                  p {
                      font-family: $font-family-poppins-regular;
                      line-height: $sm-line-height;
                      color: $text-grey-shade-1;
                      font-size: 18px;
                  }
              }
              .headin-admin {
                h3{
                  margin: 20px 0 12px 0;
                }
                p{
                  font-family: $font-family-poppins-regular;
                  font-size: 18px;
                  line-height: $sm-line-height;
                  color: $text-grey-shade-1;
                }
              }
              .heading-mission{
                h3{
                  margin: 20px 0 12px 0;
                }
                p{
                  font-family: $font-family-poppins-regular;
                  font-size: 18px;
                  line-height: $sm-line-height;
                  color: $text-grey-shade-1;
                }
              }
          }
          .shape-outer{
            .shape{
              display: flex;
              justify-content: flex-end;
              .svg {
                position: absolute;
                width: 0;
                height: 0;
              }
              .clipped {
                width: 100%;
                height: 542px;
                background: turquoise url('/../assets/img/about-3/about-image.jpg');
                background-size: cover;
                -webkit-clip-path: url(#my-clip-path);
                clip-path: url(#my-clip-path);
                @include breakpoint($secreen-max-md){
                  display: none;
                }
              }
            }
          }
        }
    }
}

//================  About End Here =================//




//================  Counter Start Here =================//

.counter1-about3{
  margin-bottom: 0 !important;
}

//================  Counter End Here =================//




//================  Services Start Here =================//

#services-home2-about3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $grey-shade-1;
            font-size: 18px;
            line-height: $sm-line-height;
          }
        }
      }
      .row{
        margin-top: 64px;
        .services-box-outer{
          .services-box{
            padding: 36px;
            box-shadow: $box-shadow;
            display: flex;
            align-items: center;
            background-color: $bg-white;
            @include breakpoint($secreen-lg){
              padding: 40px 25px 40px 40px;
            }
            @include breakpoint($secreen-max-sm){
              align-items: baseline;
            }
            .icon{
              width: 100px;
              height: 80px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              @include breakpoint($secreen-lg){
                width: 130px;
              }
              @include breakpoint($secreen-max-md){
                width: 80px;
              }
              @include breakpoint($secreen-max-sm){
                width: auto;
                height: auto;
                display: block;
              }
              .services-icon{
                font-size: 36px;
                position: relative;
              }
            }
            .draw-square{
              background-color: $bg-cosmos;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .bullhorn{
              background-color: $bg-oasis;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .code{
              background-color: $bg-icecold;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .bullseye{
              background-color: $bg-blue-chalk;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .content{
              margin-left: 20px;
              .heading-inner{
                h3{
                  color: $text-neutral;
                }
                .desc-inner{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  line-height: 30px;
                  margin: 12px 0px 16px 0px;
                }
                .news-blog-btn{
                  display: inline-flex;
                  align-items: center;
                  position: relative;
                  overflow: hidden;
                  width: 125px;
                  &:hover{
                    .arrow{
                      animation: news .4s;
                      @include news-blog;
                    }
                  }
                  .read{
                    text-transform: capitalize;
                    color: $text-primary;
                    font-family: $font-family-poppins-medium;
                    margin-right: 8px;
                    line-height: 18px;
                  }
                  .arrow{
                    font-size: 21px;
                    color: $text-primary;
                  }
                }
              }
            }
          }
          .box1,
          .box2{
            margin-bottom: 30px;
          }
          .box3{
            @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

//================  Services End Here =================//




//================  Let's Started Start Here =================//

#started-about3{
  background-color: $bg-primary;
  .container{
    .wrapper{
      padding: 64px 0;
      .heading{
        text-align: center;
        h2{
          color: $text-white;
          @include breakpoint($secreen-max-md){
            font-size: 32px;
            line-height: 44px;
          }
        }
         p{
          font-family: $font-family-poppins-regular;
          
          color: $text-white;
          margin-top: 8px;
        }
        .input{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
          @include breakpoint($secreen-xs){
            flex-direction: column;
          }
          .input-inner{
            width: 382px;
            padding: 14px 24px;
            font-family: $font-family-poppins-regular;
            
            color: $text-grey-shade-1;
            background-color: $bg-white;
            border-radius: 0 !important;
            border: none;
            text-transform: capitalize;
            box-shadow: $box-shadow;
            &:focus{
              outline: none;
            }
            @include breakpoint($secreen-xs){
              width: 100%;
            }
          }
          .subscribe-btn{
            @include breakpoint($secreen-xs){
              margin-top: 24px;
            }
            @include site-btn;
            background-color: $bg-neutral;
          }
        }
      }
    }
  }
}

//================  Let's Started End Here =================//

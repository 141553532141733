

//============= Heading Start Here ==============//

#heading-team{
  background-color: $bg-primary;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//






//============= Team Start Here ==============//

#team-3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 16px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            
          }
        }
      }
      .row{
        margin-top: 64px;
        justify-content: center;
        .team-box{
          .team-image{
            position: relative;
            box-shadow: $box-shadow;
            overflow: hidden;
            @include site-transition;
            &:hover{
              .overlay-icons .about-person{
                transform: perspective(300px) rotateX(0deg) translateY(-10px);
                opacity: 0;
              }
              .overlay-icons .icon{
                transform: perspective(300px) rotateX(0deg) translateY(-27px);
                opacity: 1;
              }
            }
            .img-fluid{
              width: 100%;
            }
            .overlay-icons{
              background-color: $bg-white;
              padding: 8px 12px 16px 16px;
              overflow: hidden;
              position: absolute;
              bottom: 0;
              width: 100%;
              .about-person{
                text-align: center;
                display: flex;
                flex-direction: column;
                opacity: 1;
                transform: perspective(300px) rotateX(-8deg) translateY(16px);
                transition: all cubic-bezier(.4,0,.2,1) .5s;
                h3{
                  color: $text-neutral;
                }
                 p{
                  
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-regular;
                  margin-top: 4px;
                }
              }
              .icon{
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transform: perspective(300px) rotateX(0deg) translateY(-2px);
                transition: all cubic-bezier(.4,0,.2,1) .5s;
                @include breakpoint($secreen-xs){
                  justify-content: center;
                }
                li{
                  &:not(:first-child){
                    margin-left: 16px;
                  }
                  a{
                    .social-icon{
                      color: $text-grey-shade-1;
                      font-size: 24px;
                      @include site-transition;
                      &:hover{
                        color: $text-primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .box1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box2{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//============= Team End Here ==============//

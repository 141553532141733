
//===============  Hero Section Start Here ================//

#slider2{
  position: relative;
  top: 92px;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  @include breakpoint($secreen-max-sm){
    top: 77px;
  }
  .container{
    .wrapper{
      margin-top: 128px;
      @include breakpoint($secreen-max-md){
        margin-top: 64px;
      }
      .heading{
        text-align: center;
        .slider-tag-outer{
          display: inline-flex;
          .slider-tag{
            @include site-tag;
          }
        }
        .slider-heading-outer{
          .slider-heading{
            margin-top: 18px;
            margin-bottom: 18px;
            color: $text-neutral;
            font-size: $xl-font-size;
            line-height: $xl-line-height;
            @include breakpoint($secreen-max-lg){
              font-size: 40px;
              line-height: 50px;
            }
            @include breakpoint($secreen-max-sm){
              font-size: 32px;
              line-height: 48px;
            }
          }
        }
        .slider-desc-outer{
          .slider-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            line-height: $sm-line-height;
            font-size: 18px;
          }
        }
        .input{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
          @include breakpoint($secreen-xs){
            flex-direction: column;
          }
          .input-inner{
            width: 382px;
            padding: 17px 32px;
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            background-color: $bg-white;
            border: none;
            text-transform: capitalize;
            box-shadow: $box-shadow;
            &:focus{
              outline: none;
            }
            @include breakpoint($secreen-xs){
              width: 100%;
            }
          }
          .subscribe-btn{
            @include breakpoint($secreen-xs){
              margin-top: 16px;
            }
            @include site-btn;
            padding: 17px 32px;
          }
        }
      }
      .shape{
        margin-top: 64px;
        @include breakpoint($secreen-max-md){
          display: none;
        }
        .svg {
            position: absolute;
            width: 0;
            height: 0;
        }
        .clipped {
            width: 100%;
            height: 590px;
            background: turquoise url('/../assets/img/home2/slider-image.jpg');
            background-size: cover;
            -webkit-clip-path: url(#my-clip-path);
            clip-path: url(#my-clip-path);
        }
      }
    }
  }
}

//===============  Hero Section End Here ================//



//===============  Services Start Here ================//

#services-home2{
  margin: 200px 0 128px 0;
  @include breakpoint($secreen-max-md){
    margin: 156px 0 64px 0;
  }
  @include breakpoint($secreen-max-sm){
    margin: 140px 0 64px 0;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .services-box-outer{
          .services-box{
            padding: 36px;
            box-shadow: $box-shadow;
            display: flex;
            align-items: center;
            background-color: $bg-white;
            @include breakpoint($secreen-lg){
              padding: 40px 25px 40px 40px;
            }
            @include breakpoint($secreen-max-sm){
              align-items: baseline;
            }
            .icon{
              width: 100px;
              height: 80px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              @include breakpoint($secreen-lg){
                width: 130px;
              }
              @include breakpoint($secreen-max-md){
                width: 80px;
              }
              @include breakpoint($secreen-max-sm){
                width: auto;
                height: auto;
                display: block;
              }
              .services-icon{
                font-size: 36px;
                position: relative;
              }
            }
            .draw-square{
              background-color: $bg-cosmos;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .bullhorn{
              background-color: $bg-oasis;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .code{
              background-color: $bg-icecold;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .bullseye{
              background-color: $bg-blue-chalk;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .mobile{
              background-color: $bg-lavender-pink;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .pen-alt{
              background-color: $bg-blue-romance;
              @include breakpoint($secreen-max-sm){
                background-color: transparent;
                position: relative;
                top: 13px;
              }
            }
            .content{
              margin-left: 20px;
              .heading-inner{
                h3{
                  color: $text-neutral;
                }
                .desc-inner{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  line-height: 30px;
                  margin: 12px 0px 16px 0px;
                }
                .news-blog-btn{
                  display: inline-flex;
                  align-items: center;
                  position: relative;
                  overflow: hidden;
                  width: 125px;
                  &:hover{
                    .arrow{
                      animation: news .4s;
                      @include news-blog;
                    }
                  }
                  .read{
                    text-transform: capitalize;
                    color: $text-primary;
                    font-family: $font-family-poppins-medium;
                    margin-right: 8px;
                    line-height: 18px;
                  }
                  .arrow{
                    font-size: 21px;
                    color: $text-primary;
                    position: relative;
                  }
                }
              }
            }
          }
          .box1,
          .box2{
            margin-bottom: 30px;
          }
          .box3{
            @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
            }
          }
          .box5{
            @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
            }
          }
          .box3-services,
          .box4-services{
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//===============  Services End Here ================//



//===============  Clients Start Here ================//

#clients-home2{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .clients-tag-outer{
          display: flex;
          justify-content: center;
          .clients-tag{
            @include site-tag;
          }
        }
        .clients-heading-outer{
          .clients-heading{
            color: $text-neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .clients-desc-outer{
          .clients-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        align-items: center;
        margin-top: 64px;
        .clients-box{
          &:hover{
            .clients-box-inner .img{
              opacity: 1;
            }
          }
          .clients-box-inner{
            position: relative;
            background-color: $bg-remy;
            padding: 45px 48px;
            .img{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @include site-transition;
              opacity: .5;
            }
          }
        }
        .box1,
        .box2,
        .box3,
        .box4{
          margin-bottom: 30px;
        }
        .box1,
        .box2,
        .box3,
        .box4,
        .box5,
        .box6{
          @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
          }
        }
        .box7{
          @include breakpoint($secreen-max-sm){
              margin-bottom: 30px;
          }
        }
        .box8{
          @include breakpoint($secreen-max-sm){
              margin-bottom: 0px;
          }
        }
      }
    }
  }
}

//===============  Clients End Here ================//



//===============  Portfolio Start Here ================//

#portfolio-home2{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .portfolio-tag-outer{
          display: flex;
          justify-content: center;
          .portfolio-tag{
            @include site-tag;
          }
        }
        .portfolio-heading-outer{
          .portfolio-heading{
            color: $text-neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .portfolio-desc-outer{
          .portfolio-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .portfolio-box-outer{
          .portfolio-box{
            padding: 36px;
            box-shadow: $box-shadow;
            display: flex;
            align-items: center;
            background-color: $bg-white;
            overflow: hidden;
            @include breakpoint($secreen-xs){
              flex-direction: column;
            }
            &:hover{
              .portfolio-image{
                transform: scale(1.1);
              }
            }
            .image{
              overflow: hidden;
              @include breakpoint($secreen-xs){
                width: 100%;
              }
              .portfolio-image{
                width: 100%;
                height: auto;
                @include site-transition;
              }
            }
            .content{
              margin-left: 32px;
              @include breakpoint($secreen-xs){
                margin-top: 28px;
                margin-left: 0;
                width: 100%;
              }
              .heading-inner{
                .portfolio-links{
                  font-family: $font-family-poppins-semibold;
                  color: $text-neutral;
                  text-transform: capitalize;
                  font-size: $md-font-size;
                  line-height: $md-line-height;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  line-height: $x-sm-line-height;
                  margin: 12px 0px 20px 0px;
                }
                .action{
                  display: flex;
                  .portfolio-btn{
                    @include site-btn;
                    background-color: transparent;
                    border: 1px solid $border-primary;
                    color: $primary;
                    padding: 13px 24px;
                    &:hover{
                      background-color: $bg-primary;
                      color: $text-white;
                    }
                  }
                }
              }
            }
          }
          .box1,
          .box2{
            margin-bottom: 30px;
          }
          .box3{
            @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

//===============  Portfolio End Here ================//



//===============  Features Start Here ================//

#features-home2{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .features-tag-outer{
          display: flex;
          justify-content: center;
          .features-tag{
            @include site-tag;
          }
        }
        .features-heading-outer{
          .features-heading{
            color: $text-neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .features-desc-outer{
          .features-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .left{
          @include breakpoint($secreen-min-lg){
            padding: 0;
          }
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
          .features-box{
            box-shadow: $box-shadow;
            padding: 36px 36px 32px 36px;
            position: relative;
            background-color: $bg-white;
            &:not(:last-child){
              margin-bottom: 30px;
            }
            .icon{
              display: flex;
              justify-content: center;
              margin-bottom: 13px;
              position: relative;
              z-index: 1;
              .mutli-pages{

              }
            }
            .inner-heading{
              h3{
                font-family: $font-family-poppins-semibold;
                font-size: $md-font-size;
                line-height: $md-line-height;
                text-align: center;
                margin-top: 24px;
              }
            }
          }
          .features-bg1{
            &:after{
              content: "";
              position: absolute;
              top: 33%;
              left: 42%;
              z-index: 0;
              transform: translate(-50%, -50%);
              background-image: url('/../assets/img/home2/features-icon-shape-1.svg');
              width: 48px;
              height: 51px;
              @include breakpoint($secreen-max-sm){
                left: 46%;
              }
            }
          }
          .features-bg2{
            &:after{
              content: "";
              position: absolute;
              top: 37%;
              left: 42%;
              z-index: 0;
              transform: translate(-50%, -50%);
              background-image: url('/../assets/img/home2/features-icon-shape-2.svg');
              width: 48px;
              height: 51px;
              @include breakpoint($secreen-max-sm){
                left: 46%;
              }
            }
          }
          .features-bg3{
            &:after{
              content: "";
              position: absolute;
              top: 37%;
              left: 42%;
              z-index: 0;
              transform: translate(-50%, -50%);
              background-image: url('/../assets/img/home2/features-icon-shape-3.svg');
              width: 48px;
              height: 51px;
              @include breakpoint($secreen-max-sm){
                left: 46%;
              }
            }
          }
        }
        .shape1{
          padding: 50px;
          @include breakpoint($secreen-max-md){
            display: none;
          }
          .svg {
                position: absolute;
                width: 0;
                height: 0;
                }
                .clipped {
                  width: 100%;
                  height: 470px;
                  background: url('/../assets/img/home2/features.jpg');
                  background-size: cover;
                  -webkit-clip-path: url(#my-clip-path1);
                  clip-path: url(#my-clip-path1);
                }
              }
        .right{
          @include breakpoint($secreen-min-lg){
            padding: 0;
          }
          .features-box{
            box-shadow: $box-shadow;
            padding: 36px 36px 32px 36px;
            position: relative;
            background-color: $bg-white;
            &:not(:last-child){
              margin-bottom: 30px;
            }
            .icon{
              display: flex;
              justify-content: center;
              margin-bottom: 13px;
              position: relative;
              z-index: 1;
              .mutli-pages{

              }
            }
            .inner-heading{
              h3{
                font-family: $font-family-poppins-semibold;
                margin-top: 24px;
                font-size: $md-font-size;
                line-height: $md-line-height;
                text-align: center;
              }
            }
          }
          .features-bg4{
            &:after{
              content: "";
              position: absolute;
              top: 33%;
              left: 42%;
              z-index: 0;
              transform: translate(-50%, -50%);
              background-image: url('/../assets/img/home2/features-icon-shape-4.svg');
              width: 48px;
              height: 51px;
              @include breakpoint($secreen-max-sm){
                left: 46%;
              }
            }
          }
          .features-bg5{
            &:after{
              content: "";
              position: absolute;
              top: 37%;
              left: 42%;
              z-index: 0;
              transform: translate(-50%, -50%);
              background-image: url('/../assets/img/home2/features-icon-shape-5.svg');
              width: 48px;
              height: 51px;
              @include breakpoint($secreen-max-sm){
                left: 46%;
              }
            }
          }
          .features-bg6{
            &:after{
              content: "";
              position: absolute;
              top: 37%;
              left: 42%;
              z-index: 0;
              transform: translate(-50%, -50%);
              background-image: url('/../assets/img/home2/features-icon-shape-6.svg');
              width: 48px;
              height: 51px;
              @include breakpoint($secreen-max-sm){
                left: 46%;
              }
            }
          }
        }
      }
    }
  }
}

//===============  Features End Here ================//



//===============  Testimonials Start Here ================//

#testimonials-home2{
  position: relative;
  .overlay{
    position: relative;
    padding: 128px 0;
    background-color: $bg-woodsmoke;
    width: 100%;
    @include breakpoint($secreen-max-md){
      padding: 64px 0;
    }
    &:before{
      content: "";
      position: absolute;
      background-color: $bg-neutral;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .8;
      z-index: 1;
    }
    .container{
      .wrapper{
        position: relative;
        z-index: 1;
        .heading{
          text-align: center;
          .testimonials-tag-outer{
            display: flex;
            justify-content: center;
            .testimonials-tag{
              @include site-tag;
            }
          }
          .testimonials-heading-outer{
            .testimonials-heading{
              color: $text-white;
              margin: 18px 0;
              @include breakpoint($secreen-max-md){
                font-size: 32px;
                line-height: 44px;
              }
              @include breakpoint($secreen-xs){
                font-size: 28px;
                line-height: 36px;
              }
            }
          }
          .testimonials-desc-outer{
            .testimonials-desc{
              font-family: $font-family-poppins-regular;
              color: $text-white;
              font-size: 18px;
            }
          }
        }
        .inner-wrapper1{
          margin-top: 64px;
          .your-class{
            .slick-prev,
            .slick-next{
                top: 38% !important;
            }
            .sllide-home2{
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $bg-white;
              @include breakpoint($secreen-xs){
                flex-direction: column;
              }
              &:focus{
                outline: none;
              }
              .img{
                @include breakpoint($secreen-xs){
                  display: none;
                }
                .image{
                  @include breakpoint($secreen-xs){
                    display: none;
                  }
                }
              }
              .content{
                padding: 36px;
                @include breakpoint($secreen-max-sm){
                  padding: 15px 24px;
                }
                .quote{
                  color: $text-primary;
                }
                .about-person{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  padding: 16px 0 16px 0;
                  line-height: 30px;
                }
                .person-name{
                  font-size: $md-font-size;
                  font-family: $font-family-poppins-semibold;
                  color: $text-neutral;
                  text-transform: capitalize;
                }
                .person-work{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//===============  Testimonials End Here ================//




//===============  Counter Start Here ================//

#counter-hom2{
  margin-bottom: 128px;
  background-color: $bg-grey-shade-3;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 64px 48px;
      @include breakpoint($secreen-max-md){
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 64px 0;
      }
      @include breakpoint($secreen-xs){
        display: flex;
        flex-direction: column;
      }
      .counter1{
        display: flex;
        flex-direction: column;
        @include breakpoint($secreen-max-md){
          margin: 0 auto;
        }
        @include breakpoint($secreen-xs){
          margin-bottom: 32px;
          align-items: center;
        }
        .counter-inner1{
          display: flex;
          align-items: center;
          .counter-heading{
            font-family: $font-family-poppins-semibold;
            color: $text-neutral;
            font-size: $xl-font-size;
          }
          strong{
            font-family: $font-family-poppins-semibold;
            font-size: $xl-font-size;
            color: $text-neutral;
          }
        }
        .desc{
          p{
            color: $text-grey-shade-1;
            font-family: $font-family-poppins-regular;
            position: relative;
            top: -4px;
          }
        }
      }
      .counter1-about3{
        margin-bottom: 0 !important;
      }
    }
  }
}

//===============  Counter End Here ================//



//===============  Pricing Plan Start Here ================//

#pricing-home2{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .pricing-tag-outer{
          display: flex;
          justify-content: center;
          .pricing-tag{
            @include site-tag;
          }
        }
        .pricing-heading-outer{
          .pricing-heading{
            color: $text-neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .pricing-desc-outer{
          .pricing-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .inner-wrapper{
        margin-top: 62px;
        .price-tabs{
          display: flex !important;
          .nav{
            display: inline-flex;
            margin: 0 auto;
            justify-content: center;
            border: 1px solid $border-primary;
            .item{
                .link{
                  padding: 14px 24px;
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-regular;
                  text-transform: capitalize;
                  @include site-transition;
                  &:hover{
                    background-color: $bg-primary;
                    color: $text-white;
                  }
                }
                .active{
                  background-color: $bg-primary;
                  color: white;
                }
            }
          }
        }
        .tab-content{
          margin-top: 64px;
          .container1{
            .row{
              .price-plan-outer{
                .price-plan{
                  display: flex;
                  box-shadow: $box-shadow;
                  @include breakpoint($secreen-max-sm){
                    flex-direction: column;
                  }
                  .content-begginner{
                    width: 100%;
                    height: 100%;
                    padding: 36px 0px 36px 36px;
                    background-color: $bg-white;
                    .beginner{
                      .beginner-plan{
                        font-family: $font-family-poppins-semibold;
                        font-size: 24px;
                        color: $text-neutral;
                        margin-bottom: 8px;
                        text-transform: capitalize;
                      }
                      .startup-business{
                        font-family: $font-family-poppins-regular;
                        color: $text-grey-shade-1;
                      }
                    }
                    .price{
                      .total-price{
                        font-family: $font-family-poppins-semibold;
                        line-height: $xl-line-height;
                        color: $text-neutral;
                        font-size: $xl-font-size;
                        margin: 8px 0 0px 0;
                      }
                      .Monthly{
                        font-family: $font-family-poppins-regular;
                        color: $text-grey-shade-1;
                      }
                    }
                    .action{
                      display: flex;
                      margin-top: 24px;
                      .pricing-btn{
                        @include site-btn;
                        background-color: transparent;
                        border: 1px solid $border-primary;
                        color: $primary;
                        padding: 14px 24px;
                        &:hover{
                          background-color: $bg-primary;
                          color: $text-white;
                        }
                      }
                    }
                  }
                  .get-offer{
                    background-color: $bg-primary;
                    width: 100%;
                    padding: 36px 0px 36px 36px;
                    display: flex;
                    align-items: center;
                    ul{
                      li{
                        &:not(:last-child){
                          margin-bottom: 13px;
                        }
                      }
                      .get{
                        display: flex;
                        align-items: center;
                        .dot-color{
                          color: $text-white;
                          font-size: 18px;
                        }
                        .offers{
                          font-family: $font-family-poppins-regular;
                          color: $text-white;
                          margin-left: 8px;
                        }
                      }
                      .no-get{
                        display: flex;
                        align-items: center;
                        position: relative;
                        &:after{
                          content: "";
                          position: absolute;
                          width: 132%;
                          height: 1px;
                          background-color: #ffffff;
                          right: -59px;
                          top: 50%;
                          transform: translate(0, -50%);
                          @include breakpoint($secreen-xs){
                            width: 100%;
                            right: 0;
                          }
                        }
                        .dot-color{
                          color: $text-white;
                          font-size: 18px;
                        }
                        .offers{
                          font-family: $font-family-poppins-regular;
                        
                          color: $text-white;
                          margin-left: 8px;
                        }
                      }
                    }
                  }
                }
              }
              .box1{
                @include breakpoint($secreen-max-md){
                  margin-bottom: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//===============  Pricing Plan End Here ================//



//===============  Let's Started Start Here ================//

#started{
  margin-bottom: 128px;
  background-color: $bg-primary;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      padding: 64px 0;
      .heading{
        text-align: center;
        h2{
          color: $text-white;
          @include breakpoint($secreen-max-md){
            font-size: 32px;
            line-height: 44px;
          }
        }
         p{
          font-family: $font-family-poppins-regular;
          color: $text-white;
          margin-top: 18px;
          font-size: 18px;
        }
        .input{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
          @include breakpoint($secreen-xs){
            flex-direction: column;
          }
          .input-inner{
            width: 382px;
            padding: 14px 24px;
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            background-color: $bg-white;
            border: none;
            text-transform: capitalize;
            box-shadow: $box-shadow;
            &:focus{
              outline: none;
            }
            @include breakpoint($secreen-xs){
              width: 100%;
            }
          }
          .subscribe-btn{
            @include breakpoint($secreen-xs){
              margin-top: 16px;
            }
            @include site-btn;
            background-color: $bg-neutral;
          }
        }
      }
    }
  }
}

//===============  Let's Started End Here ================//




//===============  Blog Start Here ================//

#blog-home2{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .news-blog-tag-outer{
          display: flex;
          justify-content: center;
          .news-blog-tag{
            @include site-tag;
          }
        }
        .news-blog-heading-outer{
          .news-blog-heading{
            color: $text-neutral;
            margin: 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .news-blog-desc-outer{
          .news-blog-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .blog-box-outer{
          .blog-box{
            box-shadow: $box-shadow;
            background-color: $bg-white;
            overflow: hidden;
            &:hover{
              .img-blog{
                transform: scale(1.1);
              }
            }
            .content{
              padding: 36px;
              .heading-inner{
                .blog-hed{
                  font-family: $font-family-poppins-semibold;
                  color: $text-neutral;
                  font-size: $md-font-size;
                  line-height: $md-line-height;
                  @include site-transition;
                  @include breakpoint($secreen-max-md){
                    font-size: 22px;
                  }
                  &:hover{
                    color: $text-primary;
                  }
                }
              }
              .admin{
                display: flex;
                align-items: center;
                margin: 16px 0 16px 0;
                a{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                }
                span{
                  margin: 0 5px;
                  color: $text-grey-shade-1;
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                }
              }
              .action{
                .news-blog-btn{
                  display: inline-flex;
                  align-items: center;
                  position: relative;
                  overflow: hidden;
                  width: 125px;
                  &:hover{
                    .arrow{
                      animation: news .4s;
                      @include news-blog;
                    }
                  }
                  p{
                    text-transform: capitalize;
                    color: $text-primary;
                    font-family: $font-family-poppins-medium;
                    margin-right: 8px;
                  }
                  .arrow{
                    font-size: 21px;
                    color: $text-primary;
                  }
                }
              }
            }
            .image{
              overflow: hidden;
              margin-left: 36px;
              .img-blog{
                width: 100%;
                height: auto;
                @include site-transition;
              }
            }
          }
        }
        .box1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box2{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//===============  Blog End Here ================//



//===============  Footer Start Here ================//

#footer-home2{
  padding: 128px 0 32px 0;
  background-color: $bg-woodsmoke;
  @include breakpoint($secreen-max-md){
    padding: 64px 0;
  }
  .container{
    .wrapper{
      .row{
        .digieized-outer{
          .digieized{
            @include breakpoint($secreen-max-sm){
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .logo{
              .digieized-logo{
                width: 170px;
                height: 46px;
              }
            }
            .desc{
              p{
                font-family: $font-family-poppins-regular;
                line-height: $sm-line-height;
                color: $text-white;
                margin: 18px 0 18px 0;
                @include breakpoint($secreen-max-sm){
                  text-align: center;
                }
              }
            }
            .action{
              display: flex;
              .footer-btn{
                @include site-btn;
                background-color: transparent;
                border: 1px solid $border-primary;
                color: $text-white;
                padding: 13px 24px;
                &:hover{
                  background-color: $bg-primary;
                  color: $text-white;
                }
              }
            }
          }
        }
        .quick-links-outer{
          @include breakpoint($secreen-max-sm){
            margin-top: 64px;
          }
          .quick-links{
            @include breakpoint($secreen-max-sm){
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .quick-heading{
              h4{
                color: $text-white;
                margin-bottom: 16px;
              }
            }
            .quick-links-inner{
              display: flex;
              align-items: center;
              ul{
                line-height: 38px;
                li{
                  a{
                    font-family: $font-family-poppins-regular;
                    color: $text-white;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                }
              }
              .first-col{
                margin-right: 32px;
              }
            }
          }
        }
        .follow-links-outer{
          @include breakpoint($secreen-max-md){
            margin-top: 64px;
          }
          @include breakpoint($secreen-max-sm){
            text-align: center;
          }
          .follow-links{
            @include breakpoint($secreen-max-sm){
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            margin: 0 auto;
            .follow-heading{
              h4{
                color: $text-white;
                margin-bottom: 16px;
              }
            }
            .media-links{
              ul{
                line-height: 38px;
                li{
                  a{
                    font-family: $font-family-poppins-regular;
                    color: $text-white;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                }
              }
            }
          }
        }
        .contact-Details-outer{
          @include breakpoint($secreen-max-md){
            margin-top: 64px;
          }
          .contact-Details{
            display: flex;
            flex-direction: column;
            justify-self: flex-end;
            @include breakpoint($secreen-max-sm){
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .contact-Details-heading{
              .contact{
                color: $text-white;
                margin-bottom: 19px;
              }
            }
            .contact-Details-info{
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              .info{
                display: inline-flex;
                align-items: center;
                .main{
                  height: 32px;
                  width: 32px;
                  background-color: $bg-primary;
                  border-radius: 50%;
                  margin-right: 10px;
                  text-align: center;
                  @include site-transition;
                  .contact{
                    height: auto;
                    line-height: 32px;
                    color: $text-white;
                  }
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-white;
                }
              }
              .center{
                margin: 20px 0;
              }
            }
          }
        }
      }
      #copyright{
      margin-top: 128px;
      @include breakpoint($secreen-xs){
        margin-top: 64px;
      }
        .wrapper{
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          @include breakpoint($secreen-max-md){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .left{
            @include breakpoint($secreen-max-md){
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;
              align-items: center;
            }
            .icon{
              display: flex;
              align-items: center;
              margin-bottom: 18px;
              li{
                &:not(:first-child){
                  margin-left: 16px;
                }
                a{
                  .social-icon{
                    color: $text-white;
                    font-size: 24px;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                }
              }
            }
            .copyright-home2{
              .copyright{
                font-family: $font-family-poppins-regular;
                color: $text-white;
                margin-top: 8px;
                text-align: center;
                .link{
                  color: $text-primary;
                  display: inline-flex;
                }
              }
            }
          }
          .right{
            margin-top: 4px;
            .condition-home2{
              display: flex;
              align-items: center;
              justify-content: center;
              a{
                font-family: $font-family-poppins-regular;
                color: $text-white;
                @include site-transition;
                &:hover{
                  color: $text-primary;
                }
              }
              span{
                font-family: $font-family-poppins-regular;
              
                color: $text-white;
                margin: 0 5px;
              }
              a{
                font-family: $font-family-poppins-regular;
              
                color: $text-white;
                @include site-transition;
                &:hover{
                  color: $text-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

//===============  Footer End Here ================//




//============= Heading Start Here ==============//

#heading-blog-posts{
  background-color: $bg-primary;
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 52px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//


//============= post-details Start Here ==============//

#blog-with-sidebar{
  .container{
    .row{
      margin: 104px 0;
      @include breakpoint($secreen-max-md){
        margin: 64px 0;
      }
      .blog-posts{
        @include breakpoint($secreen-xs){
          padding: 0;
        }
        @include breakpoint($secreen-max-md){
          margin-bottom: 64px;
        }
        .row-1{
          margin: 0;
          padding: 0;
          .news-blog-box-outer1{
            .news-blog-box1{
              position: relative;
              overflow: hidden;
              box-shadow: $box-shadow;
              background-color: $bg-white;
              &:hover{
              }
              .image{
                .news-blog-image{
                  width: 100%;
                }
              }
              .content{
                padding: 36px;
                .info-admin{
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  a{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                    margin-right: 4px;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                  p{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                  }
                }
                .news-blog-heading{
                  .blog-link{
                    font-family: $font-family-poppins-semibold;
                    
                    color: $text-neutral;
                    line-height: $md-line-height;
                    font-size: 22px;
                    transition: .5s all ease-in-out;
                    &:hover{
                      color: $text-primary;
                      transition: .5s all ease-in-out;
                    }
                    @include breakpoint($secreen-max-md){
                      font-size: 22px;
                    }
                  }
                }
                .news-blog-btn{
                  display: inline-flex;
                  align-items: center;
                  margin-top: 16px;
                  position: relative;
                  overflow: hidden;
                  width: 125px;
                  &:hover{
                    .arrow{
                      animation: news .4s;
                      @include news-blog;
                    }
                  }
                  p{
                    text-transform: capitalize;
                    color: $text-primary;
                    font-family: $font-family-poppins-medium;
                    
                    margin-right: 8px;
                  }
                  .arrow{
                    font-size: 21px;
                    color: $text-primary;
                  }
                }
              }
            }
          }
          .box{
            @include breakpoint($secreen-xs){
              padding: 0;
            }
          }
          .box1,
          .box2{
            margin-bottom: 30px;
          }
          .blog-box-outer{
            .blog-box{
              box-shadow: $box-shadow;
              background-color: $bg-white;
              overflow: hidden;
              &:hover{
                .img-blog{
                  transform: scale(1.1);
                }
              }
              .content{
                padding: 36px;
                .heading-inner{
                  .blog-hed{
                    font-family: $font-family-poppins-semibold;
                    
                    color: $text-neutral;
                    font-size: 22px;
                    line-height: $md-line-height;
                    @include site-transition;
                    @include breakpoint($secreen-max-md){
                      font-size: 22px;
                    }
                    &:hover{
                      color: $text-primary;
                    }
                  }
                }
                .admin{
                  display: flex;
                  align-items: center;
                  margin: 16px 0 16px 0;
                  a{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                  span{
                    margin: 0 5px;
                    color: $text-grey-shade-1;
                  }
                  p{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                  }
                }
                .action{
                  .news-blog-btn{
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    overflow: hidden;
                    width: 125px;
                    &:hover{
                      .arrow{
                        animation: news .4s;
                        @include news-blog;
                      }
                    }
                    p{
                      text-transform: capitalize;
                      color: $text-primary;
                      font-family: $font-family-poppins-medium;
                      margin-right: 8px;
                    }
                    .arrow{
                      font-size: 21px;
                      color: $text-primary;
                    }
                  }
                }
              }
              .image{
                overflow: hidden;
                margin-left: 36px;
                .img-blog{
                  width: 100%;
                  height: auto;
                  @include site-transition;
                }
              }
            }
          }
          .box{
            @include breakpoint($secreen-xs){
              padding: 0;
            }
          }
          .box5{
            @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
            }
          }
          .news-blog-box-outer{
            .news-blog-box{
              position: relative;
              overflow: hidden;
              box-shadow: $box-shadow;
              background-color: $bg-white;
              padding: 36px;
              overflow: hidden;
              &:hover{
              }
              .image{
                overflow: hidden;
                &:hover{
                  .news-blog-image{
                    transform: scale(1.1);
                  }
                }
                .news-blog-image{
                  width: 100%;
                  @include site-transition;
                }
              }
              .content{
                padding: 32px 0 0 0;
                .info-admin{
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  a{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                    margin-right: 4px;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                  p{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                  }
                }
                .news-blog-heading{
                  .blog-link{
                    font-family: $font-family-poppins-semibold;
                    
                    color: $text-neutral;
                    line-height: $md-line-height;
                    font-size: 22px;
                    transition: .5s all ease-in-out;
                    @include breakpoint($secreen-max-md){
                      font-size: 22px;
                    }
                    &:hover{
                      color: $text-primary;
                      transition: .5s all ease-in-out;
                    }
                  }
                }
                .news-blog-btn{
                  display: inline-flex;
                  align-items: center;
                  margin-top: 16px;
                  position: relative;
                  overflow: hidden;
                  width: 125px;
                  &:hover{
                    .arrow{
                      animation: news .4s;
                      @include news-blog;
                    }
                  }
                  p{
                    text-transform: capitalize;
                    color: $text-primary;
                    font-family: $font-family-poppins-medium;
                    
                    margin-right: 8px;
                  }
                  .arrow{
                    font-size: 21px;
                    color: $text-primary;
                  }
                }
              }
            }
          }
          .box{
            @include breakpoint($secreen-xs){
              padding: 0;
            }
          }
          .box3,
          .box4{
            margin-bottom: 30px;
          }
        }
      }
    }
    .sidebar{
      padding-left: 20px;
      @include breakpoint($secreen-max-sm){
        padding: 0;
      }
      .input-search{
        position: relative;
        box-shadow: $box-shadow;
        .input{
          width: 100%;
          border: none;
          padding: 16px 24px;
          background-color: $bg-sonw;
          color: $text-grey-shade-1;
          font-family: $font-family-poppins-regular;
          background-color: $bg-white;
          text-transform: capitalize;
          @include site-transition;
          &:focus{
            outline: none;
            background-color: $bg-grey-shade-3;
          }
        }
        .search{
          width: 16px;
          position: absolute;
          right: 24px;
          top: 50%;
          color: $text-grey-shade-1;
          transform: translate(0%, -50%);
        }
      }
      .popularposts-heading{
        h3{
          color: $text-neutral;
          margin: 40px 0 20px 0;
        }
      }
      .content-box{
        padding: 36px;
        background-color: $bg-white;
        box-shadow: $box-shadow;
        .info-admin{
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          a{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            margin-right: 4px;
            @include site-transition;
            &:hover{
              color: $text-primary;
            }
          }
          p{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
          }
        }
        .news-blog-heading{
          .blog-link{
            font-family: $font-family-poppins-semibold;
            
            color: $text-neutral;
            line-height: $md-line-height;
            font-size: 22px;
            transition: .5s all ease-in-out;
            &:hover{
              color: $text-primary;
              transition: .5s all ease-in-out;
            }
            @include breakpoint($secreen-max-md){
              font-size: 22px;
            }
          }
        }
        .news-blog-btn{
          display: inline-flex;
          align-items: center;
          margin-top: 16px;
          position: relative;
          overflow: hidden;
          width: 125px;
          &:hover{
            .arrow{
              animation: news .4s;
              @include news-blog;
            }
          }
          p{
            text-transform: capitalize;
            color: $text-primary;
            font-family: $font-family-poppins-medium;
            
            margin-right: 8px;
          }
          .arrow{
            font-size: 21px;
            color: $text-primary;
          }
        }
      }
      .center-box{
        margin: 30px 0;
      }
      .categories{
        margin: 40px 0 24px 0;
        h3{
          color: $text-neutral;
          margin-bottom: 16px;
        }
        .categories-inner{
          display: flex;
          align-items: center;
          line-height: $md-line-height;
          .circle-arrow{
            transform: rotate(-90deg);
            margin-right: 12px;
            color: $text-primary;
            font-size: 18px;
          }
          p{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            text-transform: capitalize;
          }
        }
      }

      .popular-tags{
        h3{
          color: $text-neutral;
          margin-bottom: 20px;
        }
        .tags-inner{
          display: flex;
          flex-wrap: wrap;
          li{
            &:not(:last-child){
              margin-right: 12px;
              margin-bottom: 12px;
            }
            .tag{
              padding: 12px 16px;
              background-color: $bg-grey-shade-3;
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-medium;
              
              @include site-transition;
              &:hover{
                color: $text-primary;
              }
            }
          }
        }
      }
    }
  }
}

.action-btn{
  display: flex;
  margin-top: 64px;
  .load-more-btn{
    @include site-btn;
    width: 100%;
    text-align: center;
  }
}

//============= post-details End Here ==============//



//===============  Heading Start Here ================//

#heading-faq{
  background-color: $bg-primary;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//===============  Heading End Here ================//



//===============  FAQs Layout 1 Start Here ================//

#faq{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 18px 0;
            text-align: center;
            @include breakpoint($secreen-max-md){
              font-size: 36px;
              line-height: 48px;
            }
            @include breakpoint($secreen-xs){
              font-size: 32px;
              line-height: 45px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            text-align: center;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .collap{
          .heading{
            h3{
              color: $text-neutral;
              margin-bottom: 24px;
            }
          }
          .bs-example{
            .accordion{
              .main{
                margin-bottom: 30px;
                box-shadow: $box-shadow;
                .card{
                  border: none;
                  background-color: $bg-white;
                  padding: 0 32px;
                  font-family: $font-family-poppins-regular;
                  
                  line-height: $x-sm-line-height;
                  color: $text-grey-shade-1;
                  border-radius: 0;
                  .card-header{
                    background-color: transparent;
                    padding: 20px 0;
                    border: none;
                    &:hover{
                      cursor: pointer;
                    .collap-inner .icon .fa-chevron-circle-down{
                        color: $text-primary;
                      }
                      .collap-inner .icon .fa-chevron-circle-up{
                          color: $text-primary;
                        }
                    .collap-inner .text p{
                        color: $text-primary;
                        cursor: pointer;
                      }
                      .collap-inner .icon .fa-chevron-circle-up{
                        color: $text-primary;
                        @include site-transition;
                      }
                    }
                    .collap-inner{
                      display: flex;
                      justify-content: space-between;
                      .icon{
                        .fa-chevron-circle-down{
                          color: $text-grey-shade-1;
                          font-size: 18px;
                          
                          @include site-transition;
                        }
                        .fa-chevron-circle-up{
                          color: $text-grey-shade-1;
                          font-size: 18px;
                          
                          @include site-transition;
                        }
                      }
                      .text{
                        p{
                          font-size: 16px;
                          font-family: $font-family-poppins-medium;
                          
                          color: $text-grey-shade-1;
                          @include site-transition;
                        }
                      }
                    }
                  }
                  .collapse{
                    .card-body1{
                      p{

                      }
                    }
                  }
                  .show{

                  }
                }
              }
              .main-end{
                margin-bottom: 0;
              }
            }
          }
        }
        .collap1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 64px;
          }
        }
      }
    }
  }
}

//===============  FAQs Layout 1 End Here ================//




//===============  FAQs Layout 2 Start Here ================//

#faq1{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 18px 0;
            text-align: center;
            @include breakpoint($secreen-max-md){
              font-size: 36px;
              line-height: 48px;
            }
            @include breakpoint($secreen-xs){
              font-size: 32px;
              line-height: 45px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            text-align: center;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .collap{
          .heading{
            h3{
              color: $text-neutral;
              margin-bottom: 24px;
            }
          }
          .bs-example{
            .accordion{
              .main{
                margin-bottom: 30px;
                box-shadow: $box-shadow;
                .card{
                  border: none;
                  background-color: $bg-white;
                  @include site-transition;
                  font-family: $font-family-poppins-regular;
                  
                  line-height: $x-sm-line-height;
                  color: $text-grey-shade-1;
                  border-radius: 0;
                  .header{
                    &:hover{
                      background-color: $bg-primary;
                      cursor: pointer;
                    }
                    .card-header{
                      background-color: transparent;
                      padding: 20px 32px;
                      border: none;
                      @include site-transition;
                      &:hover{
                      .collap-inner .icon .fa-chevron-circle-down{
                          color: $text-white;
                        }
                      .collap-inner .text p{
                          color: $text-white;
                          cursor: pointer;
                        }
                        .collap-inner .icon .fa-minus{
                          color: $text-grey-shade-1;
                          @include site-transition;
                        }
                      }
                      .collap-inner{
                        display: flex;
                        justify-content: space-between;
                        .icon{
                          .fa-chevron-circle-down{
                            color: $text-grey-shade-1;
                            font-size: 18px;
                            
                            @include site-transition;
                          }
                          .fa-chevron-circle-up{
                            color: $text-grey-shade-1;
                            font-size: 18px;
                            
                            @include site-transition;
                          }
                        }
                        .text{
                          p{
                            font-size: 16px;
                            font-family: $font-family-poppins-medium;
                            
                            color: $text-grey-shade-1;
                            @include site-transition;
                          }
                        }
                      }
                      .collapse{
                        border-top: 1px solid $border-grey-shade-1;
                        padding: 0 2rem;
                        .card-body{
                          p{

                          }
                        }
                      }
                    }
                  }
                }
              }
              .main-end{
                margin-bottom: 0;
              }
            }
          }
        }
        .collap2{
          @include breakpoint($secreen-max-md){
            margin-top: 64px;
          }
        }
      }
    }
  }
}

//===============  FAQs Layout 2 End Here ================//




//===============  FAQs Layout 3 Start Here ================//

#faq3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 18px 0;
            text-align: center;
            @include breakpoint($secreen-max-md){
              font-size: 36px;
              line-height: 48px;
            }
            @include breakpoint($secreen-xs){
              font-size: 32px;
              line-height: 45px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            text-align: center;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .collap{
          .heading{
            h3{
              color: $text-neutral;
              margin-bottom: 24px;
            }
          }
          .bs-example{
            .accordion{
              .main{
                box-shadow: $box-shadow;
                .card{
                  border: none;
                  background-color: $bg-white;
                  padding: 0 32px;
                  font-family: $font-family-poppins-regular;
                  
                  line-height: $x-sm-line-height;
                  color: $text-grey-shade-1;
                  border-radius: 0;
                  .card-header{
                    background-color: transparent;
                    padding: 20px 0;
                    border: none;
                    border-bottom: 1px solid $border-remy;
                    &:hover{
                      cursor: pointer;
                    .collap-inner .icon .fa-chevron-circle-down{
                        color: $text-primary;
                        @include site-transition;
                      }
                    .collap-inner .text p{
                        color: $text-primary;
                        cursor: pointer;
                      }
                      .collap-inner .icon .fa-chevron-circle-up{
                        color: $text-primary;
                        @include site-transition;
                      }
                    }
                    .collap-inner{
                      display: flex;
                      justify-content: space-between;
                      .icon{
                        .fal{
                          
                        }
                        .fa-chevron-circle-down{
                          color: $text-grey-shade-1;
                          
                          font-size: 18px;
                          @include site-transition;
                        }
                        .fa-chevron-circle-up{
                          font-size: 18px;
                          color: $text-grey-shade-1;
                          @include site-transition;
                        }
                      }
                      .text{
                        p{
                          font-size: 16px;
                          font-family: $font-family-poppins-medium;
                          
                          color: $text-grey-shade-1;
                          @include site-transition;
                        }
                      }
                    }
                    .collapse{
                      border-top: 1px solid $border-grey-shade-1;
                      .card-body{
                        p{

                        }
                      }
                    }
                  }
                }
              }
              .main-end{
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

//===============  FAQs Layout 3 End Here ================//
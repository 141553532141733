// Main Colors
$neutral: #17161A;
$primary: #033fff;
$grey-shade-1: #7f7f7f;
$grey-shade-2: #d3d3d6;
$grey-shade-3: #F0EDF6;
$white: #ffffff;


// Features, Services, and Icons Colors
$blue: #033fff;
$twitter: #00ACEE;
$lavender-pink: #FBB6CE;
$blue-romance: #C6F6D5;
$carrot-orange: #1d1919;
$peach: #ffe6b2;
$pink-lily: #f6d2e6;
$sonw: #fff6fb;
$light-blue: #0066ff;
$remy: #FEE2F1;
$blue-chalk: #E9D8FD;
$oasis: #FEEBC8;
$icecold: #B2F5EA;
$cosmos: #FED7D7;
$woodsmoke: #17161A;
$mineshaft: #373737;
$cinnabar: #EA4335;
$chambray: #3B5998;
$instagram: #3F729B;
$dribble: #EA4C89;
$snapchat: #FFFC00;


// Background Colors

$bg-neutral: $neutral;
$bg-primary: $primary;
$bg-grey-shade-1: $grey-shade-1;
$bg-grey-shade-2: $grey-shade-2;
$bg-grey-shade-3: $grey-shade-3;
$bg-twitter: $twitter;
$bg-blue-romance: $blue-romance;
$bg-lavender-pink: $lavender-pink;
$bg-snapchat: $snapchat;
$bg-dribble: $dribble;
$bg-instagram: $instagram;
$bg-white: $white;
$bg-chambray: $chambray;
$bg-cinnabar: $cinnabar;
$bg-blue-chalk: $blue-chalk;
$bg-oasis: $oasis;
$bg-icecold: $icecold;
$bg-cosmos: $cosmos;
$bg-mineshaft: $mineshaft;
$bg-carrot-orange: $carrot-orange;
$bg-woodsmoke: $woodsmoke;
$bg-peach: $peach;
$bg-pink-lily: $pink-lily;
$bg-sonw: $sonw;
$bg-remy: $remy;
// Text Colors
$text-neutral: $neutral;
$text-primary: $primary;
$text-grey-shade-1: $grey-shade-1;
$text-grey-shade-2: $grey-shade-2;
$text-grey-shade-3: $grey-shade-3;
$text-white: $white;
$text-lavender-pink: $lavender-pink;
$text-chambray: $chambray;
$text-cinnabar: $cinnabar;
$text-blue-chalk: $blue-chalk;
$text-oasis: $oasis;
$text-icecold: $icecold;
$text-blue-romance: $blue-romance;
$text-cosmos: $cosmos;
$text-white: $white;
$text-carrot-orange: $carrot-orange;
$text-peach: $peach;
$text-pink-lily: $pink-lily;
$text-sonw: $sonw;
$text-remy: $remy;
// Border Colors
$border-neutral: $neutral;
$border-primary: $primary;
$border-grey-shade-1: $grey-shade-1;
$border-grey-shade-2: $grey-shade-2;
$border-grey-shade-3: $grey-shade-3;
$border-cinnabar: $cinnabar;
$border-lavender-pink: $lavender-pink;
$border-blue-chalk: $blue-chalk;
$border-oasis: $oasis;
$border-chambray: $chambray;
$border-icecold: $icecold;
$border-cosmos: $cosmos;
$border-white: $white;
$border-blue-romance: $blue-romance;
$border-carrot-orange: $carrot-orange;
$border-peach: $peach;
$border-pink-lily: $pink-lily;
$border-sonw: $sonw;
$border-remy: $remy;
// box shadow
$box-shadow: (0px 17px 45px rgba(0, 0, 0, 0.080));
// Font Size
$xl-font-size: 60px;
$lg-font-size: 40px;
$md-font-size: 24px;
$sm-font-size: 20px;
// line-Height
$xl-line-height: 80px;
$lg-line-height: 58px;
$md-line-height: 36px;
$sm-line-height: 32px;
$x-sm-line-height: 24px;

/*================================
     Theme Fonts Family
================================*/


//Theme Fonts Variable
$font-family-poppins-semibold: 'Poppins-SemiBold';
$font-family-poppins-medium: 'Poppins-Medium';
$font-family-poppins-regular: 'Poppins-Regular';

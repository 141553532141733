

//============= Heading Start Here ==============//

#heading-portfolio-details{
  background-color: $bg-primary;
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//



//============= Portfolio Details Start Here ==============//

#profolio-details{
  margin: 128px 0;
  @include breakpoint($secreen-max-md){
    margin: 64px 0;
  }
  .container{
    .wrapper{
      text-align: center;
      h2{
        color: $text-neutral;
        @include breakpoint($secreen-max-md){
          font-size: 32px;
          line-height: 44px;
        }
        @include breakpoint($secreen-xs){
          font-size: 28px;
          line-height: 36px;
        }
      }
      p{
        font-family: $font-family-poppins-regular;
        color: $text-grey-shade-1;
        margin-top: 18px;
        font-size: 18px;
      }
    }
    .row{
      margin-top: 64px;
      .images{
        @include breakpoint($secreen-max-md){
          margin-bottom: 64px;
        }
        .img{
          .img-fluid{
            width: 100%;
          }
        }
        .img1,
        .img2{
          margin-bottom: 30px;
        }
      }
      .content{
        .content-box{
          margin-bottom: 30px;
          padding: 36px;
          background-color: $bg-white;
          box-shadow: $box-shadow;
          h3{
            color: $text-neutral;
          }
          p{
            font-family: $font-family-poppins-regular;
            line-height: 30px;
            color: $text-grey-shade-1;
            margin-top: 12px;
          }
        }
        .action{
          display: flex;
          .live-preview{
            @include site-btn;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

//============= Portfolio Details End Here ==============//

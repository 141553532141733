html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    width: 100%;
    height: auto;
    margin: 0;
    box-sizing: border-box;
    @include site-transition;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

a {
    display: block;
    text-decoration: none;
    letter-spacing: 0.3px;
    &:hover {
        text-decoration: none;
    }
}

h1{
  font-size: $xl-font-size;
  line-height: $xl-line-height;
  font-family: $font-family-poppins-semibold;
  font-weight: normal;
  text-transform: capitalize;
  margin: 0px;
  padding: 0px;
}
h2{
  font-size: $lg-font-size;
  line-height: $lg-line-height;
  font-family: $font-family-poppins-semibold;
  text-transform: capitalize;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}
h3{
  font-size: $md-font-size;
  line-height: $md-line-height;
  font-family: $font-family-poppins-semibold;
  text-transform: capitalize;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}
h4{
  font-size: $sm-font-size;
  line-height: $x-sm-line-height;
  font-family: $font-family-poppins-semibold;
  text-transform: capitalize;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}
h5,
h6 {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
}
p {
    margin: 0px;
    padding: 0px;
    letter-spacing: 0.3px;
}
i {
    cursor: pointer;
}
input{
    border-radius: 0;
    letter-spacing: 0.3px;
}

.dropdown-toggle{
    -webkit-appearance: none !important;
}

button {
    -webkit-appearance: none !important;
}

.tag{

}

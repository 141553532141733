
//============= Coming Soon Start Here ==============//

#count-page{
  background-color: $bg-neutral;
  padding: 24px 0;
}

.count{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include breakpoint($secreen-max-md){
    height: auto;
  }
  .container{
    margin: 0 auto;
    .wrapper{
      .heading{
        text-align: center;
        h1{
          color: $text-white;
          text-transform: capitalize;
          font-size: 40px;
          margin-bottom: 18px;
          line-height: 58px;
        }
        p{
          color: $text-white;
          font-family: $font-family-poppins-regular;
          font-size: 18px;
        }
      }
      .row{
        margin: 50px auto 0 auto;
        .col-lg-12{
          .timer{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            flex-wrap: wrap;
            text-align: center;
            padding-top: 15px;
            .timer-wrapper {
              display: flex;
              align-items: center;
              flex-direction: column;
              width: 200px;
              .time {
                font-size: 128px;
                color: $text-primary;
                
                font-family: $font-family-poppins-semibold;
                line-height: 120px;
              }
              .text {
                font-size: 16px;
                font-family: $font-family-poppins-regular;
                color: $text-white;
                text-transform: capitalize;
              }
            }
            .coln{
              font-size: 64px;
              
              position: relative;
              top: -32px;
              color: $text-primary;
              @include breakpoint($secreen-max-md){
                display: none;
              }
            }
          }
          .get-latest{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 50px 0 40px 0;
            .get-latest-heading{
              .latest{
                font-family: $font-family-poppins-semibold;
                font-size: $sm-font-size;
                line-height: $md-line-height;
                color: $text-white;
                text-transform: capitalize;
                margin-bottom: 16px;
              }
            }
            .input{
              display: flex;
              align-items: center;
              @include breakpoint($secreen-xs){
                flex-direction: column;
              }
              .input-inner{
                width: 303px;
                padding: 14px 24px;
                font-family: $font-family-poppins-regular;
                background-color: $bg-white;
                border: none;
                color: $text-grey-shade-1;
                text-transform: capitalize;
                &:focus{
                  outline: none;
                }
                @include breakpoint($secreen-xs){
                  width: 100%;
                }
                @include breakpoint($secreen-xs){
                  margin: 4px 0 16px 0;
                }
              }
              .subscribe-btn{
                @include site-btn;
              }
            }
            .icon{
              display: flex;
              align-items: center;
              margin-top: 24px;
              @include breakpoint($secreen-xs){
                justify-content: center;
              }
              li{
                &:not(:first-child){
                  margin-left: 16px;
                }
                a{
                  .social-icon{
                    color: $text-white;
                    font-size: 24px;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                }
              }
            }
          }
          .copyright{
            .copy{
              font-family: $font-family-poppins-regular;
              color: $text-white;
              margin-top: 8px;
              margin-bottom: 8px;
              text-transform: capitalize;
              text-align: center;
              margin: 8px 0 4px 0;
              span{
                color: $text-primary;
              }
            }
          }
        }
      }
    }
  }
}

//============= Coming Soon End Here ==============//



//===============  Preloader Start Here ================//

.loader{
  width: 100%;
  height: 100vh;
  background-color: $bg-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  .img-loader{
    width: 70px;
    height: 70px;
    background-color: $bg-white;
    animation: burst_spin 4s infinite linear; 
  }
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -50%);
    z-index: 10;
    height: 34px;
  }
}

@keyframes burst_spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

//===============  Preloader End Here ================//



//===============  Popup Start Here ================//

.popup-youtube {
  .youtube-click {
    outline:none;
    width: 50px;
    heignt: 50px;
  }
}

//===============  Popup End Here ================//



//===============  Hero Section Start Here ================//

#slider {
    position: relative;
    margin-bottom: 128px;
    @include breakpoint($secreen-max-md){
      margin-bottom: 64px;
    }
    .container {
        position: relative;
        .row{
          align-items: center;
          .content {
            @include breakpoint($secreen-max-md){
              text-align: center;
              margin-bottom: 64px;
            }
              .main-desc {
                  display: inline-flex;
                  p {
                      @include site-tag;
                  }
              }
              .heading {
                  h2 {
                      font-family: $font-family-poppins-semibold;
                      font-size: $xl-font-size;
                      line-height: $xl-line-height;
                      margin-top: 16px;
                      margin-bottom: 18px;
                      color: $text-neutral;
                      @include breakpoint($secreen-max-lg){
                        font-size: 40px;
                        line-height: 50px;
                      }
                      @include breakpoint($secreen-min-lg){
                        position: relative;
                        left: -2.5px;
                      }
                      @include breakpoint($secreen-max-sm){
                        font-size: 32px;
                        line-height: 48px;
                      }
                  }
              }
              .desc {
                  p {
                      font-family: $font-family-poppins-regular;
                      font-size: 18px;
                      line-height: $sm-line-height;
                      color: $text-grey-shade-1;
                  }
              }
              .buttons{
                display: flex;
                align-items: center;
                margin-top: 32px;
                @include breakpoint($secreen-max-md){
                  justify-content: center;
                }
                @include breakpoint($secreen-xs){
                  flex-direction: column;
                }
                .action{
                  display: flex;
                  .slider-btn{
                    @include site-btn;
                  }
                }
                .play{
                  margin-left: 16px;
                  display: flex;
                  align-items: center;
                  @include breakpoint($secreen-xs){
                    margin-top: 36px;
                  }
                  @include breakpoint($secreen-xs){
                    margin-left: 0;
                  }
                  span{
                    width: 51px;
                    height: 51px;
                    border-radius: 50%;
                    margin-right: 16px;
                    color: $text-white;
                    background-color: $bg-carrot-orange;
                    text-align: center;
                    line-height: 51px;
                    position: relative;
                    z-index: 1;
                    &:hover{
                      cursor: pointer;
                    }
                    &::after{
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      border-radius: 50%;
                      border: 1px solid $border-carrot-orange;
                      @include button-transition-home31;
                      animation: home3pulse1 1.5s linear infinite;
                    }
                    &:before{
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      border-radius: 50%;
                      border: 1px solid $border-carrot-orange;
                      @include button-transition-home31;
                      animation: home3pulse1 1.5s linear .8s infinite;
                    }
                    .play-btn{
                      font-size: 18px;
                      position: relative;
                      left: 1px;
                    }
                  }
                  p{
                    font-family: $font-family-poppins-medium;
                    color: $text-neutral;
                    letter-spacing: 0.3px;
                    text-transform: capitalize;
                  }
                }
              }
          }
          .slider-image-outer{
            .image {
                display: flex;
                justify-content: flex-end;
                position: relative;
                @include breakpoint($secreen-max-md){
                  display: flex;
                  justify-content: center;
                }
                .slider-image {
                    width: 475px;
                    height: auto;
                    box-shadow: $box-shadow;
                    @include breakpoint($secreen-max-sm){
                      width: 100% !important;
                      height: 100% !important;
                    }
                }
                .circle {
                    .circle-svg {
                        position: absolute;
                        top: 150px;
                        right: -25px;
                        z-index: -1;
                        @include circle-svg1;
                        animation: circle1 8s linear infinite;
                        @include breakpoint($secreen-max-lg){
                          display: none;
                        }
                    }
                }
                .dots {
                    .dots-svg {
                        position: absolute;
                        bottom: 32px;
                        left: -20px;
                        z-index: 1;
                        @include dots-svg;
                        animation: dots 3s linear infinite;
                        @include breakpoint($secreen-max-lg){
                          display: none;
                        }
                    }
                }
            }
          }
        }
        .triangle {
            .triangle-svg {
                position: absolute;
                bottom: -70px;
                left: 140px;
                @include breakpoint($secreen-max-lg){
                  display: none;
                }
            }
        }
        .circle{
          position: absolute;
          top: -20px;
          left: 350px;
          z-index: -1;
          @include breakpoint($secreen-max-md){
            display: none;
          }
        }
    }
}

//===============  Hero Section End Here ================//




//===============  Services Start Here ================//

#services{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      width: auto;
      .heading{
        text-align: center;
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            font-family: $font-family-poppins-semibold;
            font-size: $lg-font-size;
            line-height: $lg-line-height;
            color: $text-neutral;
            margin: 18px 0;
            text-transform: capitalize;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            font-size: 18px;
            line-height: 28px;
            color: $text-grey-shade-1;
            line-height: $sm-line-height;
          }
        }
      }
      .row{
        justify-content: center;
        margin-top: 64px;
        .services-box{
          .services-box-inner{
            padding: 36px;
            background-color: $bg-white;
            box-shadow: $box-shadow;
            @include breakpoint($secreen-xs){
              padding: 40px 24px;
            }
            .services-info{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .services-name{
                h3{
                  color: $text-neutral;
                  padding: 0px 25px 0 0;
                }
              }
              .services{
                .services-icon{
                  color: $text-primary;
                }
              }
            }
            .services-line{
              width: 64px;
              height: 2px;
              background-color: $bg-primary;
              margin: 20px 0;
            }
            .desc{
              p{
                font-family: $font-family-poppins-regular;
                color: $text-grey-shade-1;
                line-height: 30px;
              }
            }
            .action{
              display: flex;
              margin-top: 24px;
              .services-btn{
                @include site-btn;
                background-color: transparent;
                border: 1px solid $border-primary;
                color: $blue;
                padding: 12px 24px;
                &:hover{
                  background-color: $bg-primary;
                  color: $text-white;
                }
              }
            }
          }
        }
        .box1,
        .box2{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box-services1,
        .box-services2,
        .box-services3{
          margin-bottom: 30px;
        }
        .box-services4,
        .box-services5{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//===============  Services End Here ================//





//===============  Features Start Here ================//

#features{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    position: relative;
    .row{
      align-items: center;
      @include breakpoint($secreen-max-md){
        display: flex;
        flex-direction: column-reverse;
      }
      .features-image-outer{
        .image{
          overflow: hidden;
          @include breakpoint($secreen-max-md){
            display: flex;
            justify-content: center;
          }
          .features-image{
            width: 475px;
            height: auto;
            box-shadow: $box-shadow;
            @include breakpoint($secreen-max-sm){
              width: 100% !important;
              height: 100% !important;
            }
          }
          .features-circle{
            position: absolute;
            bottom: 115px;
            left: -48px;
            z-index: -1;
            @include circle-svg-features-1;
            animation: features1 7s linear infinite;
            @include breakpoint($secreen-max-md){
              display: none;
            }
          }
          .features-circle-4{
            position: absolute;
            top: 180px;
            right: 46px;
            z-index: -1;
            @include circle-svg1;
            animation: circle1 12s linear infinite;
            @include breakpoint($secreen-max-md){
              display: none;
            }
          }
        }
      }
      .content{
        @include breakpoint($secreen-max-md){
          text-align: center;
          padding: 0 30px;
          margin-bottom: 80px;
        }
        position: relative;
        .features-tag-outer{
          display: flex;
          @include breakpoint($secreen-max-md){
            justify-content: center;
          }
          .features-tag{
            @include site-tag;
          }
        }
        .features-heading{
          h2{
            font-family: $font-family-poppins-semibold;
            font-size: $lg-font-size;
            line-height: $lg-line-height;
            color: $text-neutral;
            margin: 18px 0;
            text-transform: capitalize;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .features-desc{
          p{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
            line-height: $sm-line-height;
          }
        }
        .check-outer{
          margin-top: 36px;
          .check{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            @include breakpoint($secreen-max-md){
              justify-content: center;
            }
            .bullet{
              width: 36px;
              height: 27px;
              @include breakpoint($secreen-xs){
                width: 60px;
              }
            }
            p{
              font-family: $font-family-poppins-regular;
              
              color: $text-neutral;
              line-height: $sm-line-height;
              margin-left: 12px;
              @include breakpoint($secreen-xs){
                text-align: left;
              }
            }
          }
        }
      }
    }
    .features-triangle{
      position: absolute;
      top: 30px;
      right: 176px;
      transform: rotate(90deg);
      z-index: -1;
      @include breakpoint($secreen-max-sm){
        display: none;
      }
    }
  }
}

//===============  Features End Here ================//




//===============  Clients Start Here ================//

#clients-home1{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    position: relative;
    .wrapper{
      .heading{
        text-align: center;
        .clients-tag-outer{
          display: flex;
          justify-content: center;
          .clients-tag{
            @include site-tag;
          }
        }
        .clients-heading-outer{
          .clients-heading{
            font-family: $font-family-poppins-semibold;
            font-size: $lg-font-size;
            line-height: $lg-line-height;
            color: $text-neutral;
            margin: 18px 0;
            text-transform: capitalize;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .clients-desc-outer{
          .clients-desc{
            font-family: $font-family-poppins-regular;
            font-size: 18px;
            line-height: $sm-line-height;
            color: $text-grey-shade-1;
          }
        }
      }
      .row{
        margin-top: 64px;
        padding: 0 48px;
        justify-content: center;
        align-items: center;
        @include breakpoint($secreen-max-md){
          grid-gap: 64px 0;
        }
        .clients-box-outer{
          .clients-box{
            margin: 0 auto;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
              .clients-image{
                opacity: 1;
                transition: .3s ease-in-out;
              }
            }
            .clients-image{
              transition: .3s ease-in-out;
              opacity: 0.3;
            }
          }
        }
        .box1,
        .box2,
        .box3,
        .box4{
          margin-bottom: 64px;
        }
        .box5,
        .box6{
          @include breakpoint($secreen-max-md){
            margin-bottom: 64px;
          }
        }
        .box7{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 64px;
          }
        }
      }
    }
    .circle-shape{
      position: absolute;
      right: 110px;
      top: 100px;
      z-index: -1;
      @include breakpoint($secreen-max-sm){
        display: none;
      }
    }
  }
}

//===============  Clients End Here ================//




//===============  Testimonials Start Here ================//

#testimonials{
  position: relative;
  .overlay{
    position: relative;
    padding: 128px 0;
    background-image: url('/../assets/img/testimonials-backgroung.jpg');
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    &:before{
      content: "";
      position: absolute;
      background-color: $bg-neutral;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .8;
      z-index: 1;
    }
    .container{
      .wrapper{
        position: relative;
        z-index: 1;
        .heading{
          text-align: center;
          .testimonials-tag-outer{
            display: flex;
            justify-content: center;
            .testimonials-tag{
              @include site-tag;
            }
          }
          .testimonials-heading-outer{
            .testimonials-heading{
              font-family: $font-family-poppins-semibold;
              font-size: $lg-font-size;
              line-height: $lg-line-height;
              color: $text-white;
              margin: 18px 0;
              text-transform: capitalize;
              @include breakpoint($secreen-max-md){
                font-size: 32px;
                line-height: 44px;
              }
              @include breakpoint($secreen-xs){
                font-size: 28px;
                line-height: 36px;
              }
            }
          }
          .testimonials-desc-outer{
            .testimonials-desc{
              font-family: $font-family-poppins-regular;
              font-size: 18px;
              line-height: $sm-line-height;
              color: $text-white;
            }
          }
        }
        .inner-wrapper1{
          .your-class{
            &:focus{
              outline: none;
            }
            .sllide{
              position: relative;
              padding: 36px;
              background-color: $bg-white;
              margin-top: 64px;
              @include breakpoint($secreen-xs){
                padding: 40px 15px;
              }
              &:focus{
                outline: none;
              }
              .desktop{
                position: relative;
                @include breakpoint($secreen-xs){
                  padding: 40px 15px;
                  display: none;
                }
                .quote{
                  .testimonials-icon{
                    color: $text-primary;
                  }
                }
                .profile{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .desc{
                    p{
                      color: $text-grey-shade-1;
                      font-family: $font-family-poppins-regular;
                      line-height: 30px;
                      margin: 16px 0;
                    }
                  }
                  .image{
                    .image-testimonials{
                      margin-left: 40px;
                    }
                  }
                }
                .about-person{
                  h1{
                    color: $text-neutral;
                    font-size: $md-font-size;
                    line-height: $md-line-height;
                    font-family: $font-family-poppins-semibold;
                  }
                  p{
                    color: $text-grey-shade-1;
                    font-family: $font-family-poppins-regular;
                    margin-top: 6px;
                  }
                }
                .squre{
                  .dots-svg{
                    position: absolute;
                    bottom: -70px;
                    right: -70px;
                    @include breakpoint($secreen-xs){
                      display: none;
                    }
                  }
                }
              }
              .mobile{
                display: none;
                @include breakpoint($secreen-xs){
                  display: block !important;
                }
                .profile-mob{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  .img-mob{
                    img{}
                  }
                  .desc-mob{
                    p{
                      color: $text-grey-shade-1;
                      font-family: $font-family-poppins-regular;
                      margin: 18px 0;
                      text-align: center;
                    }
                  }
                }
                .content-mob{
                  text-align: center;
                  h1{
                    color: $text-neutral;
                    font-size: $md-font-size;
                    line-height: $md-line-height;
                    font-family: $font-family-poppins-semibold;
                    
                  }
                  p{
                    color: $text-grey-shade-1;
                    font-family: $font-family-poppins-regular;
                    
                    text-transform: capitalize;
                  }
                }
              }
            }
          }
        }
      }
    }
   }
 }

 //===============  Testimonials End Here ================//




//===============  Counter Start Here ================//

#counter{
  position: relative;
  margin-bottom: 128px;
  background-color: $bg-primary;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 64px 48px;
      @include breakpoint($secreen-max-md){
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 64px 0;
      }
      @include breakpoint($secreen-xs){
        display: flex;
        flex-direction: column;
      }
      .counter1{
        @include breakpoint($secreen-xs){
          margin-bottom: 32px;
        }
        @include breakpoint($secreen-max-md){
          margin: 0 auto;
        }
        .counter-inner1{
          display: flex;
          align-items: center;
          .counter-heading{
            font-family: $font-family-poppins-semibold;
            
            color: $text-white;
            line-height: $lg-line-height;
            font-size: $xl-font-size;
          }
          .counter-icon{
            color: $text-white;
            position: relative;
            left: 12px;
            bottom: 2px;
            @include breakpoint($secreen-thirteen-max){
              bottom: 0px !important;
            }
          }
        }
        .desc{
          p{
            color: $text-white;
            font-family: $font-family-poppins-regular;
            
            margin-top: 8px;
            @include breakpoint($secreen-max-sm){
              text-align: center;
            }
          }
        }
      }
      .count1,
      .count2,
      .count3{
        @include breakpoint($secreen-xs){
          margin-bottom: 30px;
        }
      }
    }
  }
}

//===============  Counter End Here ================//




//===============  Portfolio Start Here ================//

#portfolio{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    position: relative;
    .wrapper{
      .heading{
        text-align: center;
        .portfolio-tag-outer{
          display: flex;
          justify-content: center;
          .portfolio-tag{
            @include site-tag;
          }
        }
        .portfolio-heading-outer{
          .portfolio-heading{
            font-family: $font-family-poppins-semibold;
            font-size: $lg-font-size;
            line-height: $lg-line-height;
            color: $text-neutral;
            margin: 18px 0;
            text-transform: capitalize;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .portfolio-desc-outer{
          .portfolio-desc{
            font-family: $font-family-poppins-regular;
            font-size: 18px;
            line-height: $sm-line-height;
            color: $text-grey-shade-1;
          }
        }
      }
      .row{
        margin-top: 64px;
        .portfolio-box-outer{
          .portfolio-box{
            overflow: hidden;
            position: relative;
            &:hover{
              .content .content-inner{
                transform: translateY(-80px);
                transition: .3s ease-in-out;
              }
              .action{
                position: absolute;
                left: 50%;
                transform: translate(-50%, -65px) !important;
                transition: .3s ease-in-out;
              }
            }
            .image{
              .portfolio-image{
                width: 100%;
                height: auto;
                position: relative;
                z-index: 1;
                box-shadow: $box-shadow;
                &:hover{
                  cursor: pointer;
                }
              }
            }
            .content{
              padding: 18px;
              text-align: center;
              .content-inner{
                transition: .3s ease-in-out;
                .heading-nunito{
                  h1{
                    font-family: $font-family-poppins-semibold;
                    
                    color: $text-neutral;
                    font-size: $md-font-size;
                    line-height: $md-line-height;
                    margin-bottom: 6px;
                  }
                }
                .desc{
                  p{
                    font-family: $font-family-poppins-regular;
                    
                    color: $text-grey-shade-1;
                    margin-top: 4px;
                  }
                }
              }
              .action{
                display: flex;
                margin-top: 12px;
                justify-content: center;
                transition: .3s all ease-in-out;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 80px);
                .portfolio-btn{
                  @include site-btn;
                  background-color: transparent;
                  border: 1px solid $border-primary;
                  color: $blue;
                  padding: 12px 24px;
                  &:hover{
                    background-color: $bg-primary;
                    color: $text-white;
                  }
                }
              }
            }
          }
        }
        .box1,
        .box2{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
      }
    }
    .circle-shape{
      position: absolute;
      top: 100px;
      left: 70px;
      z-index: -1;
      @include breakpoint($secreen-max-sm){
        display: none;
      }
    }
    .features-triangle{
      position: absolute;
      top: 40px;
      right: 30px;
      transform: rotate(90deg);
      z-index: -1;
      @include breakpoint($secreen-max-sm){
        display: none;
      }
    }
  }
}

//===============  Portfolio End Here ================//



//===============  Pricing Plan Start Here ================//

#pricing{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    position: relative;
    .wrapper{
      .heading{
        text-align: center;
        .pricing-tag-outer{
          display: flex;
          justify-content: center;
          .pricing-tag{
            @include site-tag;
          }
        }
        .pricing-heading-outer{
          .pricing-heading{
            font-family: $font-family-poppins-semibold;
            
            font-size: $lg-font-size;
            line-height: $lg-line-height;
            color: $text-neutral;
            margin: 18px 0;
            text-transform: capitalize;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .pricing-desc-outer{
          .pricing-desc{
            font-family: $font-family-poppins-regular;
            font-size: 18px;
            line-height: $sm-line-height;
            color: $text-grey-shade-1;
          }
        }
      }
      .row{
        margin-top: 64px;
        .pricing-box-outer{
          .pricing-box{
            padding: 36px;
            position: relative;
            background-color: $bg-white;
            overflow: hidden;
            box-shadow: $box-shadow;
            @include site-transition;
            &::after{
              content: "";
              position: absolute;
              width: 217px;
              height: 217px;
              background-color: $bg-grey-shade-3;
              border-radius: 50%;
              top: -107px;
              right: -107px;
              z-index: 1;
            }
            .pricing-info{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .pricing-name{
                h1{
                  font-family: $font-family-poppins-semibold;
                  color: $text-neutral;
                  line-height: $md-line-height;
                  font-size: $md-font-size;
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  margin-top: 8px;
                  @include breakpoint($secreen-max-lg){
                    font-size: 14px;
                  }
                }
              }
              .pricing-icon-outer{
                .pricing-icon{
                  position: relative;
                  z-index: 10;
                  font-size: 56px;
                  color: $text-primary;
                }
              }
           }
           .price{
             display: flex;
             align-items: center;
             margin: 12px 0;
             h2{
               font-family: $font-family-poppins-semibold;
               
               color: $text-neutral;
               font-size: $xl-font-size;
               line-height: $xl-line-height;
             }
             .span1{
               font-family: $font-family-poppins-regular;
               
               font-size: 16px;
               color: $text-grey-shade-1;
               margin-left: 5px;
             }
             .span2{
               font-family: $font-family-poppins-regular;
               
               font-size: 16px;
               color: $text-grey-shade-1;
               margin-left: 5px;
             }
           }
           .pricing-get{
             .get{
               display: flex;
               align-items: center;
                margin-bottom: 18px;
               .dot-circle{
                 margin-right: 8px;
                 font-size: 18px;
                 color: $text-primary;
               }
               p{
                 font-family: $font-family-poppins-regular;
                 font-size: 16px;
                 color: $text-grey-shade-1;
               }
             }
             .no-get{
               display: flex;
               align-items: center;
               margin-bottom: 16px;
               .dot-circle{
                 margin-right: 8px;
                 color: $text-grey-shade-2;
                 font-size: 18px;
               }
               p{
                 font-family: $font-family-poppins-regular;
                 
                 font-size: 16px;
                 color: $text-grey-shade-2;
               }
             }
           }
           .action{
             display: flex;
             margin-top: 32px;
             .pricing-btn{
               @include site-btn;
               background-color: transparent;
               border: 1px solid $border-primary;
               color: $blue;
               padding: 12px 24px;
               width: 100%;
               text-align: center;
               &:hover{
                 background-color: $bg-primary;
                 color: $text-white;
               }
             }
           }
        }
       }
       .box1,
       .box2{
         margin-bottom: 30px;
       }
     }
   }
   .circle{
     position: absolute;
     right: 15px;
     top: 30px;
     @include breakpoint($secreen-max-sm){
      display: none;
    }
   }
 }
 .dots{
   position: absolute;
   left: -105px;
   top: 70px;
   @include breakpoint($secreen-max-sm){
    display: none;
  }
 }
}

//===============  Pricing Plan End Here ================//


//===============  Blog Start Here ================//

#news-blog{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    position: relative;
    .wrapper{
      .heading{
        text-align: center;
        .news-blog-tag-outer{
          display: flex;
          justify-content: center;
          .news-blog-tag{
            @include site-tag;
          }
        }
        .news-blog-heading-outer{
          .news-blog-heading{
            font-family: $font-family-poppins-semibold;
            
            font-size: $lg-font-size;
            line-height: $lg-line-height;
            color: $text-neutral;
            margin: 18px 0;
            text-transform: capitalize;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .news-blog-desc-outer{
          .news-blog-desc{
            font-family: $font-family-poppins-regular;
            font-size: 18px;
            line-height: $sm-line-height;
            color: $text-grey-shade-1;
          }
        }
      }
      .row{
        margin-top: 64px;
        .news-blog-box-outer{
          .news-blog-box{
            position: relative;
            overflow: hidden;
            box-shadow: $box-shadow;
            background-color: $bg-white;
            &:hover{
            }
            .image{
              .news-blog-image{
                width: 100%;
              }
            }
            .content{
              padding: 36px;
              .info-admin{
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                a{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  margin-right: 4px;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                }
              }
              .news-blog-heading{
                .blog-link{
                  font-family: $font-family-poppins-semibold;
                  color: $text-neutral;
                  line-height: $md-line-height;
                  font-size: $md-font-size;
                  transition: .5s all ease-in-out;
                  &:hover{
                    color: $text-primary;
                    transition: .5s all ease-in-out;
                  }
                  @include breakpoint($secreen-max-md){
                    font-size: 22px;
                  }
                }
              }
              .news-blog-btn{
                display: inline-flex;
                align-items: center;
                margin-top: 16px;
                position: relative;
                overflow: hidden;
                width: 125px;
                &:hover{
                  .arrow{
                    animation: news .4s;
                    @include news-blog;
                    font-size: 21px;
                    color: $text-primary;
                  }
                }
                p{
                  text-transform: capitalize;
                  color: $text-primary;
                  font-family: $font-family-poppins-medium;
                  
                  margin-right: 8px;
                }
                .arrow{
                  font-size: 21px;
                  color: $text-primary;
                }
              }
            }
          }
        }
        .box1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box2{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
    .triangle{
      position: absolute;
      left: 12px;
      top: 70px;
      transform: rotate(90deg);
      @include breakpoint($secreen-max-sm){
        display: none;
      }
    }
  }
}

//===============  Blog End Here ================//


//===============  Let's Started Start Here ================//

#started-home1{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .overlay{
    position: relative;
    background-image: url('/../assets/img/testimonials-backgroung.jpg');
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    &:before{
      content: "";
      position: absolute;
      background-color: $bg-neutral;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .8;
      z-index: 1;
    }
    .container{
      .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 64px 0;
        position: relative;
        z-index: 1;
        @include breakpoint($secreen-max-md){
          flex-direction: column;
        }
        .started-heading{
          @include breakpoint($secreen-max-sm){
            text-align: center;
          }
          h2{
            color: $text-white;
            margin-bottom: 18px;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
              text-align: center;
            }
          }
          p{
            color: $text-white;
            font-family: $font-family-poppins-regular;
            font-size: 18px;
          }
        }
        .action{
          display: flex;
          @include breakpoint($secreen-max-md){
            margin-top: 32px;
          }
          .started-btn{
            @include site-btn;
          }
        }
      }
    }
  }
}

//===============  Let's Started End Here ================//



//============  Top Button Strat Here ============//

#topBtn{
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 50px;
  height: 50px;
  color: $text-neutral;
  border: none;
  background-color: $bg-white;
  box-shadow: 0px 17px 45px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: none;
  z-index: 5;
  border-radius: 50%;
  @include site-transition;
  &:focus{
    outline: none;
  }
  &:hover{
    background-color: $bg-primary;
    .fa-arrow-up{
      color: $text-white;
    }
  }
  @include breakpoint($secreen-max-sm){
    right: 15px;
    bottom: 15px;
  }
  .fa-arrow-up{
    color: $text-primary;
    font-size: 20px;
    position: relative;
    top: 1px;
    @include site-transition;
  }
}

//============  Top Button End Here ============//



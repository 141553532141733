

//============= Heading Start Here ==============//

#heading-post-details-sidebar{
  background-color: $bg-primary;
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//




//============= post-details Start Here ==============//

#post-details-sidebar{
  margin: 128px 0;
  @include breakpoint($secreen-max-md){
    margin: 64px 0;
  }
  .container{
    .row{
      .details-page{
        padding-left: 0;
        @include breakpoint($secreen-max-md){
          margin-bottom: 64px;
        }
        @include breakpoint($secreen-max-md){
          padding-left: 15px;
        }
        .img{
          width: 100%;
          margin-bottom: 40px;
          .img-fluid{
            width: 100%;
            box-shadow: $box-shadow;
          }
        }
        .ui-ux{
          p{
            color: $text-grey-shade-1;
            
            font-family: $font-family-poppins-regular;
          }
        }
        .heading{
          .link-h2{
            color: $text-neutral;
            margin: 4px 0 8px 0;
            font-size: $lg-font-size;
            font-family: $font-family-poppins-semibold;
            
            text-transform: capitalize;
            @include site-transition;
            &:hover{
              color: $text-primary;
            }
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
          p{
            color: $text-grey-shade-1;
            font-family: $font-family-poppins-regular;
            .admin{
              display: inline-flex;
              color: $text-grey-shade-1;
              @include site-transition;
              &:hover{
                color: $text-primary;
              }
            }
          }
        }
        .description{
          margin: 40px 0;
          p{
            color: $text-grey-shade-1;
            font-family: $font-family-poppins-regular;
            line-height: $sm-line-height;
            .para-link{
              color: $text-primary;
              text-transform: capitalize;
              display: inline-flex;
            }
          }
          .background{
            background-color: $bg-white;
            padding: 24px;
            margin: 40px 0;
            box-shadow: $box-shadow;
          }
        }
        .tags{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px 0;
          border-top: 1px solid $border-grey-shade-3;
          border-bottom: 1px solid $border-grey-shade-3;
          @include breakpoint($secreen-max-sm){
            flex-direction: column;
          }
          .tags-inner{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @include breakpoint($secreen-max-sm){
              margin-bottom: 32px;

              justify-content: center;
            }
            li{
              text-transform: capitalize;
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-regular;
              &:not(:last-child){
                margin-right: 12px;
              }
              @include breakpoint($secreen-max-sm){
                margin-bottom: 16px;
              }
              a{
                background-color: $bg-grey-shade-3;
                color: $text-grey-shade-1;
                padding: 12px 16px;
                text-transform: capitalize;
                @include site-transition;
                &:hover{
                  color: $text-primary;
                }
              }
            }
          }
          .icons{
            display: flex;
            align-items: center;
            flex-direction: row;
            li{
              text-transform: capitalize;
              color: $text-grey-shade-1;
              font-family: $font-family-poppins-medium;
              
              &:not(:last-child){
                margin-right: 12px;
              }
              a{
                .social-icon{
                  color: $text-grey-shade-1;
                  font-size: 24px;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                }
              }
            }
          }
        }
        .inner-heading{
          margin-top: 40px;
          h3{
            color: $text-neutral;
          }
          p{
            margin-top: 8px;
            text-transform: capitalize;
            color: $text-grey-shade-1;
            font-family: $font-family-poppins-medium;
            
          }
        }
        .row-inner{
          margin: 40px 0;
          .news-blog-box-outer{
            @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
            }
            .news-blog-box{
              position: relative;
              overflow: hidden;
              box-shadow: $box-shadow;
              background-color: $bg-white;
              @include site-transition;
              .image{
                overflow: hidden;
                .news-blog-image{
                  width: 100%;
                  @include site-transition;
                  overflow: hidden;
                }
              }
              .content{
                padding: 36px;
                .info-admin{
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  a{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                    margin-right: 4px;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                  p{
                    font-family: $font-family-poppins-regular;
                    color: $text-grey-shade-1;
                  }
                }
                .news-blog-heading{
                  .blog-link{
                    font-family: $font-family-poppins-semibold;
                    
                    color: $text-neutral;
                    line-height: $md-line-height;
                    font-size: $md-font-size;
                    transition: .5s all ease-in-out;
                    &:hover{
                      color: $text-primary;
                      transition: .5s all ease-in-out;
                    }
                    @include breakpoint($secreen-max-md){
                      font-size: 22px;
                    }
                  }
                }
                .news-blog-btn{
                  display: inline-flex;
                  align-items: center;
                  margin-top: 16px;
                  position: relative;
                  overflow: hidden;
                  width: 125px;
                  &:hover{
                    .arrow{
                      animation: news .4s;
                      @include news-blog;
                    }
                  }
                  p{
                    text-transform: capitalize;
                    color: $text-primary;
                    font-family: $font-family-poppins-medium;
                    
                    margin-right: 8px;
                  }
                  .arrow{
                    font-size: 21px;
                    color: $text-primary;
                  }
                }
              }
            }
          }
          .box1{
            padding-left: 0;
            @include breakpoint($secreen-max-md){
              padding: 0;
            }
          }
          .box2{
            padding-right: 0;
            @include breakpoint($secreen-max-md){
              padding: 0;
            }
          }
        }
        .comments{
          h3{
            color: $text-neutral;
            margin-bottom: 32px;
          }
          .person-comments{
            li{
              &:not(:first-child){
                margin-top: 32px;
              }
              .person-name{
                display: flex;
                align-items: center;
                .img-comment{
                  width: 50px;
                  margin-right: 12px;
                }
                .name{
                  span{
                    text-transform: capitalize;
                    color: $text-neutral;
                    font-family: $font-family-poppins-regular;
                  }
                  p{
                    text-transform: capitalize;
                    color: $text-grey-shade-1;
                    font-family: $font-family-poppins-regular;
                    margin-right: 4px;
                    margin-top: 0;
                  }
                }
              }
              p{
                text-transform: capitalize;
                color: $text-grey-shade-1;
                font-family: $font-family-poppins-regular;
                line-height: 30px;
                margin-top: 16px;
              }
              .action{
                display: flex;
                margin-top: 20px;
                .reply-btn{
                  padding: 12px 16px;
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-medium;
                  
                  background-color: $bg-grey-shade-3;
                  text-transform: capitalize;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                }
              }
            }
            .third{
              margin-left: 110px;
              @include breakpoint($secreen-max-md){
                margin-left: 0;
              }
            }
          }
        }
        .post-comment{
          margin-top: 40px;
          .heading-post-comment{
            .post-heading{
              color: $text-neutral;
              margin-bottom: 32px;
            }
          }
          .form{
            .input-row1{
              display: flex;
              justify-content: space-between;
              @include breakpoint($secreen-xs){
                flex-direction: column;
              }
              input{
                background-color: $bg-white;
                padding: 14px 24px;
                box-shadow: $box-shadow;
                border: none;
                width: 100%;
                color: $text-grey-shade-1;
                text-transform: capitalize;
                font-family: $font-family-poppins-regular;
                
                @include site-transition;
                &:focus{
                  outline: none;
                  background-color: $bg-grey-shade-3;
                }
              }
              .input-name{
                margin-right: 40px;
                box-shadow: $box-shadow;
                @include breakpoint($secreen-xs){
                  margin-bottom: 20px;
                }
              }
              .input-email{
                box-shadow: $box-shadow;
              }
            }
            .input{
              margin: 20px 0;
              .input-web{
                background-color: $bg-white;
                padding: 12px 24px;
                box-shadow: $box-shadow;
                border: none;
                width: 100%;
                color: $text-grey-shade-1;
                text-transform: capitalize;
                font-family: $font-family-poppins-regular;
                
                @include site-transition;
                &:focus{
                  outline: none;
                  background-color: $bg-grey-shade-3;
                }
              }
            }
            textarea{
              padding: 12px 24px;
              box-shadow: $box-shadow;
              color: $text-grey-shade-1;
              background-color: $bg-white;
              text-transform: capitalize;
              font-family: $font-family-poppins-regular;
              
              resize: none;
              width: 100%;
              border: none;
              @include site-transition;
              &:focus{
                outline: none;
                background-color: $bg-grey-shade-3;
              }
            }
            .check{
              display: flex;
              justify-content: space-between;
              @include breakpoint($secreen-xs){
               flex-direction: column;
              }
              label{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 28px 0;
                .check-inner{
                  background-color: $bg-grey-shade-3;
                  box-shadow: $box-shadow;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  flex-shrink: 0;
                  justify-content: center;
                  align-items: center;
                  margin-right: 7px;
                  position: relative;
                  &:hover{
                    cursor: pointer;
                  }
                  &:focus{
                    border: 2px solid red;
                  }
                  .input-check{
                    opacity: 0;
                    position: absolute;
                    &:hover{
                      cursor: pointer;
                    }
                  }
                  .input-check:checked + svg {
                  	display: block;
                    fill: #00ACEE;
                    background-color: #4200ff;
                    padding-left: 6px;
                    padding-top: 7px;
                  }
                  .fill-current{
                    display: none;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                  }
                }
                .select-none{
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-regular;
                  a{
                    display: inline-flex;
                    color: $text-primary;
                  }
                }
              }
              .forget{
                a{
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-regular;
                  text-transform: capitalize;
                }
              }
            }
            .action{
              display: flex;
              .comment-btn{
                width: 50%;
                @include site-btn;
                text-align: center;
                @include breakpoint($secreen-max-md){
                  width: auto;
                }
              }
            }
          }
        }
      }
      .sidebar{
        padding-left: 20px;
        .input-search{
          position: relative;
          box-shadow: $box-shadow;
          .input{
            width: 100%;
            border: none;
            padding: 16px 24px;
            background-color: $bg-sonw;
            color: $text-grey-shade-1;
            font-family: $font-family-poppins-regular;
            background-color: $bg-white;
            text-transform: capitalize;
            @include site-transition;
            &:focus{
              outline: none;
              background-color: $bg-grey-shade-3;
            }
          }
          .search{
            width: 16px;
            position: absolute;
            right: 24px;
            top: 50%;
            color: $text-grey-shade-1;
            transform: translate(0%, -50%);
          }
        }
        .popularposts-heading{
          h3{
            color: $text-neutral;
            margin: 40px 0 20px 0;
          }
        }
        .content-box{
          padding: 36px;
          background-color: $bg-white;
          box-shadow: $box-shadow;
          .info-admin{
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            a{
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
              margin-right: 4px;
              @include site-transition;
              &:hover{
                color: $text-primary;
              }
            }
            p{
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
            }
          }
          .news-blog-heading{
            .blog-link{
              font-family: $font-family-poppins-semibold;
              
              color: $text-neutral;
              line-height: $md-line-height;
              font-size: 22px;
              transition: .5s all ease-in-out;
              &:hover{
                color: $text-primary;
                transition: .5s all ease-in-out;
              }
              @include breakpoint($secreen-max-md){
                font-size: 22px;
              }
            }
          }
          .news-blog-btn{
            display: inline-flex;
            align-items: center;
            margin-top: 16px;
            position: relative;
            overflow: hidden;
            width: 125px;
            &:hover{
              .arrow{
                animation: news .4s;
                @include news-blog;
              }
            }
            p{
              text-transform: capitalize;
              color: $text-primary;
              font-family: $font-family-poppins-medium;
              
              margin-right: 8px;
            }
            .arrow{
              color: $text-primary;
              font-size: 21px;
            }
          }
        }
        .center-box{
          margin: 30px 0;
        }
        .categories{
          margin: 40px 0 24px 0;
          h3{
            color: $text-neutral;
            margin-bottom: 16px;
          }
          .categories-inner{
            display: flex;
            align-items: center;
            line-height: $md-line-height;
            .circle-arrow{
              transform: rotate(-90deg);
              margin-right: 12px;
              color: $text-primary;
            }
            p{
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
              text-transform: capitalize;
            }
          }
        }

        .popular-tags{
          h3{
            color: $text-neutral;
            margin-bottom: 20px;
          }
          .tags-inner{
            display: flex;
            flex-wrap: wrap;
            li{
              &:not(:last-child){
                margin-right: 12px;
                margin-bottom: 12px;
              }
              .tag{
                padding: 12px 16px;
                background-color: $bg-grey-shade-3;
                color: $text-grey-shade-1;
                font-family: $font-family-poppins-medium;
                
                @include site-transition;
                &:hover{
                  color: $text-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

//============= post-details End Here ==============//
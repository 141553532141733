

//================  Heading Start Here =================//

#heading-blog{
  background-color: $bg-primary;
  margin-bottom: 104px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//================  Heading End Here =================//


//================  Button Load Start Here =================//

.action-blog{
  display: flex;
  margin-top: 64px;
  justify-content: center;
  align-items: center;
  .load-btn{
    padding: 12px 22px;
    border: 1px solid $border-primary;
    background-color: transparent;
    color: $text-primary;
    text-transform: capitalize;
    font-family: $font-family-poppins-medium;
    font-weight: 600;
    @include site-transition;
    &:hover{
      background-color: $bg-primary;
      color: $text-white;
    }
  }
}

//================  Button Load End Here =================//


//============= Heading Start Here ==============//

#heading-services{
  background-color: $bg-primary;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-md){
          font-size: 40px;
          line-height: 48px;
        }
        @include breakpoint($secreen-xs){
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
}

//============= Heading End Here ==============//




//============= Services Start Here ==============//

#home3-services{
  .container{
    .wrapper{
      padding: 96px 142px;
      background-color: $bg-white;
      box-shadow: $box-shadow;
      @include breakpoint($secreen-max-lg){
        padding: 96px 40px;
      }
      @include breakpoint($secreen-max-md){
        padding: 64px 40px;
      }
      @include breakpoint($secreen-xs){
        padding: 64px 24px;
      }
      .heading{
        text-align: center;
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 0 0 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .services-box-outer{
          .services-box{
            padding: 36px;
            position: relative;
            background-color: $bg-sonw;
            @include site-transition;
            &:hover{
              .circle{
                background-color: $bg-primary;
              }
              .circle .services-icon{
                color: $text-white;
              }
              .services-dots .without-hover{
                display: none;
                @include site-transition;
              }
              .services-dots .hover{
                display: block;
                @include site-transition;
              }
            }
            .circle{
              width: 80px;
              height: 80px;
              background-color: $bg-remy;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              @include site-transition;
              @include site-transition;
              .services-icon{
                font-size: 36px;
                position: relative;
                top: 1px;
              }
            }
            .services-heading{
              h3{
                color: $text-neutral;
                margin: 16px 0;
              }
            }
            .action{
              a{
                display: inline-block;
                position: relative;
                &:hover{

                }
                p{
                  font-family: $font-family-poppins-medium;
                  
                  color: $text-primary;
                  text-transform: capitalize;
                  margin-bottom: 8px;
                }
                .arrow{
                  font-size: 21px;
                  color: $text-primary;
                }
              }
            }
            .services-dots{
              .without-hover{
                position: absolute;
                top: 36px;
                right: 36px;
                width: 30px;
                @include site-transition;
              }
              .hover{
                position: absolute;
                top: 36px;
                right: 36px;
                width: 30px;
                @include site-transition;
                display: none;
              }
            }
          }
        }
        .box-services1,
        .box-services2,
        .box-services3{
          margin-bottom: 30px;
        }
        .box-services4,
        .box-services5{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

.services-home2{
  margin: 128px 0 !important;
  @include breakpoint($secreen-max-md){
    margin: 64px 0 !important;
  }
}

//============= Services End Here ==============//

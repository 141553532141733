

//=========== Hero Section Start Here ==========//

.background-slider{
  background-color: $bg-primary;
  position: relative;
  overflow: hidden;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }


  #header-home3 {
    @include site-transition;

    &:after{
      content: "";
      position: absolute;
      width: 720px;
      height: 720px;
      top: -110px;
      right: -110px;
      background-image: url('/../assets/img/home3/slider-circle-1.svg');
      border-radius: 100%;
      background-color: #6C39FF;
      @include slider-circle;
      animation: circle .9s ease-in-out;
      @include breakpoint($secreen-max-md){
        top: -310px;
        right: -310px;
      }
    }
        .container {
            .nav {
                display: flex;
                align-items: center;
                padding: 28px 0 0 0;
                position: relative;
                z-index: 1;
                @include breakpoint($secreen-max-md){
                  justify-content: space-between;
                  padding: 20px 0 0 0;
                }
                .logo {
                  display: flex;
                  align-items: center;
                  flex-direction: row-reverse;
                  @include breakpoint ($secreen-xs){
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                  }
                    .digieized{
                      .digieized-logo{
                      }
                    }
                    .toggle{
                      transform: scaleX(-1, 1);
                      margin-right: 12px;
                      @include breakpoint ($secreen-xs){
                        transform: scaleX(-1);
                      }
                      &:hover{
                        .first{
                          width: 25px;
                          @include site-transition;
                        }
                        .third{
                          width: 25px;
                          @include site-transition;
                        }
                      }
                      @include breakpoint($secreen-xl){
                        display: none;
                      }
                      .toggle-line {
                          display: flex;
                          flex-direction: row;
                      }
                      .first {
                          width: 21px;
                          height: 3px;
                          transition: 0.3s;
                          background-color: $bg-white;
                          @include site-transition;
                      }
                      .second {
                          width: 25px;
                          height: 3px;
                          margin: 5px 0;
                          transition: 0.3s;
                          background-color: $bg-white;
                          @include site-transition;
                      }
                      .third {
                          width: 16px;
                          height: 3px;
                          transition: 0.3s;
                          background-color: $bg-white;
                          @include site-transition;
                      }
                    }
                }
                .navigation {
                    margin-left: auto;
                    display: flex;
                    @include breakpoint($secreen-max-lg){
                      display: none;
                    }
                    .navigation-ul {
                        display: flex;
                        align-items: center;
                        .navigation-li {
                            position: relative;
                            &:hover{
                              .drop-ul{
                                 opacity: 1;
                                 visibility: visible;
                                 top: 100%;
                               }
                               #slider-home3 .illustertaion .slider-illustertaion .image{
                                 position: relative;
                                 z-index: -1;
                               }
                            }
                            &:not(:first-child) {
                                margin-left: 32px;
                            }
                            .links {
                                display: flex;
                                align-items: center;
                                line-height: 52px;
                                cursor: pointer;
                                text-transform: capitalize;
                                color: $text-white;
                                font-family: $font-family-poppins-medium;
                            }
                            .drop-ul{
                              background-color: $bg-white;
                              box-shadow: $box-shadow;
                              position: absolute;
                              top: 100px;
                              width: 270px;
                              z-index: 1;
                              opacity: 0;
                              visibility: hidden;
                              border-radius: 0;
                              left: -20px;
                              transition: all cubic-bezier(.4,0,.2,1) .4s;
                              padding: 16px 0;
                              .drop-li{
                                position: relative;
                                &:hover{
                                  background-color: $bg-primary;
                                  .other-ul{
                                    opacity: 1;
                                    visibility: visible;
                                  }
                                  .drop-links{
                                    color: $text-white;
                                    transition: .3s all ease-in-out;
                                    background-color: $bg-primary;
                                    cursor: pointer;
                                    &:hover{
                                      .drop-arrow{
                                        transform: rotate(-270deg);
                                        transition: .3s all ease-in-out;
                                      }
                                    }
                                    .drop-arrow{
                                      color: $text-white;
                                    }
                                  }
                                }
                                .drop-links{
                                  padding: 12px 32px;
                                  color: $text-grey-shade-1;
                                  font-family: $font-family-poppins-regular;
                                  position: relative;
                                  display: flex;
                                  align-items: center;
                                  cursor: pointer;
                                  text-transform: capitalize;
                                  justify-content: space-between;
                                  .drop-arrow{
                                    transform: rotate(-90deg);
                                    color: $text-grey-shade-1;
                                    transition: .3s all ease-in-out;
                                  }
                                }
                                .other-ul{
                                  position: absolute;
                                  right: 254px;
                                  top: -16px;
                                  background-color: $bg-white;
                                  box-shadow: $box-shadow;
                                  width: 270px;
                                  z-index: 1;
                                  opacity: 0;
                                  visibility: hidden;
                                  border-radius: 0;
                                  transition: all cubic-bezier(.4,0,.2,1) .4s;
                                  padding: 16px 0;
                                  .other-li{
                                    position: relative;
                                    .other-links{
                                      padding: 12px 32px;
                                      color: $text-grey-shade-1;
                                      font-family: $font-family-poppins-medium;
                                      position: relative;
                                      text-transform: capitalize;
                                      &:hover{
                                        color: $text-white;
                                        transition: .3s all ease-in-out;
                                        background-color: $bg-primary;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                        }
                    }
                }
                .action{
                  display: flex;
                  margin-left: 40px;
                  @include breakpoint ($secreen-max-lg){
                    margin-left: auto;
                  }
                  @include breakpoint ($secreen-xs){
                    display: none;
                  }
                  .btn{
                    @include site-btn;
                    background-color: transparent;
                    border: 1px solid $border-primary;
                    color: $text-white;
                    padding: 12px 24px;
                    border-radius: 0;
                  }
                  .sign-up{
                    margin-right: 16px;
                    border: 1px solid $border-white;
                    &:hover{
                      background-color: $bg-white;
                      color: $text-primary;
                    }
                  }
                  .login{
                    background-color: $bg-white;
                    color: $text-primary;
                    border: 1px solid $border-white;
                    &:hover{
                      background-color: transparent;
                      color: $text-white;
                    }
                  }
                }
            }
            .nav2{

            }
            #nav{
            }
        }
    }


// Slider

#slider-home3{
  padding-top: 100px;
  position: relative;
  padding-bottom: 128px;
  @include breakpoint($secreen-max-md){
    padding: 64px 0;
  }
  .container{
    .row{
      align-items: center;
      .content-outer{
        .content{
          padding-right: 20px;
          @include breakpoint($secreen-max-md){
            padding-right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 64px;
          }
          .slider-heading{
            h2{
              color: $text-white;
              font-size: $xl-font-size;
              line-height: $xl-line-height;
              @include breakpoint($secreen-lg){
                font-size: 56px;
              }
              @include breakpoint($secreen-min-lg){
                position: relative;
                left: -1.2px;
              }
              @include breakpoint($secreen-max-lg){
                font-size: 40px;
                line-height: 50px;
              }
              @include breakpoint($secreen-max-sm){
                font-size: 32px;
                line-height: 48px;
              }
            }
          }
          .slider-desc{
            p{
              font-family: $font-family-poppins-regular;
              line-height: $sm-line-height;
              color: $text-white;
              font-size: 18px;
              margin-top: 18px;
            }
          }
          .buttons{
            display: flex;
            align-items: center;
            margin-top: 32px;
            @include breakpoint($secreen-xs){
              flex-direction: column;
            }
            .action{
              display: flex;
              .slider-btn{
                @include site-btn;
                background-color: $bg-white;
                border: 1px solid $border-white;
                color: $text-primary;
                padding: 12px 24px;
                &:hover{
                  background-color: transparent;
                  color: $text-white;
                }
              }
            }
            .play{
              margin-left: 16px;
              display: flex;
              align-items: center;
              @include breakpoint($secreen-xs){
                margin-top: 32px;
              }
              @include breakpoint($secreen-xs){
                margin-left: 0;
              }
              span{
                width: 51px;
                height: 51px;
                border-radius: 50%;
                margin-right: 16px;
                color: $text-white;
                background-color: $bg-carrot-orange;
                text-align: center;
                line-height: 51px;
                position: relative;
                z-index: 1;
                &:hover{
                  cursor: pointer;
                }
                &::after{
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  border-radius: 50%;
                  border: 1px solid $border-carrot-orange;
                  @include button-transition-home31;
                  animation: home3pulse1 1.5s linear infinite;
                }
                &:before{
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  border-radius: 50%;
                  border: 1px solid $border-carrot-orange;
                  @include button-transition-home31;
                  animation: home3pulse1 1.5s linear .8s infinite;
                }
                .play-btn{
                  font-size: 18px;
                  position: relative;
                  left: 1px;
                }
              }
              p{
                
                font-family: $font-family-poppins-regular;
                color: $text-white;
                text-transform: capitalize;
              }
            }
          }
        }
      }
      .illustertaion-outer{
        @include breakpoint($secreen-max-md){
          display: flex;
          justify-content: center;
        }
        .illustertaion{
          display: flex;
          justify-content: flex-end;
          @include breakpoint($secreen-max-md){
            justify-content: center;
          }
          .slider-illustertaion{
            .image{
              width: 100%;
              @include breakpoint($secreen-lg){
                width: 450px;
              }
              @include breakpoint($secreen-xs){
                width: 300px;
              }
            }
          }
        }
      }
    }
  }
}
}

//=========== Hero Section End Here ==========//

//===============  Services Start Here ================//

#services-home3{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: $bg-primary;
    z-index: -1;
    @include breakpoint($secreen-max-md){
      display: none;
    }
  }
  .container{
    position: relative;
    z-index: 10;
    &:after{
      content: "";
      position: absolute;
      width: 720px;
      height: 720px;
      top: -70px;
      left: 10px;
      background-image: url('/../assets/img/home3/slider-circle-1.svg');
      border-radius: 100%;
      background-color: #6C39FF;
      z-index: -1;
      @include breakpoint($secreen-max-lg){
        display: none;
      }
    }
    .wrapper{
      padding: 96px 142px;
      background-color: $bg-white;
      box-shadow: $box-shadow;
      @include breakpoint($secreen-max-lg){
        padding: 96px 40px;
      }
      @include breakpoint($secreen-max-md){
        padding: 64px 40px;
      }
      @include breakpoint($secreen-xs){
        padding: 64px 24px;
      }
      .heading{
        text-align: center;
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 0 0 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        justify-content: center;
        .services-box-outer{
          .services-box{
            padding: 36px;
            position: relative;
            background-color: $bg-sonw;
            @include site-transition;
            &:hover{
              .circle{
                background-color: $bg-primary;
              }
              .circle .services-icon{
                color: $text-white;
              }
              .services-dots .without-hover{
                display: none;
                @include site-transition;
              }
              .services-dots .hover{
                display: block;
                @include site-transition;
              }
            }
            .circle{
              width: 80px;
              height: 80px;
              background-color: $bg-remy;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              @include site-transition;
              @include site-transition;
              .services-icon{
                font-size: 36px;
                position: relative;
                top: 1px;
              }
            }
            .services-heading{
              h3{
                color: $text-neutral;
                margin: 16px 0;
              }
            }
            .action{
              a{
                display: inline-block;
                position: relative;
                &:hover{

                }
                p{
                  font-family: $font-family-poppins-medium;
                  color: $text-primary;
                  text-transform: capitalize;
                  margin-bottom: 8px;
                }
                .arrow{
                  font-size: 21px;
                  color: $text-primary;
                }
              }
            }
            .services-dots{
              .without-hover{
                position: absolute;
                top: 36px;
                right: 36px;
                width: 30px;
                @include site-transition;
              }
              .hover{
                position: absolute;
                top: 36px;
                right: 36px;
                width: 30px;
                @include site-transition;
                display: none;
              }
            }
          }
        }
        .box1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box2{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//===============  Services End Here ================//




//===============  Clients Start Here ================//

#clients-home3{
  margin-top: 64px;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .owl-carousel{
        .owl-stage-outer{
          height: auto !important;
          .image-logo{
            width: 140px;
            @include site-transition;
            opacity: .3;
            @include breakpoint($secreen-max-lg){
              margin: 0 auto !important;
            }
            &:hover{
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

//===============  Clients End Here ================//



//===============  Features Start Here ================//

#features-home3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .features-heading-outer{
          .features-heading{
            color: $neutral;
            margin: 0 0 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .features-desc-outer{
          .features-desc{
            font-family: $font-family-poppins-regular;
            color: $grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .features-box-outer{
          .features-box{
            padding: 36px;
            position: relative;
            background-color: $bg-white;
            box-shadow:$box-shadow;
            @include site-transition;
            &:hover{
              background-color: $bg-primary;
              .features-shape .without-hover-shape{
                display: none;
              }
              .features-shape .hover-shape{
                display: block;
              }
              .features-dots .without-hover{
                display: none;
              }
              .features-dots .hover{
                display: block;
              }
              .features-heading h3{
                color: $text-white;
              }
              .features-desc p{
                color: $text-white;
              }
            }
            .features-shape{
              .without-hover-shape{
                width: auto;
                @include site-transition;
              }
              .hover-shape{
                display: none;
                @include site-transition;
              }
            }
            .features-heading{
              h3{
                color: $text-neutral;
                margin: 16px 0;
              }
            }
            .features-desc{
              p{
                font-family: $font-family-poppins-regular;
                line-height: $x-sm-line-height;
                color: $text-grey-shade-1;
                line-height: 30px;
              }
            }
            .features-dots{
              .without-hover{
                width: auto;
                width: 56px;
                position: absolute;
                top: 36px;
                right: 36px;
                @include site-transition;
              }
              .hover{
                display: none;
                width: 56px;
                position: absolute;
                top: 36px;
                right: 36px;
                @include site-transition;
              }
            }
          }
        }
        .box1,
        .box2,
        .box3{
          margin-bottom: 30px;
        }
        .box4,
        .box5{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//===============  Features End Here ================//


//===============  Portfolio Start Here ================//

#portfolio-home3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .portfolio-heading-outer{
          .portfolio-heading{
            color: $neutral;
            margin: 0 0 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .portfolio-desc-outer{
          .portfolio-desc{
            font-family: $font-family-poppins-regular;
            color: $grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .portfolio-box-outer{
          .portfolio-box{
            position: relative;
            overflow: hidden;
            box-shadow: $box-shadow;
            &:hover{
              .content{
                bottom: 36px;
                opacity: 1;
                transition: .3s all ease-in-out;
                @include breakpoint($secreen-xs){
                  bottom: 24px;
                }
              }
            }
            .img{
              .image-portfolio{
                width: 540px;
                height: auto;
                @include breakpoint($secreen-max-lg){
                  width: 100%;
                  height: auto;
                }
              }
            }
            .content{
              background-color: #ffffff;
              padding: 24px 32px;
              position: absolute;
              bottom: -100px;
              width: 468px;
              left: 50%;
              transform: translate(-50%, 0%);
              opacity: 0;
              margin: 0 auto;
              transition: .5s all ease-in-out;
              @include breakpoint($secreen-xs){
                display: flex;
                padding: 24px;
              }
              .portfolio-heading{
                a{
                  font-family: $font-family-poppins-semibold;
                  color: $text-neutral;
                  font-size: $md-font-size;
                  line-height: $md-line-height;
                  text-transform: capitalize;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                  @include breakpoint($secreen-xs){
                    font-size: $sm-font-size;
                    line-height: $x-sm-line-height;
                  }
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  line-height: $x-sm-line-height;
                  
                  margin-top: 4px;
                  @include breakpoint($secreen-xs){
                    display: none;
                  }
                }
              }
            }
          }
        }
        .box1,
        .box2{
          margin-bottom: 30px;
        }
        .box3{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//===============  Portfolio End Here ================//




//===============  Testimonials Start Here ================//

#testimonials-home3{
  margin-bottom: 128px;
  position: relative;
  overflow: hidden;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  &::after{
    content: "";
    position: absolute;
    left: 40px;
    background-color: $bg-grey-shade-3;
    top: 15px;
    z-index: -1;
    border-radius: 50%;
    background-repeat: no-repeat;
    width: 397px;
    height: 397px;
    @include breakpoint($secreen-max-sm){
      display: none;
    }
  }
  .container{
    .row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content{
        padding-right: 85px;
        @include breakpoint($secreen-max-md){
          margin-bottom: 80px;
          padding-right: 0;
          text-align: center;
        }
        @include breakpoint($secreen-max-sm){
          padding-right: 15px !important;
        }
        .stars{
          display: flex;
          align-items: center;
          @include breakpoint($secreen-max-md){
            justify-content: center;
          }
          .star-li{
            &:not(:last-child){
              margin-right: 16px;
            }
            .star{
              color: $text-primary;
            }
          }
        }
        .heading-inner{
          h2{
            color: $text-neutral;
            margin: 0 0 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
          p{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
            font-size: 18px;
            line-height: $sm-line-height;
          }
        }
      }
      .caraousel{
        .owl-carousel{
          box-shadow: $box-shadow;
          display: flex;
          justify-content: flex-end;
          .owl-stage-outer{
            .owl-stage{
              .owl-item{
                
                .action{
                  box-shadow: $box-shadow;
                }
                .slide{
                  background-color: $bg-white;
                  padding: 36px;
                  .img{
                    .testimonial-image{
                      width: 80px;
                      height: auto;
                      margin: 0 auto;
                    }
                  }
                  .desc-team{
                    .desc{
                      font-family: $font-family-poppins-regular;
                      line-height: 30px;
                      color: $text-grey-shade-1;
                      margin-top: 20px;
                      text-align: center;
                      @include breakpoint($secreen-xs){
                        margin-bottom: 24px;
                      }
                    }
                  }
                  .quote{
                    display: flex;
                    align-items: center;
                    margin: 24px 0;
                    @include breakpoint($secreen-max-md){
                      justify-content: center;
                    }
                    @include breakpoint($secreen-xs){
                      display: none;
                    }
                    .quote-li{
                      &:not(:last-child){
                        margin-right: 16px;
                      }
                    }
                    .quote-left{
                      color: $text-remy;
                    }
                  }
                  .about-person{
                    text-align: center;
                    h3{
                      color: $text-neutral;
                    }
                    p{
                      font-family: $font-family-poppins-regular;
                      color: $text-grey-shade-1;
                      margin-top: 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      #testimonial{

      }
    }
  }
}

//===============  Testimonials End Here ================//




//===============  Counter Start Here ================//

#counter-home3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .row{
      margin-top: 64px;
      .counter-box-outer{
        .counter-box{
          padding: 40px 20px;
          overflow: hidden;
          position: relative;
          text-align: center;
          box-shadow: $box-shadow;
          transition: .4s all ease-in-out;
          background-color: $bg-white;
          &:hover{
            background-color: $bg-primary;
            .heading h1{
              color: $text-white;
            }
            .heading p{
              color: $text-white;
            }
            .icon{
              .counter-icon{
                color: $text-white;
              }
            }
          }
          .heading{
            h1{
              color: $text-neutral;
              transition: .5s all ease-in-out;
            }
            p{
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
              
              margin-bottom: 16px;
              transition: .5s all ease-in-out;
            }
          }
          .icon{
            .counter-icon{
              color: $text-primary;
              transition: .5s all ease-in-out;
            }
          }
        }
      }
      .box1{
        @include breakpoint($secreen-max-md){
          margin-bottom: 30px;
        }
      }
      .box2,
      .box3{
        @include breakpoint($secreen-max-sm){
          margin-bottom: 30px;
        }
      }
    }
  }
}

//===============  Counter End Here ================//




//===============  Pricing Start Here ================//

#pricing-home3{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .pricing-heading-outer{
          .pricing-heading{
            color: $neutral;
            margin: 0 0 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .pricing-desc-outer{
          .pricing-desc{
            font-family: $font-family-poppins-regular;
            color: $grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .inner-wrapper{
        margin-top: 64px;
        .price-tabs{
          display: flex !important;
          .nav{
            display: inline-flex;
            margin: 0 auto;
            justify-content: center;
            border: 1px solid $border-primary;
            .item{
                .link{
                  padding: 14px 24px;
                  color: $text-grey-shade-1;
                  font-family: $font-family-poppins-regular;
                  text-transform: capitalize;
                  @include site-transition;
                  @include breakpoint($secreen-thirteen-min){
                    padding: 16px 26px;
                  }
                  &:hover{
                    background-color: $bg-primary;
                    color: $text-white;
                  }
                }
                .active{
                  background-color: $bg-primary;
                  color: white;
                }
            }
          }
        }
        .tab-content{
          margin-top: 64px;
          .container1{
            .row{
              box-shadow: $box-shadow;
              .pricing-box{
                padding: 36px;
                position: relative;
                background-color: $bg-white;
                overflow: hidden;
                @include site-transition;
                .pricing-info{
                  display: flex;
                  align-items: center;
                  .pricing-icon-outer{
                    margin-right: 16px;
                    .house,
                    .building,
                    .city{
                      font-size: 56px;
                      position: relative;
                      z-index: 2;
                      color: $text-primary;
                    }
                  }
                  .pricing-name{
                    position: relative;
                    h3{
                      color: $text-neutral;
                    }
                    p{
                      font-family: $font-family-poppins-regular;
                      color: $text-grey-shade-1;
                      margin-top: 8px;
                    }
                  }
               }
               .price{
                 display: flex;
                 align-items: center;
                 margin-top: 16px;
                 h2{
                   color: $text-neutral;
                   font-size: $xl-font-size;
                   line-height: $xl-line-height;
                 }
                 .span1{
                   font-family: $font-family-poppins-regular;
                   font-size: 16px;
                   color: $text-grey-shade-1;
                   margin-left: 5px;
                 }
                 .span2{
                   font-family: $font-family-poppins-regular;
                   font-size: 16px;
                   color: $text-grey-shade-1;
                   margin-left: 5px;
                 }
               }
               .action{
                 display: flex;
                 margin: 16px 0 28px 0;
                 .pricing-btn{
                   @include site-btn;
                   background-color: transparent;
                   border: 1px solid $border-primary;
                   color: $primary;
                   padding: 12px 24px;
                   width: 100%;
                   text-align: center;
                   &:hover{
                     background-color: $bg-primary;
                     color: $text-white;
                   }
                 }
               }
               .pricing-get{
                 li{
                   &:not(:last-child){
                     margin-bottom: 18px;
                   }
                 }
                 .get{
                   display: flex;
                   align-items: center;
                   .dot-circle{
                     margin-right: 8px;
                     color: $text-primary;
                     font-size: 18px;
                   }
                   p{
                     font-family: $font-family-poppins-regular;
                     font-size: 16px;
                     color: $text-grey-shade-1;
                   }
                 }
                 .no-get{
                   display: flex;
                   align-items: center;
                   .dot-circle{
                     margin-right: 8px;
                     color: $text-lavender-pink;
                     font-size: 18px;
                   }
                   p{
                     font-family: $font-family-poppins-regular;
                     font-size: 16px;
                     color: $text-lavender-pink;
                   }
                 }
               }
             }
             .center-box{
               background-color: $bg-primary;
               .pricing-info{

                 .pricing-icon-outer{
                   .house,
                   .building,
                   .city{
                     color: $text-white;
                   }
                 }
                 .pricing-name{
                   h3{
                     color: $text-white;
                   }
                   p{
                     color: $text-white;
                   }
                 }
              }
              .price{
                display: flex;
                align-items: center;
                margin-top: 16px;
                h2{
                  color: $text-white;
                }
                .span1{
                color: $text-white;
                }
                .span2{
                  color: $text-white;
                }
              }
              .action{
                display: flex;
                margin: 16px 0 28px 0;
                .pricing-btn{
                  @include site-btn;
                  background-color: $bg-white;
                  color: $primary;
                  width: 100%;
                  padding: 13px 24px;
                  text-align: center;
                  &:hover{
                   background-color: $bg-primary;
                   color: $text-white;
                   border: 1px solid $border-white;
                  }
                }
              }
              .pricing-get{
                .get{
                  .dot-circle{
                    margin-right: 8px;
                    color: $text-white;
                  }
                  p{
                    color: $text-white;
                  }
                }
                .no-get{
                  .dot-circle{
                    margin-right: 8px;
                    color: $text-grey-shade-1;
                  }
                  p{
                    color: $text-grey-shade-1;
                  }
                }
              }
             }
            }
          }
        }
      }
    }
  }
}

//===============  Pricing End Here ================//



//===============  Blog Start Here ================//

#news-blog-home3{
  position: relative;
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .news-blog-heading-outer{
          .news-blog-heading{
            color: $neutral;
            margin: 0 0 18px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .news-blog-desc-outer{
          .news-blog-desc{
            font-family: $font-family-poppins-regular;
            color: $grey-shade-1;
            font-size: 18px;
          }
        }
      }
      .row{
        margin-top: 64px;
        .news-blog-box-outer{
          .news-blog-box{
            position: relative;
            overflow: hidden;
            box-shadow: $box-shadow;
            background-color: $bg-white;
            padding: 36px;
            overflow: hidden;
            &:hover{
            }
            .image{
              overflow: hidden;
              &:hover{
                .news-blog-image{
                  transform: scale(1.1);
                }
              }
              .news-blog-image{
                width: 100%;
                @include site-transition;
              }
            }
            .content{
              padding: 32px 0 0 0;
              .info-admin{
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                a{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                  margin-right: 4px;
                  @include site-transition;
                  &:hover{
                    color: $text-primary;
                  }
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-grey-shade-1;
                }
              }
              .news-blog-heading{
                .blog-link{
                  font-family: $font-family-poppins-semibold;
                  color: $text-neutral;
                  line-height: $md-line-height;
                  font-size: $md-font-size;
                  transition: .5s all ease-in-out;
                  @include breakpoint($secreen-max-md){
                    font-size: 22px;
                  }
                  &:hover{
                    color: $text-primary;
                    transition: .5s all ease-in-out;
                  }
                }
              }
              .news-blog-btn{
                display: inline-flex;
                align-items: center;
                margin-top: 16px;
                position: relative;
                overflow: hidden;
                width: 125px;
                &:hover{
                  .arrow{
                    animation: news .4s;
                    @include news-blog;
                  }
                }
                p{
                  text-transform: capitalize;
                  color: $text-primary;
                  font-family: $font-family-poppins-medium;
                  margin-right: 8px;
                }
                .arrow{
                  font-size: 22px;
                  color: $text-primary;
                }
              }
            }
          }
        }
        .box1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box2{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//===============  Blog End Here ================//



//===============  Let's Started Start Here ================//

#started-home3{
  background-color: $bg-primary;
  position: relative;
  overflow: hidden;
  &:after{
    content: "";
    position: absolute;
    bottom: -150%;
    left: 18%;
    width: 750px;
    height: 750px;
    overflow: hidden;
    border-radius: 100%;
    background-color: #6C39FF;
    @include breakpoint($secreen-max-lg){
      display: none;
    }
  }
  .container{
    .wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 64px 0;
      @include breakpoint($secreen-max-md){
        justify-content: center;
        flex-direction: column;
      }
      .content{
        @include breakpoint($secreen-max-md){
          margin-bottom: 80px;
          text-align: center;
        }
        .heading{
          h2{
            color: $text-white;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .desc{
          p{
            font-family: $font-family-poppins-regular;
            color: $text-white;
            margin: 16px 0 32px 0;
            font-size: 18px;
          }
        }
        .action{
          display: flex;
          .started-btn{
            @include site-btn;
            background-color: $bg-white;
            border: 1px solid $border-white;
            color: $text-primary;
            padding: 12px 24px;
            &:hover{
              background-color: transparent;
              color: $text-white;
            }
          }
          @include breakpoint($secreen-max-md){
            justify-content: center;
          }
        }
      }
      .image{
        .cta-illustration{
          width: 100%;
        }
      }
    }
  }
}

//===============  Let's Started End Here ================//




//===============  Footer Start Here ================//

#footer-home3{
  padding: 128px 0;
  background-color: $bg-neutral;
  @include breakpoint($secreen-max-md){
    padding: 64px 0;
  }
  .container{
    .get-latest{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoint($secreen-max-md){
        justify-content: center;
        flex-direction: column;
      }
      .heading-get-latest{
        @include breakpoint($secreen-max-md){
          margin-bottom: 12px;
        }
        h3{
          color: $text-white;
          @include breakpoint($secreen-xs){
            font-size: 20px;
            text-align: center;
          }
        }
      }
      .input{
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint($secreen-xs){
          flex-direction: column;
        }
        .input-inner{
          width: 382px;
          padding: 14px 24px;
          font-family: $font-family-poppins-regular;
          background-color: $bg-white;
          border: none;
          color: $text-grey-shade-1;
          text-transform: capitalize;
          box-shadow: $box-shadow;
          &:focus{
            outline: none;
          }
          @include breakpoint($secreen-xs){
            width: 100%;
            margin: 4px 0 16px 0;
          }
        }
        .subscribe-btn{
          @include site-btn;
        }
      }
    }
    .wrapper{
      .row{
        margin-top: 64px;
        .digieized-outer{
          .digieized{
            @include breakpoint($secreen-max-sm){
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
            .logo{
              .digieized-logo{
                width: 170px;
                height: 46px;
              }
            }
            .desc{
              p{
                font-family: $font-family-poppins-regular;
                color: $text-white;
                margin: 18px 0 20px 0;
                line-height: 30px;
                @include breakpoint($secreen-xs){
                  text-align: center;
                }
              }
            }
            .action{
              display: flex;
              .footer-btn{
                @include site-btn;
                background-color: transparent;
                border: 1px solid $border-primary;
                color: $text-white;
                padding: 12px 24px;
                &:hover{
                  background-color: $bg-primary;
                  color: $text-white;
                }
              }
            }
            .icon{
              display: flex;
              align-items: center;
              margin-top: 28px;
              li{
                &:not(:first-child){
                  margin-left: 16px;
                }
                a{
                  .social-icon{
                    color: $text-white;
                    font-size: 24px;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                }
              }
            }
          }
        }
        .quick-links-outer{
          display: flex;
          justify-content: center;
          @include breakpoint($secreen-max-sm){
            margin-top: 64px;
          }
          .quick-links{
            margin: 0 auto;
            .quick-heading{
              h3{
                font-size: 20px;
                color: $text-white;
                margin-bottom: 16px;
              }
            }
            .quick-links-inner{
              display: flex;
              align-items: center;
              ul{
                line-height: 38px;
                li{
                  a{
                    font-family: $font-family-poppins-regular;
                    color: $text-white;
                    @include site-transition;
                    &:hover{
                      color: $text-primary;
                    }
                  }
                }
              }
              .first-col{
                margin-right: 32px;
              }
            }
          }
        }
        .contact-Details-outer{
          display: flex;
          justify-content: flex-end;
          @include breakpoint($secreen-max-md){
            justify-content: flex-start;
            margin-top: 64px;
          }
          @include breakpoint($secreen-max-sm){
            justify-content: center;
          }
          .contact-Details{
            display: flex;
            flex-direction: column;
            .contact-Details-heading{
              .contact{
                font-size: 20px;
                color: $text-white;
                margin-bottom: 20px;
              }
            }
            .contact-Details-info{
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              .info{
                display: inline-flex;
                align-items: center;
                .main{
                  height: 32px;
                  width: 32px;
                  background-color: $bg-primary;
                  border-radius: 50%;
                  margin-right: 10px;
                  text-align: center;
                  @include site-transition;
                  .contact{
                    height: auto;
                    color: $text-white;
                    line-height: 32px;
                  }
                }
                p{
                  font-family: $font-family-poppins-regular;
                  color: $text-white;
                }
              }
              .center{
                margin: 20px 0;
              }
            }
          }
        }
      }
      #copy-home3{
        .wrapper-copy{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 64px;
          @include breakpoint($secreen-max-md){
            justify-content: center;
            flex-direction: column;
          }
          .left{
            .copyright-home2{
              .copy{
                font-family: $font-family-poppins-regular;
                color: $text-white;
                @include breakpoint($secreen-max-md){
                  margin-bottom: 8px;
                }
                @include breakpoint($secreen-xs){
                  text-align: center;
                }
                .link{
                  display: inline-flex;
                  color: $text-primary;
                  text-transform: capitalize;
                }
              }
            }
          }
          .right{
            .condition-home2{
              display: flex;
              align-items: center;
              a{
                font-family: $font-family-poppins-regular;
                color: $text-white;
                @include site-transition;
                &:hover{
                  color: $text-primary;
                }
              }
              span{
                font-family: $font-family-poppins-regular;
                
                color: $text-white;
                margin: 0 5px;
              }
              a{
                font-family: $font-family-poppins-regular;
                
                color: $text-white;
                @include site-transition;
                &:hover{
                  color: $text-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

//===============  Footer End Here ================//



#page-404{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  .container{
    .img{
      display: flex;
      justify-content: center;
      img{
        @include breakpoint($secreen-max-sm){
          width: 100%;
          height: 100%;
        }
      }
    }
    .heading{
      margin: 32px 0;
      text-align: center;
      h1{
        color: $text-neutral;
        @include breakpoint($secreen-max-md){
          font-size: 32px;
          line-height: 44px;
        }
        @include breakpoint($secreen-xs){
          font-size: 28px;
          line-height: 36px;
        }
      }
      p{
        color: $text-grey-shade-1;
        margin-top: 18px;
        font-size: 18px;
        font-family: $font-family-poppins-regular;
      }
    }
    .action{
      display: flex;
      justify-content: center;
      .homepage-btn{
        @include site-btn;
      }
    }
  }
}

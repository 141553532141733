


//============ Header Start Here =====================//

#header-about1 {
  background-color: $bg-primary;
    .container {
        .nav {
            display: flex;
            align-items: center;
            padding: 28px 0 0 0;
            .logo {
                display: flex;
                align-items: center;
                @include breakpoint($secreen-xs){
                  width: 100%;
                  justify-content: space-between;
                  flex-direction: row-reverse;
                }
                a{
                  .digieized{
                    width: 152px;
                    height: auto;
                  }
                }
                .toggle {
                    margin-right: 12px;
                    @include breakpoint($secreen-xs){
                      margin-right: 0;
                      transform: scale(-1, 1);
                    }
                    &:hover {
                        cursor: pointer;
                        .first {
                            width: 25px !important;
                            transition: 0.3s;
                        }
                        .second {
                            transition: 0.3s;
                        }
                        .third {
                            width: 25px !important;
                            transition: 0.3s;
                        }
                    }
                    .toggle-line {
                        display: flex;
                        flex-direction: row;
                    }
                    .first {
                        width: 21px;
                        height: 3px;
                        transition: 0.3s;
                        background-color: $bg-white;
                    }
                    .second {
                        width: 25px;
                        height: 3px;
                        margin: 5px 0;
                        transition: 0.3s;
                        background-color: $bg-white;
                    }
                    .third {
                        width: 16px;
                        height: 3px;
                        transition: 0.3s;
                        background-color: $bg-white;
                    }
                }
            }
            .logo-digitized{
              .digitized{
                width: 152px;
                height: 46px;
              }
            }
            .navigation {
                margin-left: auto;
                display: flex;
                @include breakpoint ($secreen-max-lg){
                  display: none;
                }
                .navigation-ul {
                    display: flex;
                    align-items: center;
                    .navigation-li {
                        position: relative;
                        &:hover{
                          .drop-ul{
                             opacity: 1;
                             visibility: visible;
                             transform: perspective(300px) rotateX(0deg);
                             top: 100%;
                           }
                        }
                        &:not(:first-child) {
                            margin-left: 32px;
                        }
                        .links {
                            display: flex;
                            align-items: center;
                            line-height: 52px;
                            cursor: pointer;
                            p {
                                text-transform: capitalize;
                                color: $text-white;
                                font-family: $font-family-poppins-medium;
                            
                            }
                            .drop-arrow {
                                margin-left: 3px;
                                position: relative;
                                top: 0px;
                                color: $text-white;
                            }
                        }
                        .drop-ul{
                          background-color: $bg-white;
                          box-shadow: $box-shadow;
                          position: absolute;
                          top: 100px;
                          width: 270px;
                          z-index: 10;
                          opacity: 0;
                          visibility: hidden;
                          border-radius: 0;
                          left: -20px;
                          transform-origin: 50% -50px;
                          transform: perspective(300px) rotateX(-8deg);
                          transition: all cubic-bezier(.4,0,.2,1) .4s;
                          padding: 16px 0;
                          .drop-li{
                            position: relative;
                            &:hover{
                              background-color: $bg-primary;
                              .other-ul{
                                opacity: 1;
                                visibility: visible;
                                transform: perspective(300px) rotateX(0deg);
                              }
                              .drop-links{
                                color: $text-white;
                                transition: .3s all ease-in-out;
                                background-color: $bg-primary;
                                cursor: pointer;
                                &:hover{
                                  .drop-arrow{
                                    transform: rotate(-270deg);
                                    transition: .3s all ease-in-out;
                                  }
                                }
                                .drop-arrow{
                                  color: $text-white;
                                }
                              }
                            }
                            .drop-links{
                              padding: 12px 32px;
                              color: $text-grey-shade-1;
                              font-family: $font-family-poppins-medium;
                              position: relative;
                              display: flex;
                              align-items: center;
                              cursor: pointer;
                              text-transform: capitalize;
                              justify-content: space-between;
                              .drop-arrow{
                                transform: rotate(-90deg);
                                fill: $text-grey-shade-1;
                                transition: .3s all ease-in-out;
                              }
                            }
                            .other-ul{
                              position: absolute;
                              right: 254px;
                              top: -16px;
                              background-color: $bg-white;
                              box-shadow: $box-shadow;
                              width: 270px;
                              z-index: 10;
                              opacity: 0;
                              visibility: hidden;
                              border-radius: 0;
                              transform-origin: 50% -50px;
                              transform: perspective(300px) rotateX(-8deg);
                              transition: all cubic-bezier(.4,0,.2,1) .4s;
                              padding: 16px 0;
                              .other-li{
                                position: relative;
                                .other-links{
                                  padding: 12px 32px;
                                  color: $text-grey-shade-1;
                                  font-family: $font-family-poppins-medium;
                                  position: relative;
                                  text-transform: capitalize;
                                  &:hover{
                                    color: $text-white;
                                    transition: .3s all ease-in-out;
                                    background-color: $bg-primary;
                                  }
                                }
                              }
                            }
                          }
                        }
                    }
                }
            }
            .action {
                display: flex;
                margin-left: 32px;
                overflow: hidden;
                position: relative;
                margin-left: auto;
                .contact-btn {
                    @include site-btn;
                    background-color: transparent;
                    color: $text-white;
                    padding: 13px 24px;
                    border: 1px solid $border-white;
                    &:hover{
                      background-color: $bg-white;
                      color: $text-primary;
                    }
                }
                @include breakpoint($secreen-xs){
                  display: none;
                }
            }
        }
        .nav2{

        }
        #nav{
        }
    }
}

//============ Header End Here =====================//




//============ Heading Start Here ============//

#heading{
  background-color: $bg-primary;
  .container{
    .heading{
      padding: 56px 0 80px 0;
      h2{
        color: $text-white;
        text-align: center;
        font-size: $xl-font-size;
        @include breakpoint($secreen-max-lg){
          font-size: 40px;
          line-height: 50px;
        }
        @include breakpoint($secreen-max-sm){
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
}

//============ Heading End Here ============//




//============ About Start Here  ============//

#about {
    position: relative;
    margin: 128px 0;
    @include breakpoint($secreen-max-md){
      margin: 64px 0;
    }
    .container {
        position: relative;
        .row{
          align-items: center;
          .content {
            position: relative;
            @include breakpoint($secreen-max-md){
              text-align: center;
              padding: 0 30px;
              margin-bottom: 64px;
            }
              .about-tag-outer {
                  display: inline-flex;
                  .about-tag {
                      @include site-tag;
                  }
              }
              .heading {
                  h2 {
                      margin: 18px 0;
                      color: $text-neutral;
                      @include breakpoint($secreen-max-md){
                        font-size: 32px;
                        line-height: 44px;
                      }
                      @include breakpoint($secreen-xs){
                        font-size: 28px;
                        line-height: 36px;
                      }
                  }
                  p {
                      font-family: $font-family-poppins-regular;
                      line-height: $sm-line-height;
                      color: $text-grey-shade-1;
                      font-size: 18px;
                  }
              }
              .headin-admin {
                h3{
                  margin: 20px 0 12px 0;
                }
                p{
                  font-family: $font-family-poppins-regular;
                  line-height: $sm-line-height;
                  color: $text-grey-shade-1;
                  font-size: 18px;
                }
              }
              .heading-mission{
                h3{
                  margin: 20px 0 12px 0;
                }
                p{
                  font-family: $font-family-poppins-regular;
                  line-height: $sm-line-height;
                  color: $text-grey-shade-1;
                  font-size: 18px;
                }
              }
              .circle1{
                .circle1-svg{
                  position: absolute;
                  bottom: -70px;
                  left: 70px;
                }
              }
              .circle2{
                .circle2-svg{
                  position: absolute;
                  top: -70px;
                  right: 250px;
                }
              }
          }
          .slider-image-outer{
            .image {
                display: flex;
                justify-content: flex-end;
                position: relative;
                @include breakpoint($secreen-max-md){
                  display: flex;
                  justify-content: center;
                }
                .slider-image {
                    width: 475px;
                    height: 637px;
                    box-shadow: $box-shadow;
                    @include breakpoint($secreen-xs){
                      width: 100% !important;
                      height: 100% !important;
                    }
                }
                .circle {
                    .circle-svg {
                        position: absolute;
                        top: 150px;
                        right: -25px;
                        z-index: -1;
                        @include circle-svg1;
                        animation: circle1 8s ease infinite;
                        @include breakpoint($secreen-max-lg){
                          display: none;
                        }
                    }
                }
            }
          }
        }
    }
}

//============ About End Here  ============//




//============ Team Start Here  ============//

#team{
  margin-bottom: 128px;
  @include breakpoint($secreen-max-md){
    margin-bottom: 64px;
  }
  .container{
    .wrapper{
      .heading{
        text-align: center;
        .services-tag-outer{
          display: flex;
          justify-content: center;
          .services-tag{
            @include site-tag;
          }
        }
        .services-heading-outer{
          .services-heading{
            color: $text-neutral;
            margin: 16px 0;
            @include breakpoint($secreen-max-md){
              font-size: 32px;
              line-height: 44px;
            }
            @include breakpoint($secreen-xs){
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .services-desc-outer{
          .services-desc{
            font-family: $font-family-poppins-regular;
            color: $text-grey-shade-1;
        
          }
        }
      }
      .row{
        margin-top: 64px;
        justify-content: center;
        .team-box{
          .team-image{
            position: relative;
            box-shadow: $box-shadow;
            &:hover{
              .overlay-icons{
                opacity: .9;
              }
            }
            .img-fluid{
              @include breakpoint($secreen-max-sm){
                width: 100%;
              }
            }
            .overlay-icons{
              opacity: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 50%;
              left: 50%;
              background-color: $bg-primary;
              transform: translate(-50%, -50%);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              @include site-transition;
              h3{
                color: $text-white;
              }
              p{
                font-family: $font-family-poppins-regular;
                color: $text-white;
                margin: 8px 0 24px 0;
              }
              .icon{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-xs){
                  justify-content: center;
                }
                li{
                  &:not(:first-child){
                    margin-left: 16px;
                  }
                  a{
                    .social-icon{
                      color: $text-white;
                      font-size: 24px;
                      @include site-transition;
                      &:hover{
                        color: $text-grey-shade-1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .box1{
          @include breakpoint($secreen-max-md){
            margin-bottom: 30px;
          }
        }
        .box2{
          @include breakpoint($secreen-max-sm){
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

//============ Team End Here  ============//






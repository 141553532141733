



//================  Header Start Here =================//

.header-about2{
  background-color: $bg-primary;
  #header-about2 {
    @include site-transition;

    &:after{
      content: "";
      position: absolute;
      width: 600px;
      height: 600px;
      top: -61%;
      right: 50px;
      background-image: url(/../assets/img/home3/slider-circle-1.svg);
      border-radius: 100%;
      background-color: #6C39FF;
      @include breakpoint($secreen-xxl-max){
        top: -91%;
      }
      @include breakpoint($secreen-max-lg){
        display: none;
      }
    }
        .container {
            .nav {
                display: flex;
                align-items: center;
                padding: 20px 0 0 0;
                position: relative;
                z-index: 1;
                @include breakpoint($secreen-max-md){
                  justify-content: space-between;
                }
                .logo {
                  display: flex;
                  align-items: center;
                  @include breakpoint ($secreen-xs){
                    width: 100%;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                  }
                    .toggle{
                      margin-right: 12px;
                      transform: scale(1);
                      @include breakpoint ($secreen-xs){
                        margin-right: 0;
                        transform: scale(-1, 1);
                      }
                    }
                    .digieized{
                      .digieized-logo{
                      }
                    }
                }
                .navigation {
                    margin-left: auto;
                    display: flex;
                    @include breakpoint($secreen-max-lg){
                      display: none;
                    }
                    .navigation-ul {
                        display: flex;
                        align-items: center;
                        .navigation-li {
                            position: relative;
                            &:hover{
                              .drop-ul{
                                 opacity: 1;
                                 visibility: visible;
                                 top: 100%;
                               }
                               #slider-home3 .illustertaion .slider-illustertaion .image{
                                 position: relative;
                                 z-index: -1;
                               }
                            }
                            &:not(:first-child) {
                                margin-left: 32px;
                            }
                            .links {
                                display: flex;
                                align-items: center;
                                line-height: 52px;
                                cursor: pointer;
                                text-transform: capitalize;
                                color: $text-white;
                                font-family: $font-family-poppins-medium;
                                
                            }
                            .drop-ul{
                              background-color: $bg-sonw;
                              box-shadow: $box-shadow;
                              position: absolute;
                              top: 100px;
                              width: 270px;
                              z-index: 1;
                              opacity: 0;
                              visibility: hidden;
                              border-radius: 0;
                              left: -20px;
                              transition: all cubic-bezier(.4,0,.2,1) .4s;
                              padding: 16px 0;
                              .drop-li{
                                position: relative;
                                &:hover{
                                  background-color: $bg-primary;
                                  .other-ul{
                                    opacity: 1;
                                    visibility: visible;
                                  }
                                  .drop-links{
                                    color: $text-white;
                                    transition: .3s all ease-in-out;
                                    background-color: $bg-primary;
                                    cursor: pointer;
                                    &:hover{
                                      .drop-arrow{
                                        transform: rotate(-270deg);
                                        transition: .3s all ease-in-out;
                                      }
                                    }
                                    .drop-arrow{
                                      color: $text-white;
                                    }
                                  }
                                }
                                .drop-links{
                                  padding: 12px 32px;
                                  color: $text-grey-shade-1;
                                  font-family: $font-family-poppins-medium;
                                  position: relative;
                                  display: flex;
                                  align-items: center;
                                  cursor: pointer;
                                  text-transform: capitalize;
                                  justify-content: space-between;
                                  .drop-arrow{
                                    transform: rotate(-90deg);
                                    color: $text-grey-shade-1;
                                    transition: .3s all ease-in-out;
                                  }
                                }
                                .other-ul{
                                  position: absolute;
                                  right: 254px;
                                  top: -16px;
                                  background-color: $bg-white;
                                  box-shadow: $box-shadow;
                                  width: 270px;
                                  z-index: 1;
                                  opacity: 0;
                                  visibility: hidden;
                                  border-radius: 0;
                                  transition: all cubic-bezier(.4,0,.2,1) .4s;
                                  padding: 16px 0;
                                  .other-li{
                                    position: relative;
                                    .other-links{
                                      padding: 12px 32px;
                                      color: $text-grey-shade-1;
                                      font-family: $font-family-poppins-medium;
                                      position: relative;
                                      text-transform: capitalize;
                                      &:hover{
                                        color: $text-white;
                                        transition: .3s all ease-in-out;
                                        background-color: $bg-primary;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                        }
                    }
                }
                .action{
                  display: flex;
                  margin-left: 32px;
                  @include breakpoint ($secreen-max-lg){
                    margin-left: auto;
                  }
                  @include breakpoint ($secreen-xs){
                    display: none;
                  }
                  .btn{
                    @include site-btn;
                    background-color: transparent;
                    border: 1px solid $border-white;
                    color: $text-white;
                    padding: 12px 24px;
                    border-radius: 0;
                    &:hover{
                      background-color: $bg-white;
                      color: $text-primary;
                    }
                  }
                  .sign-up{
                    margin-right: 16px;
                  }
                  .login{
                    background-color: $bg-white;
                    color: $text-primary;
                  }
                }
                .toggle{
                  transform: scaleX(-1);
                  &:hover{
                    .first{
                      width: 25px;
                      @include site-transition;
                    }
                    .third{
                      width: 25px;
                      @include site-transition;
                    }
                  }
                  @include breakpoint($secreen-max-lg){
                    display: block;
                    margin-left: 20px;
                  }
                  @include breakpoint($secreen-xl){
                    display: none;
                  }
                  .toggle-line {
                      display: flex;
                      flex-direction: row;
                  }
                  .first {
                      width: 21px;
                      height: 3px;
                      transition: 0.3s;
                      background-color: $bg-white;
                      @include site-transition;
                  }
                  .second {
                      width: 25px;
                      height: 3px;
                      margin: 5px 0;
                      transition: 0.3s;
                      background-color: $bg-white;
                      @include site-transition;
                  }
                  .third {
                      width: 16px;
                      height: 3px;
                      transition: 0.3s;
                      background-color: $bg-white;
                      @include site-transition;
                  }
                }
            }
            .nav2{

            }
            #nav{
            }
        }
    }
}

//================  Header End Here =================//





//================  Heading Start Here =================//

  #heading-about2{
    background-color: $bg-primary;
    .container{
      .heading{
        padding: 56px 0 80px 0;
        h2{
          color: $text-white;
          text-align: center;
          font-size: $xl-font-size;
          @include breakpoint($secreen-max-lg){
            font-size: 40px;
            line-height: 50px;
          }
          @include breakpoint($secreen-max-sm){
            font-size: 32px;
            line-height: 48px;
          }
        }
      }
    }
  }

//================  Header End Here =================//




//================  About Start Here =================//

  #about2 {
      position: relative;
      margin: 128px 0;
      @include breakpoint($secreen-max-md){
        margin: 64px 0;
      }
      .container {
          position: relative;
          .row{
            align-items: center;
            .content {
              position: relative;
              @include breakpoint($secreen-max-md){
                text-align: center;
                margin-bottom: 64px;
              }
                .about-tag-outer {
                    display: inline-flex;
                    .about-tag {
                        @include site-tag;
                    }
                }
                .heading {
                    h2 {
                        margin: 0 0 18px 0;
                        color: $text-neutral;
                        @include breakpoint($secreen-max-md){
                          font-size: 32px;
                          line-height: 44px;
                        }
                        @include breakpoint($secreen-xs){
                          font-size: 28px;
                          line-height: 36px;
                        }
                    }
                    p {
                        font-family: $font-family-poppins-regular;
                        line-height: $sm-line-height;
                        color: $text-grey-shade-1;
                        font-size: 18px;
                    }
                }
                .headin-admin {
                  h3{
                    margin: 20px 0 12px 0;
                  }
                  p{
                    font-family: $font-family-poppins-regular;
                    line-height: $sm-line-height;
                    color: $text-grey-shade-1;
                    font-size: 18px;
                  }
                }
                .heading-mission{
                  h3{
                    margin: 20px 0 12px 0;
                  }
                  p{
                    font-family: $font-family-poppins-regular;
                    line-height: $sm-line-height;
                    color: $text-grey-shade-1;
                    font-size: 18px;
                  }
                }
            }
            .slider-illustertaion-outer{
              .illustertaion-about2{
                display: flex;
                justify-content: flex-end;
                @include breakpoint($secreen-max-md){
                  justify-content: center;
                  width: 100%;
                }
                .about-illustration{
                  width: 462px;
                  @include breakpoint($secreen-max-md){
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
      }
  }

//================  About End Here =================//  




//================  Team Start Here =================//

  #team-about2{
    margin-bottom: 128px;
    @include breakpoint($secreen-max-md){
      margin-bottom: 64px;
    }
    .container{
      .wrapper{
        .heading{
          text-align: center;
          .services-tag-outer{
            display: flex;
            justify-content: center;
            .services-tag{
              @include site-tag;
            }
          }
          .services-heading-outer{
            .services-heading{
              color: $text-neutral;
              margin: 16px 0;
              @include breakpoint($secreen-max-md){
                font-size: 32px;
                line-height: 44px;
              }
              @include breakpoint($secreen-xs){
                font-size: 28px;
                line-height: 36px;
              }
            }
          }
          .services-desc-outer{
            .services-desc{
              font-family: $font-family-poppins-regular;
              color: $text-grey-shade-1;
            }
          }
        }
        .row{
          margin-top: 64px;
          justify-content: center;
          .team-box{
            padding: 0;
            @include breakpoint($secreen-max-md){
              padding-right: 15px;
              padding-left: 15px;
            }
            .team-image{
              position: relative;
              overflow: hidden;
              box-shadow: $box-shadow;
              &:hover{
                .overlay-icons{
                  opacity: 1;
                  bottom: 32px;
                  transition: .4s all ease-in-out;
                }
              }
              .img-fluid{
                width: 100%;
              }
              .overlay-icons{
                opacity: 0;
                position: absolute;
                padding: 20px 24px;
                bottom: -100px;
                left: 50%;
                background-color: $bg-sonw;
                transform: translate(-50%, 0%);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                transition: .4s all ease-in-out;
                .icon{
                  display: flex;
                  align-items: center;
                  @include breakpoint($secreen-xs){
                    justify-content: center;
                  }
                  li{
                    &:not(:first-child){
                      margin-left: 16px;
                    }
                    a{
                      .social-icon
                      {
                        color: $text-grey-shade-1;
                        font-size: 24px;
                        @include site-transition;
                        &:hover{
                          color: $text-primary;
                        }
                      }
                    }
                  }
                }
              }
            }
            .about-person{
              text-align: center;
              padding: 24px 24px 0 24px;
              h3{
                color: $text-neutral;
              }
              p{
                font-family: $font-family-poppins-regular;
                color: $text-grey-shade-1;
                margin-top: 6px;
              }
            }
          }
          .box1{
            @include breakpoint($secreen-max-md){
              margin-bottom: 30px;
            }
          }
          .box2{
            @include breakpoint($secreen-max-sm){
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

//================  Team End Here =================//  
